import React from 'react'
import styles from './VoucherInvoicesScreen.module.css'
import { useParams } from 'react-router-dom'
import Invoice from './components/invoice/Invoice'
import { useProfile } from '../../../../../../modules/auth/hooks'
import { usePurchasedVoucher } from '../../../../../../modules/vouchers/hooks'
import Screen from '../../../../../../components/molecules/screen/Screen'
import BackButton from '../../../../../../components/atoms/backButton/BackButton'
import Text from '../../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../../types/Components.types'
import LoadingData from '../../../../../../components/molecules/loadingData/LoadingData'

interface IParams {
  voucherId: string
}

const VoucherInvoicesScreen = () => {
  const { voucherId } = useParams<IParams>()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: voucher, isLoading: isLoadingVoucher } = usePurchasedVoucher({ userId: profile?.id, voucherId })

  const isLoading = isLoadingProfile || isLoadingVoucher

  if (isLoading) return <LoadingData />

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Mis facturas
          </Text>
        </div>

        <Text>Aquí tienes todas las facturas del bono</Text>

        <div className={styles.content}>
          {voucher?.Payments?.map((payment) => {
            return <Invoice key={payment.id} payment={payment} />
          })}
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(VoucherInvoicesScreen)
