import React from 'react'
import styles from './Tag.module.css'
import c from 'classnames'
import Text from '../text/Text'
import { VARIANTS } from '../../../types/Components.types'

interface IProps {
  className?: string
  variant?: 'ntrl' | 'primary' | 'secondary' | 'max' | 'negative'
  textVariant?: VARIANTS
  children: React.ReactNode
}

const Tag = ({ className, variant = 'ntrl', textVariant = VARIANTS.white, children }: IProps) => {
  return (
    <Text
      className={c(styles.tag, variant && styles[variant], className)}
      element="span"
      size="sm"
      variant={textVariant}
      weight="500"
    >
      {children}
    </Text>
  )
}

export default React.memo(Tag)
