import { DateTime } from 'luxon'
import React from 'react'
import styles from './InvoiceRow.module.css'
import Card from '../../atoms/card/Card'
import Text from '../../atoms/text/Text'
import IconDownload from '../../icons/IconDownload'

interface IProps {
  headline: string
  paidAt: string
  onClick: () => void
}

const InvoiceRow = ({ headline, paidAt, onClick }: IProps) => {
  return (
    <Card className={styles.container} onClick={onClick}>
      <div className={styles.content}>
        <Text weight="500" className={styles.headline}>
          {headline}
        </Text>

        <Text className={styles.purchaseAt} size="sm">
          Pagada el {DateTime.fromISO(paidAt).toLocaleString(DateTime.DATE_SHORT)}
        </Text>
      </div>

      <IconDownload className={styles.icon} size="s" variant="max" />
    </Card>
  )
}

export default React.memo(InvoiceRow)
