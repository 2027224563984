import React, { useEffect, useRef, useState } from 'react'
import { VARIANTS } from '../../../types/Components.types'
import Button from '../../atoms/button/Button'
import Text from '../../atoms/text/Text'
import styles from './ConfirmModal.module.css'

interface ConfirmModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  message: string
  confirmText?: string
  confirmVariant?: VARIANTS
  cancelText?: string
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText = 'Confirm',
  confirmVariant = VARIANTS.negative,
  cancelText = 'Cancel',
}) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    if (isOpen) {
      setIsAnimating(true)
      document.addEventListener('keydown', handleEscape)
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
      document.body.style.overflow = 'visible'
    }
  }, [isOpen, onClose])

  useEffect(() => {
    if (!isOpen) {
      const timer = setTimeout(() => setIsAnimating(false), 300) // Match this with your CSS animation duration
      return () => clearTimeout(timer)
    }
  }, [isOpen])

  if (!isOpen && !isAnimating) return null

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      onClose()
    }
  }

  return (
    <div
      className={`${styles.overlay} ${isOpen ? styles.overlayEnter : styles.overlayExit}`}
      onClick={handleOverlayClick}
    >
      <div className={`${styles.modal} ${isOpen ? styles.modalEnter : styles.modalExit}`} ref={modalRef}>
        <Text element="h2" size="l" weight={500}>
          {title}
        </Text>
        <Text>{message}</Text>
        <div className={styles.buttons}>
          <Button autoSize inverted onClick={onClose}>
            {cancelText}
          </Button>
          <Button variant={confirmVariant} autoSize onClick={onConfirm}>
            {confirmText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal
