import React from 'react'
import styles from './LoadingData.module.css'
import c from 'classnames'
import Logo from '../../atoms/logo/Logo'
import Text from '../../atoms/text/Text'
import Loader from '../../atoms/loader/Loader'

interface IProps {
  className?: string
}

const LoadingData = ({ className }: IProps) => {
  return (
    <div className={c(styles.container, className)}>
      <Logo className={styles.logoWrapper} />

      <Loader variant="primary" />
      <Text>Estamos cargando los datos...</Text>
    </div>
  )
}

export default React.memo(LoadingData)
