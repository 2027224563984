import React, { useCallback } from 'react'
import Text from '../text/Text'
import TouchableOpacity from '../touchableOpacity/TouchableOpacity'
import styles from './Counter.module.css'
import c from 'classnames'
import IconIncrement from '../../icons/IconIncrement'
import IconDecrement from '../../icons/IconDecrement'
import { VARIANTS } from '../../../types/Components.types'

interface IProps {
  className?: string
  value: number
  min?: number
  max?: number
  onChange?: (value: number) => void
}

const Counter = ({ className, value, min = 0, max = -1, onChange }: IProps) => {
  const handleIncrementClick = useCallback(() => {
    const nextValue = value + 1
    if (max !== -1 && nextValue > max) {
      return
    }
    onChange?.(nextValue)
  }, [value, onChange, max])

  const handleDecrementClick = useCallback(() => {
    const nextValue = value - 1
    if (nextValue < min) {
      return
    }
    onChange?.(nextValue)
  }, [value, onChange, min])

  return (
    <div className={c(styles.container, className)}>
      <TouchableOpacity className={c(styles.action, styles.increment)} onClick={handleIncrementClick}>
        <Text className={styles.currentValue} align="center" variant={VARIANTS.secondary} weight="500">
          {value}
        </Text>
        <IconIncrement variant="secondary" size="xs" />
      </TouchableOpacity>

      <TouchableOpacity className={c(styles.action, styles.decrement)} onClick={handleDecrementClick}>
        <IconDecrement size="xs" variant="secondary" />
      </TouchableOpacity>
    </div>
  )
}

export default React.memo(Counter)
