import React from 'react'
import Text from '../../../../components/atoms/text/Text'
import styles from './Contact.module.css'
import logo from './assets/logo.png'

const Contact = () => {
  return (
    <>
      <section className={styles.container}>
        <Text weight="500" size="xl" className={styles.title} align="center">
          ¿Tienes dudas? Contáctanos
        </Text>

        <div className={styles.contactWrapper}>
          <img src={logo} className={styles.logo} alt="" />

          <div className={styles.contactData}>
            <Text size="ml">Centro de Difusión de la Innovación</Text>
            <Text size="ml" className={styles.p}>
              C/ Calvario, 38 Teléfono: 918901602
            </Text>
            <Text size="ml" className={styles.p}>
              Correo:{' '}
              <a href="mailto:cdi@aytosanlorenzo.es" rel="noreferrer" target="_blank">
                cdi@aytosanlorenzo.es
              </a>
            </Text>
            <Text size="ml" className={styles.p}>
              Web:{' '}
              <a href="cdi.aytosanlorenzo.es" rel="noreferrer" target="_blank">
                cdi.aytosanlorenzo.es
              </a>
            </Text>
          </div>
        </div>

        <div className={styles.contactWrapper}>
          <div className={styles.contactData}>
            <Text size="ml">Ayuntamiento de San Lorenzo de El Escorial</Text>
            <Text size="ml" className={styles.p}>
              Plaza de la Constitución, 3
            </Text>
            <Text size="ml" className={styles.p}>
              28200 - San Lorenzo de el Escorial, España
            </Text>
            <Text size="ml" className={styles.p}>
              NIF: P2813100A
            </Text>
          </div>
        </div>
      </section>
    </>
  )
}

export default React.memo(Contact)
