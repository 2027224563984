import * as Yup from 'yup'

const profileFormSchema = Yup.object({
  empresa: Yup.string().required('El nombre de la empresa es obligatorio'),
  cif: Yup.string().required('El NIF es obligatorio'),
  empresaDireccion: Yup.object().shape({
    street: Yup.string().required('La dirección es obligatoria'),
    postalCode: Yup.string().required('El código postal es obligatorio'),
    state: Yup.string().required('La provincia es obligatoria'),
    locality: Yup.string().required('La localidad es obligatoria'),
    country: Yup.string().required('El país es obligatorio'),
  }),
})

export default profileFormSchema
