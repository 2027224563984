import { DateTime } from 'luxon'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import LoadingData from '../../../../../../components/molecules/loadingData/LoadingData'
import { useProfile } from '../../../../../../modules/auth/hooks'
import { useMeetingRoomReservations } from '../../../../../../modules/meetingRoom/hooks'
import { useReservatedSeats } from '../../../../../../modules/seats/hooks'
import { TYPES } from '../../../../../../providers/bookAssistant/types'
import { IMeetingRoomReservation, IReservatedSeat } from '../../../../../../types/app.types'
import BookItem from '../bookItem/BookItem'
import SeeAll from '../seeAll/SeeAll'
import styles from './BooksCarousel.module.css'
interface IProps {
  limit?: number
}

const BooksCarousel = ({ limit = 4 }: IProps) => {
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: reservatedSeats, isLoading: isLoadingReservatedSeats } = useReservatedSeats({ userId: profile?.id })
  const isLoading = isLoadingProfile || isLoadingReservatedSeats
  const { data: meetingRoomReservations } = useMeetingRoomReservations({
    userId: profile?.id,
  })
  const toDisplayBooks = [...(meetingRoomReservations || []), ...(reservatedSeats || [])]
    .sort((a, b) => {
      const isMeetingRoom = a.type === TYPES.MEETING_ROOM
      let aFrom = isMeetingRoom ? (a as IMeetingRoomReservation).reserved_from : (a as IReservatedSeat).from
      let bFrom = isMeetingRoom ? (b as IMeetingRoomReservation).reserved_from : (b as IReservatedSeat).from

      // Keep meeting rooms for today at the beginning
      if (a.type === TYPES.MEETING_ROOM && DateTime.fromISO(aFrom).hasSame(DateTime.local(), 'day')) {
        return -1
      } else if (b.type === TYPES.MEETING_ROOM && DateTime.fromISO(bFrom).hasSame(DateTime.local(), 'day')) {
        return 1
      }

      return DateTime.fromISO(aFrom).toMillis() - DateTime.fromISO(bFrom).toMillis()
    })
    .slice(0, limit)

  const child = toDisplayBooks.map((reservation) => {
    const isMeetingRoom = reservation.type === TYPES.MEETING_ROOM
    const from = isMeetingRoom
      ? (reservation as IMeetingRoomReservation).reserved_from
      : (reservation as IReservatedSeat)?.seat?.reserved_from
    const to = isMeetingRoom
      ? (reservation as IMeetingRoomReservation).reserved_to
      : (reservation as IReservatedSeat)?.seat?.reserved_to
    const voucherId = isMeetingRoom
      ? (reservation as IMeetingRoomReservation).Users_MeetingRoom_Pool?.Users_Voucher?.VoucherId
      : (reservation as IReservatedSeat).seat.userVoucher
    const name = isMeetingRoom ? '' : (reservation as IReservatedSeat).name
    const reservationId = isMeetingRoom
      ? (reservation as IMeetingRoomReservation).id
      : (reservation as IReservatedSeat).seat.id

    return (
      <div
        key={
          reservation.type === TYPES.MEETING_ROOM
            ? (reservation as IMeetingRoomReservation).id
            : (reservation as IReservatedSeat).seat.id
        }
        className={styles.carouselItem}
      >
        <BookItem
          reservationId={reservationId}
          type={reservation.type}
          from={from}
          to={to}
          voucherId={voucherId}
          name={name}
        />
      </div>
    )
  })

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <Carousel
      showArrows={false}
      showThumbs={false}
      showStatus={false}
      showIndicators
      className={styles.carousel}
      dynamicHeight
      emulateTouch
    >
      {child as any}
      <div className={styles.carouselItem}>
        <SeeAll />
      </div>
    </Carousel>
  )
}

export default React.memo(BooksCarousel)
