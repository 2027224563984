import * as Yup from 'yup'

const identityCardFormSchema = Yup.object({
  identityCard: Yup.object({
    front: Yup.mixed().required('Tienes que hacer una foto del DNI por la parte delantera'),
    back: Yup.mixed().required('Tienes que hacer una foto del DNI por la parte trasera'),
  }),
})

export default identityCardFormSchema
