import React, { useCallback, useRef } from 'react'
import Button from '../../../../../../../../components/atoms/button/Button'
import Text from '../../../../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../../../../types/Components.types'
import styles from './UploadFile.module.css'

interface IProps {
  onChange?: (files: File[]) => void
  files: File[]
}

const UploadFile = ({ onChange, files }: IProps) => {
  const input = useRef<HTMLInputElement>(null)

  const handleOpenFileSelector = useCallback(() => {
    input.current?.click()
  }, [])

  const handleAttachmentsChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(evt.target.files ?? [])
      onChange?.(Array.isArray(files) ? files : [files])
    },
    [onChange],
  )

  const getFileName = useCallback(() => {
    const file = files[0]
    return file.name
  }, [files])

  return (
    <div>
      <Button className={styles.button} autoSize variant={VARIANTS.secondary} inverted onClick={handleOpenFileSelector}>
        <Text size="sm" variant={VARIANTS.secondary} weight="500" truncate>
          {files.length === 0 ? 'Seleccionar' : getFileName()}
        </Text>
      </Button>
      <input ref={input} className={styles.input} type="file" onChange={handleAttachmentsChange} />
    </div>
  )
}

export default React.memo(UploadFile)
