import React from 'react'
import styles from './WifiPassword.module.css'
import Card from '../../atoms/card/Card'
import c from 'classnames'
import Text from '../../atoms/text/Text'
import IconWifi from '../../icons/IconWifi'
import { useWifiPassword } from '../../../modules/seats/hooks'

interface IProps {
  className?: string
}

const WifiPassword = ({ className }: IProps) => {
  const { data } = useWifiPassword()

  if (!data?.pass || !data?.user) {
    return null
  }

  return (
    <Card className={c(styles.container, className)}>
      <div className={styles.iconWrapper}>
        <IconWifi variant="max" size="m" />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Text className={styles.label} size="xs" weight="600">
            Tu usuario:
          </Text>
          <Text size="m" truncate className={styles.username}>
            {data.user}
          </Text>
        </div>
        <div className={styles.content}>
          <Text className={styles.label} size="xs" weight="600">
            Tu contraseña:
          </Text>
          <Text size="m">{data.pass}</Text>
        </div>
      </div>
    </Card>
  )
}

export default React.memo(WifiPassword)
