import React from 'react'
import styles from './ListItem.module.css'
import c from 'classnames'
import Text from '../../atoms/text/Text'

interface IProps {
  className?: string
  label: string
  children: React.ReactNode
  icon?: React.ReactNode
}

const ListItem = ({ className, icon, label, children }: IProps) => {
  return (
    <div className={c(styles.container, className)}>
      <span className={styles.content}>
        {React.isValidElement(icon) ? icon : null}
        <Text>{label}</Text>
      </span>
      {children}
    </div>
  )
}

export default React.memo(ListItem)
