import c from 'classnames'
import { DateTime, Settings } from 'luxon'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Card from '../../../../../../components/atoms/card/Card'
import Tag from '../../../../../../components/atoms/tag/Tag'
import Text from '../../../../../../components/atoms/text/Text'
import LoadingData from '../../../../../../components/molecules/loadingData/LoadingData'
import routes, { generateRoute } from '../../../../../../config/routes'
import { useProfile } from '../../../../../../modules/auth/hooks'
import { usePurchasedVoucher } from '../../../../../../modules/vouchers/hooks'
import { getBookMonths } from '../../../../../../modules/vouchers/utils'
import { TYPES } from '../../../../../../providers/bookAssistant/types'
import { VARIANTS } from '../../../../../../types/Components.types'
import styles from './BookItem.module.css'
Settings.defaultLocale = 'es'

interface IProps {
  className?: string
  from: string
  to: string
  voucherId: string
  name?: string
  type: string
  reservationId: string
}

const BookItem = ({ className, reservationId, type, from, to, voucherId, name }: IProps) => {
  const history = useHistory()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: voucher, isLoading: isLoadingVoucher } = usePurchasedVoucher({
    userId: profile?.id,
    voucherId,
  })
  const isLoading = isLoadingProfile || isLoadingVoucher
  const isMeetingRoomReservation = type === 'meetingroom'

  const goToReservation = useCallback(() => {
    if (isMeetingRoomReservation) {
      return history.push(
        generateRoute(routes.book.meetingRoomDetails, [{ name: 'reservationId', value: reservationId }]),
      )
    }
    history.push(generateRoute(routes.book.details, [{ name: 'bookId', value: reservationId }]))
  }, [isMeetingRoomReservation, history, reservationId])

  let shift = ''
  if (from && to) {
    shift = `De ${DateTime.fromISO(from).toFormat('HH:mm')} a ${DateTime.fromISO(to).toFormat('HH:mm')}`
  }

  let title = ''
  if (type === TYPES.MEETING_ROOM) {
    title = (from ? DateTime.fromISO(from) : DateTime.local()).toFormat("d 'de' LLLL")
  } else if (type === TYPES.MONTH) {
    title = DateTime.fromISO(from).toFormat("'Mes de' LLLL")
  } else {
    title = DateTime.fromISO(from).toFormat("d 'de' LLLL")
  }

  let tags = null
  if (type === TYPES.MONTH) {
    const monthsToRenew = getBookMonths({ voucher })

    tags = (
      <Tag className={styles.tag} variant="secondary" textVariant={VARIANTS.white}>
        {monthsToRenew > 0 ? `${monthsToRenew} mes${monthsToRenew === 1 ? '' : 'es'}` : 'Indefinido'}
      </Tag>
    )
  }

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <Card className={c(styles.container, className)} onClick={goToReservation}>
      <div className={styles.infoWrapper}>
        <Text weight="600">{title}</Text>
        <Text size="sm">{shift}</Text>
        {tags}
      </div>
      <div
        className={c(
          styles.decorator,
          !isMeetingRoomReservation && styles.seat,
          isMeetingRoomReservation && styles.meetingsRoom,
        )}
      >
        <div className={styles.decoratorContent}>
          <Text weight="500" size="l" align="right" variant={VARIANTS.white}>
            {isMeetingRoomReservation ? 'Reunión' : name}
          </Text>
        </div>
      </div>
    </Card>
  )
}

export default React.memo(BookItem)
