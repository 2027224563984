import React, { useCallback } from 'react'
import styles from './Radio.module.css'
import c from 'classnames'

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  className?: string
  label?: string | React.ReactNode
  isChecked: boolean
  name: string
  onChange?: (name: string, value: React.InputHTMLAttributes<HTMLInputElement>['value']) => void
}

const Radio = ({ className, label, value, isChecked, name, onChange, ...rest }: IProps) => {
  const handleInputChange = useCallback(() => {
    onChange?.(name, value)
  }, [name, onChange, value])

  return (
    <label className={c(styles.container, className)}>
      <input type="radio" name={name} checked={isChecked} value={value} onChange={handleInputChange} {...rest} />
      <span className={styles.checkmark}></span>
      {label && label}
    </label>
  )
}

export default React.memo(Radio)
