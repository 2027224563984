import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import Text from '../../../../components/atoms/text/Text'
import LinkRow from '../../../../components/molecules/linkRow/LinkRow'
import Screen from '../../../../components/molecules/screen/Screen'
import UnlockSlider from '../../../../components/molecules/unlockSlider/UnlockSlider'
import routes, { generateRoute } from '../../../../config/routes'
import { useProfile } from '../../../../modules/auth/hooks'
import { useMeetingRoomReservationById } from '../../../../modules/meetingRoom/hooks'
import { getReservationTo, isReservedForToday } from '../../../../modules/meetingRoom/utils'
import { VARIANTS } from '../../../../types/Components.types'
import styles from './MeetingRoomDetailsScreen.module.css'

interface IParams {
  reservationId: string
}

const MeetingRoomDetailsScreen = () => {
  const { reservationId } = useParams<IParams>()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: reservation, isLoading: isLoadingMeetingRoomReservation } = useMeetingRoomReservationById({
    userId: profile?.id,
    reservationId,
  })

  const isLoading = isLoadingProfile || isLoadingMeetingRoomReservation

  const reservedTo = getReservationTo({ reservation })
  const isForToday = isReservedForToday({ reservation })

  const canChangeReservationSlot = useCallback(() => {
    const diffDays = reservedTo.diffNow('days').days
    if (diffDays < 0) {
      return false
    }

    if (diffDays > 2) {
      return true
    }

    return DateTime.local().weekday === 4
  }, [reservedTo])

  if (!reservation || isLoading) {
    return null
  }

  return (
    <Screen
      headerProps={{
        variant: VARIANTS.pig,
        title: 'Mi reserva',
        backEnabled: true,
      }}
    >
      <div className={styles.container}>
        <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
          Aquí tienes los detalles de tu reserva
        </Text>

        <Text>{`Recuerda que pasadas las ${reservedTo.toFormat(
          'T',
        )}h tendrás que abandonar la sala de reuniones`}</Text>

        <Text className={styles.helpText}>
          Puedes cambiar la reserva tantas veces como quieras, pero tienes que tener en cuenta que sólo podrás
          modificarla con una antelación de dos días laborales
        </Text>

        {isForToday && (
          <div className={styles.block}>
            <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
              Abre la sala de reuniones
            </Text>
            <UnlockSlider book={reservation} />
          </div>
        )}

        <div className={styles.actions}>
          <Text weight="500" className={styles.actionsTitle}>
            Acciones
          </Text>

          <LinkRow
            className={styles.row}
            path={generateRoute(routes.settings.account.vouchers.byId, [
              { name: 'voucherId', value: reservation?.Users_MeetingRoom_Pool?.UsersVoucherId },
            ])}
          >
            Ver bono de la reserva
          </LinkRow>

          {canChangeReservationSlot() && (
            <LinkRow
              className={styles.row}
              path={generateRoute(routes.book.changeMeetingRoom, [{ name: 'reservationId', value: reservation.id }])}
            >
              Cambiar hora de la reunión
            </LinkRow>
          )}
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(MeetingRoomDetailsScreen)
