import * as Yup from 'yup'

const recoverPasswordFormSchema = Yup.object({
  password: Yup.string().required('Debes introducir la nueva contraseña'),
  passwordConfirmation: Yup.string()
    .required('Debes confirmar la contraseña')
    .when('password', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Las contraseñas tienen que coincidir'),
    }),
})

export default recoverPasswordFormSchema
