import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconBulb = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M19.325 23.326a.74.74 0 00.554-.217.78.78 0 00.211-.568v-2.004c0-.623.148-1.212.443-1.767.295-.555.662-1.126 1.102-1.712.44-.587.878-1.223 1.314-1.91.435-.685.803-1.457 1.102-2.314.3-.857.449-1.846.449-2.965 0-1.48-.231-2.825-.693-4.034a8.945 8.945 0 00-1.987-3.127 8.697 8.697 0 00-3.09-2.01C17.535.231 16.205 0 14.744 0c-1.46 0-2.788.232-3.98.697a8.71 8.71 0 00-3.083 2.01 8.945 8.945 0 00-1.987 3.128C5.231 7.045 5 8.389 5 9.869c0 1.12.147 2.108.442 2.965.295.857.663 1.629 1.103 2.315.44.686.878 1.322 1.313 1.909.436.586.803 1.157 1.103 1.712.299.555.449 1.144.449 1.767v2.004c0 .234.068.424.204.568.137.145.324.217.561.217h9.15zm-1.268-2.098h-6.615v-.691c0-.776-.149-1.487-.448-2.132-.3-.646-.665-1.275-1.096-1.889-.431-.613-.867-1.245-1.307-1.895a10.42 10.42 0 01-1.102-2.132c-.295-.772-.443-1.645-.443-2.62 0-1.552.322-2.913.964-4.082a6.775 6.775 0 012.693-2.714c1.153-.64 2.5-.961 4.04-.961 1.55 0 2.898.32 4.047.961a6.787 6.787 0 012.687 2.714c.642 1.17.963 2.53.963 4.082 0 .975-.147 1.848-.442 2.62a10.62 10.62 0 01-1.096 2.132c-.435.65-.871 1.282-1.307 1.895-.435.614-.8 1.243-1.096 1.889a5.07 5.07 0 00-.442 2.132v.69zm.858 5.618c.326 0 .605-.122.839-.366.233-.244.35-.537.35-.88 0-.343-.117-.634-.35-.873a1.13 1.13 0 00-.839-.359h-8.33c-.335 0-.616.12-.845.359-.23.24-.344.53-.344.873 0 .343.115.636.344.88.229.244.51.366.845.366h8.33zM14.743 30c.89 0 1.624-.194 2.205-.582.581-.388.902-.903.964-1.543h-6.324c.053.64.367 1.155.944 1.543.576.388 1.314.582 2.211.582z" />
      </svg>
    </i>
  )
}

export default IconBulb
