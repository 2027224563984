import { ValueOf } from '../../types/app.types'
import { ITypes, TYPES } from './types'

export interface IShift {
  from: number
  to: number
  label: string
  shortLabel: string
  suitableTo: ValueOf<ITypes>[]
}

export interface IShifts {
  morning: IShift
  evening: IShift
  day: IShift
}

export const SHIFTS: IShifts = {
  morning: {
    from: 8,
    to: 15,
    label: 'Mañana / 8 - 14:30h',
    shortLabel: 'Mañana',
    suitableTo: [TYPES.DAY, TYPES.MONTH],
  },
  evening: {
    from: 14,
    to: 21,
    label: 'Tarde / 14:30 - 21:00h',
    shortLabel: 'Tarde',
    suitableTo: [TYPES.DAY, TYPES.MONTH],
  },
  day: {
    from: 8,
    to: 21,
    label: 'Día / 8 - 21:00h',
    shortLabel: 'Día',
    suitableTo: [TYPES.MONTH],
  },
}
