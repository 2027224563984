import React from 'react'
import Text from '../../../../components/atoms/text/Text'
import styles from './Prices.module.css'
import Card from '../../../../components/atoms/card/Card'
import { prices } from '../../../../config/index'

const Prices = () => {
  return (
    <>
      <section className={styles.container}>
        <Text weight="500" size="xl" align="center" className={styles.title}>
          Precios del coworking
        </Text>

        <Text size="ml" align="center" className={styles.p}>
          Los precios para acceder al coworking dependen del tipo de sitio elegido así como de la franja horaria y de la
          modalidad de la reserva. Los bonos de días sueltos caducan a los 3 meses de su adquisición. Los precios{' '}
          <Text element="span" weight="600">
            no incluyen IVA
          </Text>
          . A estos precios hay que añadirles el 21% de IVA.
        </Text>

        <Text size="ml" align="center" className={styles.p}>
          Las empresas y autónomos, con domicilio fiscal y/o actividad en San Lorenzo de El Escorial, así como los
          empadronados, disfrutarán de un 20% de descuento.
        </Text>

        <Text size="ml" align="center" className={styles.p}>
          El cobro se realizará mediante una pasarela de pagos y podrás pagar con{' '}
          <Text element="span" weight="600">
            Visa
          </Text>{' '}
          o{' '}
          <Text element="span" weight="600">
            Mastercard
          </Text>
        </Text>

        <div className={styles.seats}>
          <Card className={styles.seatDetails}>
            <div className={styles.header}>
              <Text weight="500" size="l" align="center" className={styles.seatTitle}>
                Zona Roja o Amarilla
              </Text>
            </div>

            <div className={styles.row}>
              <Text size="sm">Por mes con acceso de 8:00 a 21h</Text>

              <Text size="ml" weight="500" className={styles.price}>
                150€
              </Text>
            </div>

            <div className={styles.row}>
              <Text size="sm">Por mes con acceso de 8:00 a 14:30h o de 14:30 a 21 horas</Text>

              <Text size="ml" weight="500" className={styles.price}>
                100€
              </Text>
            </div>

            <div className={styles.row}>
              <Text size="sm">Por días (Bono de 10 días)</Text>

              <Text size="ml" weight="500" className={styles.price}>
                95€
              </Text>
            </div>

            <div className={styles.row}>
              <Text size="sm">Día suelto</Text>

              <Text size="ml" weight="500" className={styles.price}>
                15€
              </Text>
            </div>
          </Card>

          {/* <Card className={styles.seatDetails}>
            <div className={styles.header}>
              <IconSeat className={c(styles.icon, styles.blue)} />

              <Text weight="500" size="l" align="center" className={styles.seatTitle}>
                Zona Azul
              </Text>
            </div>

            <div className={styles.row}>
              <Text size="sm">Por mes con acceso de 9 a 21h</Text>

              <Text size="ml" weight="500" className={styles.price}>
                160€
              </Text>
            </div>

            <div className={styles.row}>
              <Text size="sm">Por mes con acceso de 9 a 14h o de 14 a 21h</Text>

              <Text size="ml" weight="500" className={styles.price}>
                104€
              </Text>
            </div>

            <div className={styles.row}>
              <Text size="sm">Por días (Bono de 10 días)</Text>

              <Text size="ml" weight="500" className={styles.price}>
                204€
              </Text>
            </div>
          </Card> */}
        </div>

        <div>
          <a className={styles.button} href={prices} rel="noreferrer" target="_blank">
            Consulta los precios oficiales
          </a>
        </div>
      </section>

      {/* <section className={styles.container}>
        <Text weight="500" size="xl" align="center" className={styles.title}>
          Precio de la sala de reuniones
        </Text>

        <Text size="ml" align="center" className={styles.p}>
          Si necesitas convocar una reunión, o simplemente quieres discutir tu proyecto con tus compañeros, siempre
          tienes la posibilidad de acceder a la sala de reuniones por horas. Las reservas se adquieren en formato bono
          de 10 horas consumibles en los siguientes 3 meses a su compra. Los precios{' '}
          <Text element="span" weight="600">
            no incluyen IVA
          </Text>
          . A estos precios hay que añadirles el 21% de IVA.
        </Text>

        <div className={styles.seats}>
          <Card className={styles.seatDetails}>
            <div className={styles.header}>
              <Text weight="500" size="l" align="center" className={styles.seatTitle}>
                Sala de reuniones
              </Text>
            </div>

            <div className={styles.row}>
              <Text size="sm">Bono de 10 horas</Text>

              <Text size="ml" weight="500" className={styles.price}>
                15€
              </Text>
            </div>
          </Card>
        </div>

        <div>
          <a className={styles.button} href={prices} rel="noreferrer" target="_blank">
            Consulta los precios oficiales
          </a>
        </div>
      </section> */}
    </>
  )
}

export default React.memo(Prices)
