import React from 'react'
import TouchableOpacity from '../../atoms/touchableOpacity/TouchableOpacity'
import styles from './ToggleButton.module.css'
import c from 'classnames'

interface IProps {
  className?: string
  isActive?: boolean
  disabled?: boolean
  children: React.ReactNode
  onClick?: () => void
}

const ToggleButton = ({ className, isActive, disabled, ...rest }: IProps) => {
  return (
    <TouchableOpacity
      className={c(styles.container, className, isActive && styles.active, disabled && styles.disabled)}
      {...rest}
    />
  )
}

export default React.memo(ToggleButton)
