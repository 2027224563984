import React, { useCallback } from 'react'
import styles from './Switch.module.css'
import c from 'classnames'

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  className?: string
  checked: boolean
  name: string
  onChange?: (checked: boolean) => void
}

const Switch = ({ className, checked, name, onChange, ...rest }: IProps) => {
  const handleInputChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(evt.target.checked)
    },
    [onChange],
  )

  return (
    <label className={c(styles.container, checked && styles.on, className)}>
      <input type="checkbox" name={name} checked={checked} onChange={handleInputChange} {...rest} />

      <span className={styles.switch}></span>
    </label>
  )
}

export default React.memo(Switch)
