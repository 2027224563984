import React from 'react'
import styles from './ForgotPasswordScreen.module.css'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import forgotPasswordFormSchema from './schemas/forgotPassword'
import Screen from '../../../../../components/molecules/screen/Screen'
import BackButton from '../../../../../components/atoms/backButton/BackButton'
import Text from '../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../types/Components.types'
import FormField from '../../../../../components/molecules/formField/FormField'
import Input from '../../../../../components/atoms/input/Input'
import Button from '../../../../../components/atoms/button/Button'
import { useForgotPasswordMutation } from '../../../../../modules/auth/hooks'
import routes from '../../../../../config/routes'

const ForgotPasswordScreen = () => {
  const history = useHistory()
  const forgotPassword = useForgotPasswordMutation()

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, isValid, isSubmitting } = useFormik({
    initialValues: {
      email: '',
    },
    validateOnMount: true,
    validationSchema: forgotPasswordFormSchema,
    onSubmit: async (values) => {
      forgotPassword.mutate(
        { email: values.email },
        {
          onSuccess: () => {
            history.replace(routes.password.emailSent)
          },
        },
      )
    },
  })

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Recuperar tu contraseña
          </Text>
        </div>
        <Text>
          Para reestablecer tu contraseña necesitamos que nos digas el correo electrónico con el que te registraste en
          el coworking
        </Text>

        <form className={styles.content} onSubmit={handleSubmit}>
          <FormField
            field={
              <Input
                name="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched.email && errors.email ? errors.email : null}
          />

          <Button
            variant={VARIANTS.primary}
            className={styles.action}
            type="submit"
            disabled={!isValid}
            isLoading={isSubmitting}
          >
            Recuperar contraseña
          </Button>
        </form>
      </div>
    </Screen>
  )
}

export default React.memo(ForgotPasswordScreen)
