import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconInfo = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M15 30c2.058 0 3.992-.394 5.802-1.18a15.361 15.361 0 008.025-8.025C29.61 18.989 30 17.058 30 15s-.39-3.99-1.173-5.795a15.305 15.305 0 00-3.248-4.784 15.28 15.28 0 00-4.792-3.248C18.977.39 17.042 0 14.985 0c-2.037 0-3.961.39-5.772 1.173A15.28 15.28 0 004.421 4.42a15.305 15.305 0 00-3.248 4.784C.39 11.011 0 12.942 0 15s.394 3.99 1.18 5.795a15.387 15.387 0 003.25 4.775 15.361 15.361 0 004.784 3.248C11.023 29.606 12.953 30 15 30zm0-2.762c-1.687 0-3.272-.317-4.753-.95a12.27 12.27 0 01-3.897-2.623 12.164 12.164 0 01-2.615-3.896c-.628-1.482-.942-3.071-.942-4.769 0-1.687.314-3.272.942-4.753a12.254 12.254 0 016.504-6.536c1.477-.632 3.058-.949 4.746-.949 1.707 0 3.3.317 4.776.95a12.32 12.32 0 016.528 6.535c.632 1.481.949 3.066.949 4.753.01 1.698-.304 3.287-.942 4.769a12.441 12.441 0 01-2.623 3.896 12.179 12.179 0 01-3.889 2.624c-1.482.632-3.076.949-4.784.949zm-.108-17.284c.545 0 1.006-.193 1.381-.579.376-.386.563-.846.563-1.381 0-.556-.187-1.024-.563-1.404a1.865 1.865 0 00-1.381-.571c-.535 0-.993.19-1.373.57a1.91 1.91 0 00-.571 1.405c0 .535.19.995.57 1.381.381.386.839.579 1.374.579zm3.472 13.04c.32 0 .587-.103.803-.309a1.04 1.04 0 00.324-.787 1.02 1.02 0 00-.324-.771 1.119 1.119 0 00-.803-.31h-1.697v-7.144c0-.412-.103-.746-.309-1.003-.206-.257-.51-.386-.91-.386h-2.763c-.308 0-.573.103-.795.309a1.01 1.01 0 00-.331.771c0 .32.11.581.331.787.222.206.487.309.795.309h1.513v6.358h-1.76c-.319 0-.586.103-.802.309a1.021 1.021 0 00-.324.771c0 .32.108.581.324.787.216.206.483.309.802.309h5.926z" />
      </svg>
    </i>
  )
}

export default IconInfo
