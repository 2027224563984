import { useFormik } from 'formik'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import Input from '../../../../../../components/atoms/input/Input'
import Text from '../../../../../../components/atoms/text/Text'
import FormField from '../../../../../../components/molecules/formField/FormField'
import { useProfileAssistant } from '../../../../../../providers/profileAssistant'
import { VARIANTS } from '../../../../../../types/Components.types'
import { IStepProps, IStepRef } from '../types'
import styles from './PhoneStep.module.css'
import phoneFormSchema from './schema/phone'

const PhoneStep = forwardRef<IStepRef, IStepProps>(({ onSubmit, onIsSubmittingChange }, ref) => {
  const { phone, firstname, setPhone } = useProfileAssistant()

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, isValid, isSubmitting } = useFormik({
    initialValues: {
      phone,
    },
    validationSchema: phoneFormSchema,
    onSubmit: async (values) => {
      return new Promise<void>((resolve) => {
        setPhone(values)
        resolve()
        onSubmit?.()
      })
    },
  })

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
    isValid,
    isSubmitting,
  }))

  useEffect(() => {
    onIsSubmittingChange && onIsSubmittingChange(isSubmitting)
  }, [onIsSubmittingChange, isSubmitting])

  return (
    <div className={styles.content}>
      <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
        ¡Hola, {firstname}!
      </Text>

      <Text className={styles.description}>
        Déjanos un teléfono de contacto para poder avisarte si hay algún imprevisto.
      </Text>

      <form onSubmit={handleSubmit}>
        <FormField
          field={
            <Input
              name="phone"
              type="number"
              placeholder="Mi número de teléfono"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          error={touched.phone ? errors.phone : null}
        />
      </form>
    </div>
  )
})

export default PhoneStep
