import React, { useCallback } from 'react'
import styles from './GDPRScreen.module.css'
import { useLocation } from 'react-router-dom'
import { ILocationState } from '../../../types/app.types'
import Screen from '../../../components/molecules/screen/Screen'
import ScreenTitle from '../../../components/molecules/screenTitle/ScreenTitle'
import GDPRText from '../../../components/molecules/gdprText/GDPRText'

const GDPRScreen = () => {
  const { state } = useLocation<ILocationState>()

  const isBackEnabled = useCallback(() => {
    if (!state || !state.backDisabled) {
      return true
    }
    return false
  }, [state])

  return (
    <Screen hasDecorator>
      <div className={styles.container}>
        <ScreenTitle title="Protección de datos" backEnabled={isBackEnabled()} />

        <div className={styles.content}>
          <GDPRText />
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(GDPRScreen)
