import c from 'classnames'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../../../components/atoms/button/Button'
import Card from '../../../../../components/atoms/card/Card'
import Text from '../../../../../components/atoms/text/Text'
import IconCalendarAdd from '../../../../../components/icons/IconCalendarAdd'
import routes from '../../../../../config/routes'
import { isBillingAddressComplete } from '../../../../../modules/auth'
import { useProfile } from '../../../../../modules/auth/hooks'
import { useRenewableVouchers } from '../../../../../modules/vouchers/hooks'
import { VARIANTS } from '../../../../../types/Components.types'
import styles from './Actions.module.css'

const Actions = () => {
  const history = useHistory()
  const { data: profile } = useProfile()
  const hasBillingAddress = isBillingAddressComplete({ user: profile })
  const { data: vouchersRenewables } = useRenewableVouchers({ userId: profile?.id })

  const goToNewSeatBook = () => {
    if (vouchersRenewables && vouchersRenewables?.length > 0) {
      return history.push(routes.book.new.seat.renewWarning)
    }
    history.push(routes.book.new.seat.details)
  }

  const goToEditProfile = () => {
    history.push(routes.settings.account.billingAddress)
  }

  return (
    <div className={styles.container}>
      <>
        {!hasBillingAddress && (
          <Card className={styles.completeProfileContainer} onClick={goToEditProfile}>
            <div className={styles.wrapper}>
              <Text weight="500">¡Oops! Para poder reservar, tienes que completar tus datos de faturación</Text>

              <Button variant={VARIANTS.primary} onClick={goToEditProfile} className={styles.action}>
                !Vamos!
              </Button>
            </div>
          </Card>
        )}
        {hasBillingAddress && (
          <>
            <Card className={c(styles.actionWrapper, styles.seat)} onClick={goToNewSeatBook}>
              <IconCalendarAdd size="m" variant="white" />
              <Text variant={VARIANTS.white} align="center" size="sm" className={styles.actionTitle}>
                Reservar puesto
              </Text>
            </Card>
          </>
        )}
      </>
    </div>
  )
}

export default React.memo(Actions)
