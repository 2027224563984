import React, { useCallback } from 'react'
import styles from './IdentityCard.module.css'
import c from 'classnames'
import IconCamera from '../../icons/IconCamera'
import AspectRatio from '../../atoms/aspectRatio/AspectRatio'

interface IProps {
  className?: string
  resource?: string
  onChange?: (data: { files: FileList }) => void
}

const IdentityCard = ({ className, resource, onChange }: IProps) => {
  const handleFilesChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = evt.target
      if (!files || !Object.keys(files).length) {
        return
      }

      onChange?.({ files })
    },
    [onChange],
  )

  return (
    <div className={c(styles.container, className)}>
      <AspectRatio className={styles.aspectRatio}>
        {resource && <img src={resource} alt="card" className={styles.card} />}
        <label className={c(styles.icon, resource && styles.floatIcon)}>
          <input type="file" hidden onChange={handleFilesChange} accept="image/*" />
          <IconCamera variant={resource ? 'white' : 'lighter'} />
        </label>
      </AspectRatio>
    </div>
  )
}

export default React.memo(IdentityCard)
