import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import {
  forgotPassword,
  login,
  me,
  recoverPassword,
  register,
  removePaymentCard,
  resendValidationEmail,
  updateProfile,
  validateAccount,
} from '.'

export const useProfile = () => {
  // TODO enable query when Cookieservice has token
  return useQuery({ queryKey: ['me'], queryFn: me })
}

export const useIsLogged = () => {
  const { data, isLoading, isError } = useProfile()

  return { isCheckingLogin: isLoading, isLogged: !isError && !!data }
}

export const useIsGUCSL = () => {
  const { data: user, isLoading, isError } = useProfile()

  return { isCheckingGUCSL: isLoading, isGUCSL: !isError && !!user?.isGUCSL }
}

export const usePaymentCards = () => {
  const { data: user, isLoading, isError } = useProfile()
  const paymentCards = user?.paymentCards || {}
  return { data: paymentCards, isLoading, isError }
}

export const useLoginMutation = () => {
  return useMutation({
    mutationFn: login,
    onSuccess: () => {
      toast.success('Bienvenido de nuevo')
    },
    onError: () => {
      toast.error('Oops! El usuario o la contraseña no son correctos')
    },
  })
}

export const useRegisterMutation = () => {
  return useMutation({
    mutationFn: register,
    onError: (error) => {
      if (error === '400') {
        return toast.error('Oops! Este usuario ya está dado de alta en la app')
      }
      toast.error('Oops! No hemos podido dar de alta tu cuenta. Inténtalo de nuevo más tarde')
    },
  })
}

export const useForgotPasswordMutation = () => {
  return useMutation({
    mutationFn: forgotPassword,
    onError: () => {
      toast.error('Oops! No hemos podido enviarte el email. Inténtalo de nuevo más tarde')
    },
  })
}

export const useRecoverPasswordMutation = () => {
  return useMutation({
    mutationFn: recoverPassword,
    onError: () => {
      toast.error('Oops! No hemos podido actualizar tu contraseña. Inténtalo de nuevo más tarde')
    },
  })
}

export const useValidateAccountMutation = () => {
  return useMutation({
    mutationFn: validateAccount,
    onSuccess: () => {
      toast.success('Tu cuenta ha sido validada correctamente')
    },
    onError: () => {
      toast.error('Oops! No hemos podido validar tu cuenta. Inténtalo de nuevo más tarde')
    },
  })
}

export const useResendValidationEmailMutation = () => {
  return useMutation({
    mutationFn: resendValidationEmail,
    onSuccess: () => {
      toast.success('Te hemos re-enviado un email de validación')
    },
    onError: () => {
      toast.error('Oops! No hemos podido enviarte el email. Inténtalo de nuevo más tarde')
    },
  })
}

export const useUpdateProfileMutation = () => {
  return useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      toast.success('Tu perfil se ha actualizado correctamente')
    },
    onError: () => {
      toast.error('Oops! No hemos podido hacer miembro del grupo en este momento')
    },
  })
}

export const useUpdateGUCSLMutation = () => {
  return useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {},
    onError: () => {},
  })
}

export const useUpdateDNIMutation = () => {
  return useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {},
    onError: () => {},
  })
}

export const useRemovePaymentCard = () => {
  return useMutation({
    mutationFn: removePaymentCard,
    onSuccess: () => {},
    onError: () => {},
  })
}
