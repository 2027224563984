import React, { useRef } from 'react'
import styles from './Blueprint.module.css'
import c from 'classnames'
import { ReactComponent as BluprintSVG } from './assets/blueprint.svg'
import { useSeats, useSeatsQueryParams } from '../../../modules/seats/hooks'
import Loader from '../../atoms/loader/Loader'

interface IProps {
  className?: string
  onSeatSelected?: (seat: string) => void
}

const Blueprint = ({ className, onSeatSelected }: IProps) => {
  const blueprint = useRef(null)
  const queryParams = useSeatsQueryParams()
  const { data: seats, isLoading: isLoadingSeats } = useSeats({ queryParams })
  const initialized = useRef(false)

  const initBlueprint = React.useCallback(() => {
    if (!seats) {
      return
    }

    const svg: SVGElement = blueprint.current!
    const modifiers = {
      disabled: styles.disabled,
      selected: styles.selected,
    }
    seats.forEach(({ id, name, disabled = false }) => {
      const seatElement = svg.querySelector(`[id=${name}]`)
      if (!seatElement) {
        console.error(`Seat named like ${name} was not found in the blueprint`)
        return
      }

      if (disabled && modifiers.disabled) {
        seatElement.classList.add(modifiers.disabled)
      }

      if (disabled) {
        return
      }

      seatElement.addEventListener('click', () => {
        onSeatSelected?.(id)
        const currentSelectedSeat = svg.querySelector(`.${modifiers.selected}`)
        if (currentSelectedSeat) {
          currentSelectedSeat.classList.remove(modifiers.selected)
        }
        seatElement.classList.add(modifiers.selected)
      })
    })
  }, [seats, onSeatSelected])

  React.useEffect(() => {
    if (!blueprint.current || initialized.current || isLoadingSeats) {
      return
    }

    initialized.current = true
    initBlueprint()
  }, [initBlueprint, isLoadingSeats])

  if (isLoadingSeats) {
    return (
      <div className={styles.container}>
        <Loader variant="primary" />
      </div>
    )
  }

  return <BluprintSVG className={c(className)} ref={blueprint} />
}

export default React.memo(Blueprint)
