import React from 'react'
import styles from './Screen.module.css'
import c from 'classnames'
import Header from '../../atoms/header/Header'
import { VARIANTS } from '../../../types/Components.types'

interface IProps {
  hasDecorator?: boolean
  decoratorSize?: 'small' | 'large'
  headerProps?: {
    variant?: VARIANTS
    backEnabled?: boolean
    title?: string
    action?: {
      icon: React.ReactNode
      onClick: () => void
    }
  }
  children: React.ReactNode
}

const Screen = ({
  hasDecorator = false,
  decoratorSize = 'small',
  headerProps = { variant: VARIANTS.cocodrile, backEnabled: false, title: '' },
  children,
}: IProps) => {
  return (
    <div className={styles.container}>
      {hasDecorator && <span className={c(styles.decorator, styles[`decorator-${decoratorSize}`])}></span>}
      {!hasDecorator && <Header {...headerProps} />}
      <div className={c(styles.content, !hasDecorator && styles.abs)}>{children}</div>
    </div>
  )
}

export default React.memo(Screen)
