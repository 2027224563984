import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconNotifications = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M25.69 11.62c.79 0 1.511-.195 2.161-.586a4.505 4.505 0 001.562-1.562c.391-.65.587-1.37.587-2.162 0-.79-.196-1.511-.587-2.161a4.505 4.505 0 00-1.562-1.562A4.115 4.115 0 0025.69 3c-.791 0-1.512.196-2.162.587-.65.39-1.17.911-1.562 1.562a4.115 4.115 0 00-.586 2.161c0 .791.195 1.512.586 2.162.391.65.912 1.17 1.562 1.562.65.39 1.37.586 2.162.586zm-1.582 15.304c1.169 0 2.076-.334 2.722-1.002.646-.668.969-1.661.969-2.98v-9.977a5.221 5.221 0 01-1.042.29c-.36.061-.724.088-1.094.079V22.9l-6.915-6.855 3.7-3.634a5.247 5.247 0 01-.792-.606 5.927 5.927 0 01-.672-.738l-5.602 5.523c-.263.263-.514.452-.751.566a1.628 1.628 0 01-.712.172c-.237 0-.472-.057-.705-.172-.233-.114-.486-.303-.758-.566L3.23 7.474l.073-.025c.205-.057.436-.086.692-.086h15.672a5.21 5.21 0 01.066-1.107c.061-.37.158-.72.29-1.055H3.691c-1.169 0-2.076.336-2.722 1.009C.323 6.882 0 7.873 0 9.182v13.76c0 1.319.338 2.312 1.015 2.98.677.668 1.678 1.002 3.005 1.002h20.088zM2.135 22.95V9.22l-.001-.003 6.958 6.84-6.957 6.893zm21.67 1.824H3.994c-.256 0-.487-.029-.692-.086l-.075-.024 7.289-7.21.74.73c.449.439.89.755 1.325.949.435.193.881.29 1.338.29.448 0 .892-.097 1.331-.29.44-.194.88-.51 1.319-.95l.748-.734 7.27 7.206-.098.033a2.528 2.528 0 01-.684.086z" />
      </svg>
    </i>
  )
}

export default IconNotifications
