import React, { useCallback } from 'react'
import styles from './ForgotPasswordEmailSentScreen.module.css'
import Text from '../../../../../components/atoms/text/Text'
import { useHistory } from 'react-router-dom'
import { ReactComponent as CompleteIllustration } from './assets/complete_illustration.svg'
import Screen from '../../../../../components/molecules/screen/Screen'
import Button from '../../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../../types/Components.types'

const ForgotPasswordEmailSentScreen = ({ ...rest }) => {
  const history = useHistory()

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <CompleteIllustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
            ¡Listo!
          </Text>
          <Text className={styles.description} align="center">
            Hemos enviado un email a tu dirección de correo con las instrucciones para reestablecer tu contraseña
          </Text>
        </div>

        <Button variant={VARIANTS.primary} className={styles.action} onClick={goBack}>
          ¡Vamos!
        </Button>
      </div>
    </Screen>
  )
}

export default React.memo(ForgotPasswordEmailSentScreen)
