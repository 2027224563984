import { useFormik } from 'formik'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import Input from '../../../../../../components/atoms/input/Input'
import Text from '../../../../../../components/atoms/text/Text'
import FormField from '../../../../../../components/molecules/formField/FormField'
import { useProfileAssistant } from '../../../../../../providers/profileAssistant'
import { VARIANTS } from '../../../../../../types/Components.types'
import { IStepProps, IStepRef } from '../types'
import styles from './IdentityCardNumberStep.module.css'
import identityCardNumberFormSchema from './schema/identityCard'

const IdentityCardNumberStep = forwardRef<IStepRef, IStepProps>(({ onSubmit, onIsSubmittingChange }, ref) => {
  const { dni, setDNI } = useProfileAssistant()

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, isValid, isSubmitting } = useFormik({
    initialValues: {
      dni,
    },
    validationSchema: identityCardNumberFormSchema,
    onSubmit: async (values) => {
      return new Promise<void>((resolve) => {
        setDNI(values)
        resolve()
        onSubmit?.()
      })
    },
  })

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
    isValid,
    isSubmitting,
  }))

  useEffect(() => {
    onIsSubmittingChange?.(isSubmitting)
  }, [onIsSubmittingChange, isSubmitting])

  return (
    <div className={styles.container}>
      <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
        ¿Nos das tu número de DNI?
      </Text>

      <Text className={styles.description}>¡Recuerda incluir la letra!</Text>

      <form onSubmit={handleSubmit}>
        <FormField
          field={
            <Input
              name="dni"
              type="text"
              placeholder="Mi DNI"
              value={values.dni}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          error={touched.dni ? errors.dni : null}
        />
      </form>
    </div>
  )
})

export default IdentityCardNumberStep
