import LandingScreen from './screens/landing/LandingScreen'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ProfileAssistantProvider } from './providers/profileAssistant'
import { QueryClientProvider } from '@tanstack/react-query'
import { CoworkingQueryClient } from './modules/CoworkingQueryClient'
import { CardTransitionProvider } from './providers/cardTransition/cardTransition'
import { AppRootScreen } from './screens/app/root/AppRootScreen'
import store from './store'
import { Provider } from 'react-redux'
import routes from './config/routes'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BookAssistantProvider } from './providers/bookAssistant'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://2441f35e9af24b968a6e010be31dc09f@o1420387.ingest.sentry.io/4503958558146560',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 5.0,
    release: '0.1.0',
  })
}

const App = () => {
  return (
    <QueryClientProvider client={CoworkingQueryClient}>
      <Provider store={store}>
        <BookAssistantProvider>
          <ProfileAssistantProvider>
            <CardTransitionProvider>
              <Router>
                <Switch>
                  <Route exact path={routes.landing} component={LandingScreen} />
                  <Route path={routes.baseApp} component={AppRootScreen} />
                </Switch>
              </Router>
            </CardTransitionProvider>
          </ProfileAssistantProvider>
        </BookAssistantProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
