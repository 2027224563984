import React from 'react'
import c from 'classnames'
import styles from './Text.module.css'
import { VARIANTS } from '../../../types/Components.types'

interface IProps {
  className?: string
  children: React.ReactNode
  element?: 'p' | 'span' | 'h2'
  size?: 'xs' | 's' | 'sm' | 'm' | 'ml' | 'l' | 'xl' | 'xxl'
  variant?: VARIANTS
  weight?: number | string
  align?: 'left' | 'center' | 'right'
  transform?: null | 'capitalize' | 'uppercase' | 'lowercase' | 'inherit'
  truncate?: boolean
  textStyle?: string
}

const Text = ({
  className,
  children,
  element = 'p',
  size = 'm',
  variant = VARIANTS.max,
  weight = 400,
  align = 'left',
  transform,
  truncate,
  textStyle,
  ...rest
}: IProps) => {
  return React.createElement(
    element,
    {
      className: c(
        styles.font,
        className,
        size && styles[`size-${size}`],
        variant && styles[variant],
        align && styles[align],
        styles[`weight-${weight}`],
        transform && styles[`trans-${transform}`],
        truncate && styles.truncate,
        textStyle && styles[textStyle],
      ),
      ...rest,
    },
    children,
  )
}

export default Text
