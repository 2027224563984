import React, { useCallback } from 'react'
import styles from './EmptyState.module.css'
import { ReactComponent as EmptyIllustration } from '../../assets/empty.svg'
import c from 'classnames'
import { useHistory } from 'react-router-dom'
import Text from '../../../../../../components/atoms/text/Text'
import Button from '../../../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../../../types/Components.types'

interface IProps {
  className?: string
  path: string
}

const EmptyState = ({ className, path }: IProps) => {
  const history = useHistory()

  const goToNewBook = useCallback(() => {
    history.push(path)
  }, [history, path])

  return (
    <div className={c(styles.container, className)}>
      <EmptyIllustration />

      <Text weight="500" align="center" className={styles.title}>
        No tienes reservas aún
      </Text>

      <Button inverted variant={VARIANTS.max} onClick={goToNewBook} className={styles.action}>
        Reservar ahora
      </Button>
    </div>
  )
}

export default React.memo(EmptyState)
