import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import InvoiceRow from '../../../../../../../../components/molecules/invoiceRow/InvoiceRow'
import LoadingData from '../../../../../../../../components/molecules/loadingData/LoadingData'
import { useProfile } from '../../../../../../../../modules/auth/hooks'
import { useDownloadVoucherInvoiceMutation } from '../../../../../../../../modules/vouchers/hooks'
import { IPayment } from '../../../../../../../../types/app.types'

interface IParams {
  voucherId: string
}

interface IProps {
  payment: IPayment
}

const Invoice = ({ payment }: IProps) => {
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { voucherId } = useParams<IParams>()
  const downloadInvoice = useDownloadVoucherInvoiceMutation()
  const handleDownloadInvoice = useCallback(() => {
    downloadInvoice.mutate({ userId: profile!.id, voucherId, paymentId: payment.id })
  }, [downloadInvoice, profile, voucherId, payment.id])

  if (isLoadingProfile) return <LoadingData />

  return (
    <InvoiceRow
      headline={`${payment.invoiceSerie}-${payment.invoiceNumber}`}
      paidAt={payment.paidAt}
      onClick={handleDownloadInvoice}
    />
  )
}

export default React.memo(Invoice)
