import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import Logo from '../../../components/atoms/logo/Logo'
import Screen from '../../../components/molecules/screen/Screen'
import routes from '../../../config/routes'
import { useProfile } from '../../../modules/auth/hooks'
import { isProfileCompleted } from '../../../modules/auth/utils'
import { Geolocation as GeolocationService } from '../../../modules/geolocation/service'
import { Notifications as NotificationsService } from '../../../modules/notifications/service'
import { ILocationState } from '../../../types/app.types'
import styles from './SplashScreen.module.css'

const SplashScreen = () => {
  const location = useLocation()
  const { data: user, isLoading, isError } = useProfile()
  const [geolocationState, setGeolocationState] = React.useState('pending')
  const isLogged = !!user && !isLoading && !isError
  const profileCompleted = isProfileCompleted({ user })

  React.useEffect(() => {
    GeolocationService.getGeolocationState()
      .then((state) => {
        setGeolocationState(state)
      })
      .catch((error) => {
        console.info(error)
        setGeolocationState('denied')
      })
  }, [])

  if ((!user && isLoading && !isError) || geolocationState === 'pending') {
    return (
      <Screen hasDecorator decoratorSize="large">
        <div className={styles.container}>
          <Logo className={styles.logo} size="m" />
        </div>
      </Screen>
    )
  }

  if (!isLoading && !user) {
    return (
      <Redirect
        to={{
          pathname: routes.welcome,
          search: location.search,
          state: { from: location },
        }}
      />
    )
  }

  if (isLogged && !profileCompleted) {
    return (
      <Redirect
        to={{
          pathname: routes.onboarding.home,
          search: location.search,
          state: { from: location },
        }}
      />
    )
  }

  if (isLogged && !user.email_verified) {
    return (
      <Redirect
        to={{
          pathname: routes.validation.emailPending,
          search: location.search,
          state: { from: location },
        }}
      />
    )
  }

  if (isLogged && !user.validated) {
    return (
      <Redirect
        to={{
          pathname: routes.validation.coworkingPending,
          search: location.search,
          state: { from: location },
        }}
      />
    )
  }

  if (isLogged && GeolocationService.getState() !== 'granted') {
    return (
      <Redirect
        to={{
          pathname: routes.geolocation,
          search: location.search,
          state: { from: location },
        }}
      />
    )
  }

  if (isLogged && NotificationsService.getState() === 'default') {
    return (
      <Redirect
        to={{
          pathname: routes.notifications,
          search: location.search,
          state: { from: location },
        }}
      />
    )
  }

  const from = (location?.state as ILocationState)?.from?.pathname
  return (
    <Redirect
      to={{
        pathname: from || routes.home,
        search: location.search,
      }}
    />
  )
}

export default React.memo(SplashScreen)
