import React, { useCallback } from 'react'
import styles from './OnBoardingHomeScreen.module.css'
import { useHistory } from 'react-router-dom'
import { ReactComponent as WelcomeIllustration } from './assets/illustration.svg'
import routes from '../../../../config/routes'
import Screen from '../../../../components/molecules/screen/Screen'
import Text from '../../../../components/atoms/text/Text'
import Button from '../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../types/Components.types'

const OnBoardingHomeScreen = () => {
  const history = useHistory()

  const goToStartOnBoarding = useCallback(() => {
    history.replace(routes.onboarding.profile)
  }, [history])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <WelcomeIllustration className={styles.illustration} />

          <Text size="l" variant={VARIANTS.primary} weight="700" align="center" className={styles.title}>
            Tu perfil
          </Text>

          <Text align="center">Para acceder al coworking tenemos que completar tu perfil. ¿Empezamos?</Text>
        </div>

        <Button variant={VARIANTS.primary} className={styles.action} onClick={goToStartOnBoarding}>
          ¡Vamos!
        </Button>
      </div>
    </Screen>
  )
}

export default React.memo(OnBoardingHomeScreen)
