import React, { useCallback, useRef, useState } from 'react'
import styles from './OnBoardingProfileScreen.module.css'
import { useHistory } from 'react-router-dom'
import PhoneStep from './steps/phone/PhoneStep'
import NameStep from './steps/name/NameStep'
import FacePhotoStep from './steps/facephoto/FacePhotoStep'
import ProfessionStep from './steps/profession/ProfessionStep'
import IdentityCardStep from './steps/identityCard/IdentityCardStep'
import IdentityCardNumberStep from './steps/identityCardNumber/IdentityCardNumberStep'
import c from 'classnames'
import PadronStep from './steps/padron/PadronStep'
import Screen from '../../../../components/molecules/screen/Screen'
import Carousel, { ICarouselRef, ISlide } from '../../../../components/molecules/carousel/Carousel'
import CarouselItem from '../../../../components/molecules/carousel/CarouselItem'
import Button from '../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../types/Components.types'
import routes from '../../../../config/routes'
import { IStepProps, IStepRef } from './steps/types'

const slides = [
  {
    id: 'name',
    Component: NameStep,
  },
  {
    id: 'phone',
    Component: PhoneStep,
  },
  {
    id: 'facephoto',
    Component: FacePhotoStep,
  },
  {
    id: 'profession',
    Component: ProfessionStep,
  },
  {
    id: 'padron',
    Component: PadronStep,
  },
  {
    id: 'identityCardNumber',
    Component: IdentityCardNumberStep,
  },
  {
    id: 'identityCard',
    Component: IdentityCardStep,
  },
] satisfies ISlide[]

const OnBoardingProfileScreen = () => {
  const history = useHistory()
  const carousel = useRef<ICarouselRef>(null)
  const currentSectionRef = useRef<IStepRef>(null)
  const windowSize = useRef(window.innerWidth >= 500 ? 342 : window.innerWidth).current
  const [currentSection, setCurrentSection] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleItemChange = useCallback(({ activeIndex }: { activeIndex: number }) => {
    setCurrentSection(activeIndex)
  }, [])

  const handleNextClick = useCallback(() => {
    if (isSubmitting) {
      return
    }
    currentSectionRef.current?.submit()
  }, [currentSectionRef, isSubmitting])

  const nextItem = useCallback(() => {
    carousel.current?.next()
  }, [carousel])

  const prevItem = useCallback(() => {
    carousel.current?.back()
  }, [carousel])

  const handleSectionSubmit = useCallback(() => {
    if (currentSection === slides.length - 1) {
      // Last slide
      history.replace(routes.onboarding.creation)
      return
    }
    nextItem()
  }, [currentSection, history, nextItem])

  const hasPrevAction = useCallback(() => {
    return currentSection > 0
  }, [currentSection])

  return (
    <Screen hasDecorator>
      <Carousel
        ref={carousel}
        className={styles.carousel}
        widthWindow={windowSize}
        widthItem={windowSize}
        active={0}
        slides={slides}
        indicators
        indicatorsClassName={styles.carouselIndicators}
        onTransitionEnd={handleItemChange}
        render={({ Component, displayed, ...props }) => {
          const componentProps: IStepProps = {
            onSubmit: handleSectionSubmit,
            onIsSubmittingChange: setIsSubmitting,
          }
          if (displayed) {
            componentProps.ref = currentSectionRef
          }
          return (
            <CarouselItem className={styles.carouselItem} {...props}>
              <Component {...componentProps} />
            </CarouselItem>
          )
        }}
      />

      <div className={c(styles.actions, hasPrevAction() && styles.multipleActions)}>
        {hasPrevAction() && (
          <Button variant={VARIANTS.max} className={styles.action} onClick={prevItem}>
            Atrás
          </Button>
        )}
        <Button variant={VARIANTS.primary} className={styles.action} onClick={handleNextClick} isLoading={isSubmitting}>
          Siguiente
        </Button>
      </div>
    </Screen>
  )
}

export default React.memo(OnBoardingProfileScreen)
