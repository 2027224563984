import React from 'react'
import styles from './UpcomingEvents.module.css'
import Text from '../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../types/Components.types'
import { useUpcomingReservatedSeats } from '../../../../../modules/seats/hooks'
import { useProfile } from '../../../../../modules/auth/hooks'
import { getHumanizedBookDaysLeft } from '../../../../../modules/seats/utils'
import LoadingData from '../../../../../components/molecules/loadingData/LoadingData'

const UpcomingEvents = () => {
  const { data: profile } = useProfile()
  const { data: upcomingReservatedSeats, isLoading: isLoadingReservations } = useUpcomingReservatedSeats({
    userId: profile?.id,
  })
  const upcomingReservatedSeat = upcomingReservatedSeats?.[0]

  if (isLoadingReservations) {
    return <LoadingData />
  }

  if (!upcomingReservatedSeat) {
    return (
      <div className={styles.container}>
        <Text variant={VARIANTS.white} align="center" size="s" textStyle="italic" className={styles.when}>
          ¡Vaya!
        </Text>
        <Text variant={VARIANTS.white} align="center" size="xl">
          Parece que no tienes reservas aún
        </Text>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <Text variant={VARIANTS.white} align="center" size="s" textStyle="italic" className={styles.when}>
        {getHumanizedBookDaysLeft({ reservation: upcomingReservatedSeat })}
      </Text>
      <Text variant={VARIANTS.white} align="center" size="xl">
        Puesto {upcomingReservatedSeat.name}
      </Text>
    </div>
  )
}

export default React.memo(UpcomingEvents)
