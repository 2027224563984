export const SET_TYPE = 'BA/SET_TYPE'
export const SET_NUMBER_OF_MONTHS = 'BA/SET_NUMBER_OF_MONTHS'
export const SET_DAY = 'BA/SET_DAY'
export const SET_SEAT = 'BA/SET_SEAT'
export const SET_SHIFT = 'BA/SET_SHIFT'
export const SET_BLUEPRINT_INITIALIZED = 'BA/SET_BLUEPRINT_INITIALIZED'
export const SET_HOURS = 'BA/SET_HOURS'
export const SET_DATA = 'BA/SET_DATA'
export const SET_COWORKING_DAYS = 'BA/SET_COWORKING_DAYS'
export const SET_ATTACHMENTS = 'BA/SET_ATTACHMENTS'
export const CLEAR = 'BA/CLEAR'
