import React, { useState } from 'react'
import { FaRegTrashCan } from 'react-icons/fa6'
import { toast } from 'react-toastify'
import BackButton from '../../../../../components/atoms/backButton/BackButton'
import CardIcon from '../../../../../components/atoms/cardIcon/CardIcon'
import Text from '../../../../../components/atoms/text/Text'
import TouchableOpacity from '../../../../../components/atoms/touchableOpacity/TouchableOpacity'
import EmptyState from '../../../../../components/molecules/emptyState/EmptyState'
import ListItem from '../../../../../components/molecules/listItem/ListItem'
import LoadingData from '../../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../../components/molecules/screen/Screen'
import { usePaymentCards, useProfile, useRemovePaymentCard } from '../../../../../modules/auth/hooks'
import { IPaymentCard } from '../../../../../modules/auth/types'
import { VARIANTS } from '../../../../../types/Components.types'
import { getCardType } from '../../../../../utils'
import styles from './SavedCardsScreen.module.css'
import ConfirmModal from '../../../../../components/molecules/confirmModal/ConfirmModal'

const SavedCardsScreen = () => {
  const [modalParams, setModalParams] = useState<{
    isOpen: boolean
    data: string | null
  }>({
    isOpen: false,
    data: null,
  })
  const { data: profile } = useProfile()
  const { data: cards, isLoading }: { data: Record<string, IPaymentCard>; isLoading: boolean } = usePaymentCards()
  const removePaymentCard = useRemovePaymentCard()

  const confirmRemoval = () => {
    removePaymentCard.mutate(
      { userId: profile?.id!, cardId: modalParams.data! },
      {
        onSuccess: () => {
          toast.success('Hemos eliminado tu tarjeta')
        },
        onError: () => {
          toast.success('Oops! No hemos podido eliminar tu tarjeta')
        },
      },
    )

    setModalParams({
      isOpen: false,
      data: null,
    })
  }

  const handleRemoveCard = (cardId: string) => {
    setModalParams({
      isOpen: true,
      data: cardId,
    })
  }

  if (isLoading) {
    return <LoadingData />
  }

  const hasSavedCards = Object.keys(cards ?? {}).length > 0
  return (
    <Screen hasDecorator decoratorSize="small">
      <section className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Métodos de pago
          </Text>
        </div>

        {hasSavedCards ? (
          <Text>
            Aquí encontrarás los métodos de pago que tienes guardados. Si tienes problemas con alguno de ellos, prueba a
            eliminarlo
          </Text>
        ) : null}

        {Object.keys(cards ?? {}).map((cardId) => {
          const card = cards[cardId]
          return (
            <ListItem
              key={cardId}
              label={card.Number ?? 'Desconocido'}
              icon={<CardIcon cardType={getCardType(card.Number ?? '')} />}
            >
              <TouchableOpacity onClick={() => handleRemoveCard(cardId)}>
                <FaRegTrashCan size={18} color={`rgba(var(--clrNegative))`} />
              </TouchableOpacity>
            </ListItem>
          )
        })}

        {!hasSavedCards ? <EmptyState headline="No tienes métodos de pago guardados" /> : null}
      </section>

      <ConfirmModal
        isOpen={modalParams.isOpen}
        onClose={() =>
          setModalParams({
            isOpen: false,
            data: null,
          })
        }
        onConfirm={confirmRemoval}
        title="¿Estás seguro?"
        message="Si eliminas la tarjeta tendrás que volver a introducirla para poder realizar pagos"
        confirmText="Eliminar"
        cancelText="Cancelar"
      />
    </Screen>
  )
}

export default React.memo(SavedCardsScreen)
