import FetchHelper from '../FetchHelper'

class InvoicesAPI {
  static getInvoices({ userId, queryParams }: { userId: string; queryParams: URLSearchParams }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/invoices?${queryParams.toString()}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static downloadInvoice({ userId, paymentId }: { userId: string; paymentId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/invoices/${paymentId}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }
}

export default InvoicesAPI
