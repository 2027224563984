import React from 'react'
import BackButton from '../../atoms/backButton/BackButton'
import Text from '../../atoms/text/Text'
import styles from './ScreenTitle.module.css'
import c from 'classnames'
import { VARIANTS } from '../../../types/Components.types'

interface IProps {
  className?: string
  backEnabled?: boolean
  title: string
}

const ScreenTitle = ({ className, backEnabled = true, title }: IProps) => {
  return (
    <div className={c(styles.container, className)}>
      {backEnabled && <BackButton className={styles.backButton} />}
      <Text size="l" variant={VARIANTS.primary} weight="700">
        {title}
      </Text>
    </div>
  )
}

export default React.memo(ScreenTitle)
