import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../../../components/atoms/button/Button'
import Counter from '../../../../../components/atoms/counter/Counter'
import Switch from '../../../../../components/atoms/switch/Switch'
import Text from '../../../../../components/atoms/text/Text'
import ListItem from '../../../../../components/molecules/listItem/ListItem'
import Screen from '../../../../../components/molecules/screen/Screen'
import ToggleButton from '../../../../../components/molecules/toggleButton/ToggleButton'
import routes from '../../../../../config/routes'
import { useHolidays, useSeatById, useSeatsQueryParams } from '../../../../../modules/seats/hooks'
import { useBookAssistant } from '../../../../../providers/bookAssistant'
import { IShift, IShifts, SHIFTS } from '../../../../../providers/bookAssistant/shifts'
import { TYPES } from '../../../../../providers/bookAssistant/types'
import { useCardTransition } from '../../../../../providers/cardTransition/cardTransition'
import { VARIANTS } from '../../../../../types/Components.types'
import { isSameDay, nextWorkingDay } from '../../../../../utils'
import styles from './BookSeatScreen.module.css'
import DatePicker from './components/datePicker/DatePicker'

const BookSeatScreen = () => {
  const history = useHistory()
  const { type, months, seat: seatId, day, shift, setType, setDay, setNumberOfMonths, setShift } = useBookAssistant()
  const queryParams = useSeatsQueryParams()
  const { data: seat } = useSeatById({ seatId, queryParams })
  const { data: holidays } = useHolidays()

  const { openCard } = useCardTransition()

  const handleContinueClick = useCallback(() => {
    history.push(routes.book.new.seat.confirmation)
  }, [history])

  const handleBookTypeDayClick = () => {
    const today = DateTime.local()
    let target = today.plus({ days: 1 })

    if (target.weekday === 6 || target.weekday === 7) {
      target = target.plus({ days: 8 - target.weekday })
    }

    if (holidays?.some((holiday) => isSameDay(DateTime.fromJSDate(holiday), target))) {
      target = target.plus({ days: 1 })
    }

    setType({ type: TYPES.DAY })
    setDay({ day: target.toJSDate() })
  }

  const handleBookTypeMonthClick = () => {
    const today = DateTime.local()
    // const endOfMonth = DateTime.local().endOf('month')
    let targetDay = today.plus({ months: 1 }).startOf('month')
    // Check if the target day is a holiday or weekend
    if (holidays?.some((holiday) => isSameDay(DateTime.fromJSDate(holiday), targetDay)) || targetDay.isWeekend) {
      targetDay = nextWorkingDay(targetDay) as DateTime<true>
    }

    // if (today.hasSame(endOfMonth, 'day') && today.hasSame(endOfMonth, 'month')) {
    //   targetDay = targetDay.plus({ months: 1 }).startOf('month')
    // }
    setType({ type: TYPES.MONTH })
    setDay({ day: targetDay.toJSDate() })
  }

  const handleMonthsChange = useCallback(
    (months: number) => {
      setNumberOfMonths?.({ months })
    },
    [setNumberOfMonths],
  )

  const handleBookUndefinedChange = useCallback(
    (checked: boolean) => {
      handleMonthsChange(checked ? -1 : 1)
    },
    [handleMonthsChange],
  )

  const handleChangeSeatClick = useCallback(() => {
    history.push(routes.book.new.seat.place)
  }, [history])

  const handleDateSelectorClick = useCallback(() => {
    openCard?.({ Component: <DatePicker disabledDays={[6, 7]} disabledDates={holidays} /> })
  }, [openCard, holidays])

  const isBookOfTypeMonth = type === TYPES.MONTH
  const isBookUndefined = isBookOfTypeMonth && months === -1

  return (
    <Screen
      headerProps={{
        backEnabled: true,
        variant: VARIANTS.flamingo,
        title: 'Nueva reserva',
      }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <ListItem label="Tipo de reserva">
            <div className={styles.reservationType}>
              <ToggleButton isActive={!isBookOfTypeMonth} onClick={handleBookTypeDayClick}>
                <Text size="sm" variant={!isBookOfTypeMonth ? VARIANTS.white : VARIANTS.secondary} weight="500">
                  DÍA
                </Text>
              </ToggleButton>
              <ToggleButton isActive={isBookOfTypeMonth} onClick={handleBookTypeMonthClick}>
                <Text size="sm" variant={isBookOfTypeMonth ? VARIANTS.white : VARIANTS.secondary} weight="500">
                  MES
                </Text>
              </ToggleButton>
            </div>
          </ListItem>

          {isBookOfTypeMonth && (
            <div className={styles.coworkingAccessWrapper}>
              <Text>¿Cuando quieres acceder al coworking?</Text>
              <div className={styles.shitsOptions}>
                {(Object.keys(SHIFTS) as Array<keyof IShifts>).map((key) => {
                  const { from, to, label, suitableTo } = SHIFTS[key] as IShift
                  const isActive = shift && shift.from === from && shift.to === to
                  const isSuitable = suitableTo.some((bookType) => bookType === type)
                  if (!isSuitable) {
                    return null
                  }

                  return (
                    <ToggleButton
                      key={key}
                      className={styles.shiftButton}
                      isActive={isActive}
                      onClick={() => setShift({ shift: SHIFTS[key] })}
                    >
                      <Text size="sm" variant={isActive ? VARIANTS.white : VARIANTS.secondary} weight="500">
                        {label}
                      </Text>
                    </ToggleButton>
                  )
                })}
              </div>
            </div>
          )}

          {isBookOfTypeMonth && (
            <>
              <ListItem label="De manera indefinida">
                <Switch name="undefined" checked={isBookUndefined} onChange={handleBookUndefinedChange} />
              </ListItem>

              {!isBookUndefined && (
                <ListItem label="¿Cuántos meses?">
                  <Counter min={1} max={12} onChange={handleMonthsChange} value={months} />
                </ListItem>
              )}

              <Text size="s" className={styles.helpText}>
                Se te aplicarán los descuentos automáticamente. 5% dto. el sexto mes y si renuevas durante 12 meses, el
                último es gratis.
              </Text>
            </>
          )}

          <ListItem label="Cuando quieres reservar">
            <Button
              className={styles.button}
              autoSize
              variant={VARIANTS.secondary}
              inverted
              onClick={handleDateSelectorClick}
            >
              {!isBookOfTypeMonth && (
                <Text size="sm" variant={VARIANTS.secondary} weight="500">
                  {day ? DateTime.fromJSDate(day).toLocaleString(DateTime.DATE_MED) : 'Seleccionar'}
                </Text>
              )}
              {isBookOfTypeMonth && (
                <Text className={styles.month} size="sm" variant={VARIANTS.secondary} weight="500">
                  {day ? DateTime.fromJSDate(day).toLocaleString({ month: 'long' }) : 'Seleccionar'}
                </Text>
              )}
            </Button>
          </ListItem>

          {isBookOfTypeMonth && (
            <Text size="s" className={styles.helpText}>
              {`Al reservar un mes, la reserva se realiza para el mes natural. Es decir, si reservas hoy, ${DateTime.local().toLocaleString(
                DateTime.DATE_FULL,
              )}, tendrá validez hasta ${DateTime.local().endOf('month').toLocaleString(DateTime.DATE_FULL)}`}
            </Text>
          )}

          <ListItem label="Tu sitio">
            <Button
              className={styles.button}
              autoSize
              variant={VARIANTS.secondary}
              inverted
              onClick={handleChangeSeatClick}
              disabled={!day || !shift}
            >
              <Text size="sm" variant={VARIANTS.secondary} weight="500">
                {seat ? seat.name : 'Elígelo'}
              </Text>
            </Button>
          </ListItem>
        </div>

        <div className={styles.actions}>
          <Button
            variant={VARIANTS.primary}
            className={styles.action}
            onClick={handleContinueClick}
            disabled={!day || !shift || !seat}
          >
            Continuar
          </Button>
        </div>
      </div>
    </Screen>
  )
}

export default BookSeatScreen
