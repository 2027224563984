import c from 'classnames'
import React from 'react'
import Text from '../../atoms/text/Text'
import styles from './EmptyState.module.css'
import { ReactComponent as EmptyIllustration } from './assets/emptyIllustration.svg'

interface IProps {
  className?: string
  headline: string
}

const EmptyState = ({ className, headline }: IProps) => {
  return (
    <div className={c(styles.wrapper, className)}>
      <EmptyIllustration />

      <Text weight="500" align="center">
        {headline}
      </Text>
    </div>
  )
}

export default React.memo(EmptyState)
