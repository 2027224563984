import React from 'react'
import styles from './SwitchRow.module.css'
import c from 'classnames'
import Text from '../../../../../components/atoms/text/Text'
import Switch from '../../../../../components/atoms/switch/Switch'

interface IProps {
  className?: string
  children: React.ReactNode
  name: string
  onChange: (isChecked: boolean) => void
  checked: boolean
}

const SwitchRow = ({ name, onChange, checked, className, children }: IProps) => {
  return (
    <div className={c(styles.container, className)}>
      <Text>{children}</Text>

      <Switch name={name} checked={checked} onChange={onChange} />
    </div>
  )
}

export default React.memo(SwitchRow)
