import React, { useState, useCallback, useEffect } from 'react'
import styles from './UpdateDNIScreen.module.css'
import { useFormik } from 'formik'
import identityCardFormSchema from './schema/identityCard'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import Screen from '../../../../../components/molecules/screen/Screen'
import BackButton from '../../../../../components/atoms/backButton/BackButton'
import Text from '../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../types/Components.types'
import IdentityCard from '../../../../../components/molecules/identityCard/IdentityCard'
import Button from '../../../../../components/atoms/button/Button'
import { useProfile, useUpdateDNIMutation } from '../../../../../modules/auth/hooks'
import LoadingData from '../../../../../components/molecules/loadingData/LoadingData'

const UpdateDNIScreen = () => {
  const [front, setFront] = useState<string | undefined>()
  const [back, setBack] = useState<string | undefined>()
  const history = useHistory()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const updateDNIMutation = useUpdateDNIMutation()

  const { handleSubmit, values, setFieldValue, touched, errors, isSubmitting } = useFormik({
    initialValues: {
      identityCard: {
        front: null,
        back: null,
      },
    },
    validationSchema: identityCardFormSchema,
    onSubmit: (values) => {
      return new Promise<void>((resolve, reject) => {
        updateDNIMutation.mutate(
          {
            userId: profile!.id,
            identityCard: {
              front: values.identityCard.front!,
              back: values.identityCard.back!,
            },
          },
          {
            onSuccess: () => {
              resolve()
              toast.success('¡Hemos actualizado tu documento de identidad!')
              history.goBack()
            },
            onError: (error) => {
              reject(error)
              toast.error('Oops! No hemos podido actualizar tu documento de identidad en este momento')
            },
          },
        )
      })
    },
  })

  const onFrontChange = useCallback(
    ({ files }: { files: FileList }) => {
      setFieldValue('identityCard.front', files[0])
    },
    [setFieldValue],
  )

  const onBackChange = useCallback(
    ({ files }: { files: FileList }) => {
      setFieldValue('identityCard.back', files[0])
    },
    [setFieldValue],
  )

  useEffect(() => {
    const { identityCard } = values
    if (identityCard.front) {
      const _URL = window.URL || window.webkitURL
      const blob = _URL.createObjectURL(identityCard.front)

      const img = new Image()
      img.src = blob

      img.onload = function () {
        setFront(blob)
      }
    }

    if (identityCard.back) {
      const _URL = window.URL || window.webkitURL
      const blob = _URL.createObjectURL(identityCard.back)

      const img = new Image()
      img.src = blob

      img.onload = function () {
        setBack(blob)
      }
    }
  }, [values])

  if (isLoadingProfile) {
    return <LoadingData />
  }

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Documento de identidad
          </Text>
        </div>

        <form className={styles.content} onSubmit={handleSubmit}>
          <Text className={styles.description}>¿Necesitas modificar tu documento de identidad?</Text>

          <div className={styles.section}>
            <Text size="ml" variant={VARIANTS.primary} weight="600" align="center" className={styles.sectionTitle}>
              Parte frontal
            </Text>

            <IdentityCard onChange={onFrontChange} resource={front} />

            {touched.identityCard && errors.identityCard?.front && (
              <Text variant={VARIANTS.negative} align="center" className={styles.error}>
                {errors.identityCard?.front}
              </Text>
            )}
          </div>

          <div className={styles.section}>
            <Text size="ml" variant={VARIANTS.primary} weight="600" align="center" className={styles.sectionTitle}>
              Parte trasera
            </Text>

            <IdentityCard onChange={onBackChange} resource={back} />

            {touched.identityCard && errors.identityCard?.back && (
              <Text variant={VARIANTS.negative} align="center" className={styles.error}>
                {errors.identityCard?.back}
              </Text>
            )}
          </div>

          <Button
            variant={VARIANTS.primary}
            className={styles.action}
            type="button"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Modificar
          </Button>
        </form>
      </div>
    </Screen>
  )
}

export default React.memo(UpdateDNIScreen)
