import { useMutation, useQuery } from '@tanstack/react-query'
import { downloadInvoice, getInvoices } from '.'
import { toast } from 'react-toastify'

export const useInvoices = (
  { userId, queryParams }: { userId?: string; queryParams: URLSearchParams },
  options = {},
) => {
  return useQuery({
    queryKey: ['invoices', userId, queryParams.toString()],
    queryFn: () => getInvoices({ userId: userId!, queryParams }),
    ...options,
  })
}

export const useDownloadInvoiceMutation = () => {
  return useMutation({
    mutationFn: downloadInvoice,
    onSuccess: () => {},
    onError: () => {
      toast.error('Oops! No hemos podido descargar tu factura. Inténtalo de nuevo más tarde')
    },
  })
}
