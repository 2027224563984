import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import routes from '../../../config/routes'
import { useIsLogged } from '../../../modules/auth/hooks'

interface IProps extends RouteProps {
  component: React.ComponentType<any>
  componentProps?: any
}

const PrivateRoute = ({ component: Component, componentProps = {}, ...rest }: IProps) => {
  const { isCheckingLogin, isLogged } = useIsLogged()

  if (isCheckingLogin) {
    return null
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const computedProps = { ...props, ...componentProps }
        return isLogged ? (
          <Component {...computedProps} />
        ) : (
          <Redirect
            to={{
              pathname: routes.app,
              search: props.location.search,
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

export default React.memo(PrivateRoute)
