import React, { useReducer, useCallback, useContext, Reducer } from 'react'
import CardTransition from './components/CardTransition'

interface ICardTransitionContext {
  openCard: ({ Component }: { Component: React.ReactNode }) => void
  closeCard: () => void
}

export const CardTransitionContext = React.createContext<ICardTransitionContext | null>(null)

const { Provider } = CardTransitionContext

enum Actions {
  OPEN_CARD = 'CT/OPEN_CARD',
  CLOSE_CARD = 'CT/CLOSE_CARD',
}

interface IState {
  shown: boolean
  Component?: React.ReactNode
}

interface IAction {
  type: Actions
  payload?: {
    Component?: React.ReactNode
  }
}

const reducer = (prevState: IState, { type, payload }: IAction) => {
  switch (type) {
    case Actions.OPEN_CARD:
      return {
        ...prevState,
        Component: payload?.Component,
        shown: true,
      }

    case Actions.CLOSE_CARD:
      return {
        ...prevState,
        Component: null,
        shown: false,
      }
    default:
      return prevState
  }
}

interface IProps {
  children: React.ReactNode
}

export const CardTransitionProvider = ({ children }: IProps) => {
  const [{ shown, Component }, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, { shown: false })

  const openCard = useCallback(({ Component }: { Component: React.ReactNode }) => {
    dispatch({ type: Actions.OPEN_CARD, payload: { Component } })
  }, [])

  const closeCard = useCallback(() => {
    dispatch({ type: Actions.CLOSE_CARD })
  }, [])

  return (
    <Provider
      value={{
        openCard,
        closeCard,
      }}
    >
      {children}
      <CardTransition shown={shown}>{Component}</CardTransition>
    </Provider>
  )
}

export const useCardTransition = () => {
  const ctx = useContext(CardTransitionContext)
  if (!ctx) {
    throw Error('The `useCardTransition` hook must be called from a descendent of the `CardTransitionProvider`.')
  }

  return ctx
}
