import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'
import { toast } from 'react-toastify'
import {
  getAvailableHours,
  getAvailableSlots,
  getMeetingRoomHours,
  getMeetingRoomReservations,
  openCoworking,
  reservateMeetingRoom,
  updateMeetingRoomReservation,
} from '.'
import { CoworkingQueryClient } from '../CoworkingQueryClient'

export const useMeetingRoomReservations = ({
  userId,
  options,
}: {
  userId?: string
  options?: { enabled: boolean }
}) => {
  return useQuery({
    queryKey: ['meetingRoomReservations', userId],
    queryFn: () => getMeetingRoomReservations({ userId: userId! }),
    enabled: !!userId && (options?.enabled ?? true),
  })
}

export const useMeetingRoomReservationById = ({
  userId,
  reservationId,
}: {
  userId?: string
  reservationId?: string | null
}) => {
  const { data: reservations, ...rest } = useMeetingRoomReservations({
    userId,
    options: { enabled: !!userId && !!reservationId },
  })

  const reservation = React.useMemo(() => {
    if (!reservations) {
      return undefined
    }
    return reservations.find((reservation) => reservation.id === reservationId)
  }, [reservations, reservationId])

  return { data: reservation, ...rest }
}

export const useMeetingRoomHours = ({ userId, options }: { userId?: string; options?: { enabled: boolean } }) => {
  return useQuery({
    queryKey: ['meetingRoomReservations', 'hours', userId],
    queryFn: () => getMeetingRoomHours({ userId: userId! }),
    enabled: !!userId && (options?.enabled ?? true),
  })
}

export const useAvailableHours = ({ userId, options }: { userId?: string; options?: { enabled: boolean } }) => {
  return useQuery({
    queryKey: ['meetingRoomReservations', 'availableHours', userId],
    queryFn: () => getAvailableHours({ userId: userId! }),
    enabled: !!userId && (options?.enabled ?? true),
  })
}

export const useAvailableSlots = ({ date, options }: { date?: string; options?: { enabled: boolean } }) => {
  return useQuery({
    queryKey: ['meetingRoomReservations', 'availableSlots', date],
    queryFn: () => getAvailableSlots({ date: date! }),
    enabled: !!date && (options?.enabled ?? true),
  })
}

export const useReservateMeetingRoomMutation = () => {
  return useMutation({
    mutationFn: reservateMeetingRoom,
    onSuccess: (_, { userId }) => {
      CoworkingQueryClient.invalidateQueries(['meetingRoomReservations', 'availableHours', userId])
      CoworkingQueryClient.invalidateQueries(['meetingRoomReservations', userId])
    },
    onError: (error) => {
      toast.error('Oops! No hemos podido confirmar tu reserva')
    },
  })
}

export const useUpdateMeetingRoomReservationMutation = () => {
  return useMutation({
    mutationFn: updateMeetingRoomReservation,
    onSuccess: (_, { userId }) => {
      toast.success('Tu reserva ha sido actualizada correctamente')
      CoworkingQueryClient.invalidateQueries(['meetingRoomReservations', 'availableHours', userId])
      CoworkingQueryClient.invalidateQueries(['meetingRoomReservations', userId])
    },
    onError: (error) => {
      toast.error('Oops! No hemos podido cambiar tu reserva')
    },
  })
}

export const useOpenCoworkingMeetingRoomMutation = () => {
  return useMutation({
    mutationFn: openCoworking,
    onError: () => {
      toast.error('Oops! No hemos podido abrir la puerta')
    },
  })
}
