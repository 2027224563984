import React, { useCallback } from 'react'
import styles from './KO.module.css'
import { useHistory } from 'react-router-dom'
import { ReactComponent as PaymentCompleteIllustration } from './assets/payment_complete_illustration.svg'
import routes from '../../../../config/routes'
import Screen from '../../../../components/molecules/screen/Screen'
import Text from '../../../../components/atoms/text/Text'
import Button from '../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../types/Components.types'

const KO = () => {
  const history = useHistory()

  const handleContinueClick = useCallback(() => {
    history.replace(routes.home)
  }, [history])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <PaymentCompleteIllustration />
          <Text size="l" align="center" variant={VARIANTS.primary} weight="700" className={styles.title}>
            ¡Oops!
          </Text>
          <Text className={styles.description} align="center">
            Ha ocurrido un error al intentar realizar el pago de tu bono.
          </Text>
        </div>

        <Button variant={VARIANTS.primary} className={styles.action} onClick={handleContinueClick}>
          Sácame de aquí
        </Button>
      </div>
    </Screen>
  )
}

export default React.memo(KO)
