import React from 'react'
import styles from './PromotionalSlider.module.css'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import * as slides from './assets'
import Text from '../../../../components/atoms/text/Text'

const PromotionalSlider = () => {
  return (
    <>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        infiniteLoop
        autoPlay
        className={styles.container}
        emulateTouch
      >
        {Object.values(slides).map((slide) => {
          return <img key={slide} src={slide} alt="" />
        })}
      </Carousel>
      <Text className={styles.caption} size="sm" align="center">
        Fotos: Paula Arroyo Prieto @Ojovivofoto
      </Text>
    </>
  )
}

export default React.memo(PromotionalSlider)
