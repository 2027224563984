export interface ITypes {
  DAY: 'days'
  MONTH: 'period'
  MEETING_ROOM: 'meetingroom'
  COWORKING_SATURDAY: 'coworkingsaturday'
  COWORKING_WEEKEND: 'coworkingweekend'
  COWORKING_HOLIDAY: 'coworkingholiday'
  COWORKING_3_DAYS: 'coworking3days'
}

export const TYPES: ITypes = {
  DAY: 'days',
  MONTH: 'period',
  MEETING_ROOM: 'meetingroom',
  COWORKING_SATURDAY: 'coworkingsaturday',
  COWORKING_WEEKEND: 'coworkingweekend',
  COWORKING_HOLIDAY: 'coworkingholiday',
  COWORKING_3_DAYS: 'coworking3days',
}
