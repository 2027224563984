import React from 'react'
import Card from '../../atoms/card/Card'
import Text from '../../atoms/text/Text'
import styles from './BookCard.module.css'
import AspectRatio from '../../atoms/aspectRatio/AspectRatio'
import c from 'classnames'
import { DateTime } from 'luxon'
import { humanizeShift } from '../../../utils'
import { VARIANTS } from '../../../types/Components.types'
import { IShift } from '../../../providers/bookAssistant/shifts'
import { TYPES } from '../../../providers/bookAssistant/types'
import { useProfile } from '../../../modules/auth/hooks'

interface IProps {
  className?: string
  type: string
  seat: string
  day: Date
  months: number
  shift: Partial<IShift>
}

const BookCard = ({ className, type, seat, day, months, shift }: IProps) => {
  const isBookOfTypeMonth = type === TYPES.MONTH
  const { data: profile } = useProfile()

  return (
    <Card className={c(styles.container, className)}>
      <AspectRatio>
        <div className={styles.content}>
          <Text size="sm" weight="700" transform="uppercase" variant={VARIANTS.white} className={styles.label}>
            Nombre
          </Text>
          <Text variant={VARIANTS.white}>{`${profile?.firstname} ${profile?.lastname}`}</Text>

          <div className={styles.extraInfo}>
            <div className={styles.block}>
              <Text size="sm" weight="700" transform="uppercase" variant={VARIANTS.white} className={styles.label}>
                Puesto
              </Text>
              <Text variant={VARIANTS.white}>{seat}</Text>
            </div>

            <div className={styles.block}>
              <Text size="sm" weight="700" transform="uppercase" variant={VARIANTS.white} className={styles.label}>
                Turno
              </Text>
              <Text variant={VARIANTS.white}>{humanizeShift(shift)}</Text>
            </div>

            <div className={styles.block}>
              <Text
                size="sm"
                weight="700"
                transform="uppercase"
                variant={VARIANTS.white}
                align="right"
                className={styles.label}
              >
                {isBookOfTypeMonth ? 'Mensual' : 'Fecha'}{' '}
              </Text>

              {!isBookOfTypeMonth && (
                <Text variant={VARIANTS.white} align="right">
                  {day && DateTime.fromJSDate(day).toLocaleString(DateTime.DATE_SHORT)}
                </Text>
              )}

              {isBookOfTypeMonth && (
                <Text variant={VARIANTS.white} align="right">
                  {months === -1 ? 'Indefinido' : `${months} meses`}
                </Text>
              )}
            </div>
          </div>
        </div>
      </AspectRatio>
    </Card>
  )
}

export default React.memo(BookCard)
