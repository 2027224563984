import React from 'react'
import Text from '../../atoms/text/Text'
import styles from './CookiesText.module.css'

const CookiesText = () => {
  return (
    <>
      <Text weight="600" className={styles.header}>
        ¿Qué hacemos con tus datos?
      </Text>

      <Text className={styles.block}>
        Los datos facilitados mediante el formulario de alta, por teléfono o correo electrónico, ya sea cuando te
        inscribes a una acción formativa o pides cita, se incorporan al tratamiento “Usuarios del coworking, cuyo{' '}
        <Text weight="500" element="span">
          Responsable
        </Text>{' '}
        es el Ayuntamiento de San Lorenzo de El Escorial (NIF: P2813100A) y el órgano de gestión es el Centro de
        Difusión de la Innovación. Tiene como única{' '}
        <Text weight="500" element="span">
          finalidad
        </Text>{' '}
        conocer las peculiaridades del interesad@ para poder adecuar al máximo los servicios ofrecidos y justificar
        nuestro trabajo. Los datos aportados y los informes generados son confidenciales. La{' '}
        <Text weight="500" element="span">
          legitimación
        </Text>{' '}
        del tratamiento es poder prestarte el servicio solicitado.
      </Text>

      <Text className={styles.block}>
        Tus datos{' '}
        <Text weight="500" element="span">
          no se cederán
        </Text>{' '}
        a ninguna empresa u organismo. Los datos se conservan de forma indefinida mientras tengas interés en los
        servicios del centro. Para que dejemos de tenerlos nos lo debes comunicar. No recogemos nunca datos sensibles.
      </Text>

      <Text className={styles.block}>
        Puedes{' '}
        <Text weight="500" element="span">
          ejercer los derechos
        </Text>{' '}
        de acceso, rectificación, oposición y cancelación, dirigiendo un escrito a Centro de Difusión de la Innovación,
        calle Calvario 38, 28200 San Lorenzo de El Escorial, Madrid, personándote en el horario de atención o por correo
        electrónico: cdi@aytosanlorenzo.es.
      </Text>

      <Text weight="600" className={styles.header}>
        Otros datos facilitados
      </Text>

      <Text className={styles.block}>
        Los datos aportados por{' '}
        <Text weight="500" element="span">
          proveedores
        </Text>{' '}
        se incorporan al tratamiento «Proveedores». No recogemos datos de candidat@s, que son derivados a Recursos
        Humanos del Ayuntamiento.
      </Text>

      <Text weight="600" className={styles.header}>
        Acceso a datos personales
      </Text>

      <Text className={styles.block}>
        Durante la navegación, se utilizarán las cookies con el fin de mantener la sesión del usuario activa, pero bajo
        ninguna circustancia se almacenarán datos privados del usaurio en las mencionadas cookies.
      </Text>

      <Text className={styles.block}>
        El almacenamiento localStorage permite a esta Aplicación almacenar y acceder a datos directamente en el
        navegador del Usuario sin fecha de expiración. En ningún caso se utlizarán cookies con fines comerciales o
        analíticos.
      </Text>

      <Text className={styles.block}>
        El acceso a la localización se realiza de{' '}
        <Text weight="500" element="span">
          manera puntual
        </Text>{' '}
        para poder acceder al coworking, en ningún caso se consume la localización del usuario de manera continua.
      </Text>

      <Text className={styles.block}>
        A modo de recordatorio esta applicación web enviará push notifications al usuario para avisarle de eventos
        puntuales como{' '}
        <Text weight="500" element="span">
          fecha de cierre del coworking, aviso de reserva, informar de errores...
        </Text>
      </Text>
    </>
  )
}

export default React.memo(CookiesText)
