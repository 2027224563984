import { SET_HOURS, SET_RESERVATIONS } from './actions'
import { CLEAN_ACTION } from '../../store/store'

const initialState = {
  hours: {},
  reservations: {},
}

const MeetingRoomReducer = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case SET_HOURS:
      return {
        ...prevState,
        hours: { ...payload.hours },
      }

    case SET_RESERVATIONS:
      return {
        ...prevState,
        reservations: { ...payload.reservations },
      }

    case CLEAN_ACTION:
      return { ...initialState }

    default:
      return prevState
  }
}

export default MeetingRoomReducer
