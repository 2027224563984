import c from 'classnames'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../../../../components/atoms/button/Button'
import Card from '../../../../../../components/atoms/card/Card'
import Text from '../../../../../../components/atoms/text/Text'
import routes from '../../../../../../config/routes'
import { VARIANTS } from '../../../../../../types/Components.types'
import styles from './SeeAll.module.css'
import { ReactComponent as Illustration } from './assets/illustration.svg'

interface IProps {
  className?: string
}

const SeeAll = ({ className }: IProps) => {
  const history = useHistory()

  const goToBooks = () => {
    history.push(routes.book.list)
  }

  return (
    <Card className={c(styles.container, className)}>
      <div className={styles.wrapper}>
        <Text weight="500">Todas mis reservas</Text>

        <Button variant={VARIANTS.primary} onClick={goToBooks} className={styles.action}>
          Ver ahora
        </Button>
      </div>

      <Illustration />
    </Card>
  )
}

export default React.memo(SeeAll)
