import React, { useCallback, useMemo } from 'react'
import styles from './Voucher.module.css'
import { DateTime } from 'luxon'
import { IUserPurchaseVoucher } from '../../../../../../../../types/app.types'
import { getBookMonths, getBookRenewedMonths, isRenewable } from '../../../../../../../../modules/vouchers/utils'
import Card from '../../../../../../../../components/atoms/card/Card'
import Text from '../../../../../../../../components/atoms/text/Text'
import Tag from '../../../../../../../../components/atoms/tag/Tag'
import { VARIANTS } from '../../../../../../../../types/Components.types'

interface IProps {
  voucher: IUserPurchaseVoucher
  onClick?: (voucher: any) => void
}

const Voucher = ({ voucher, onClick }: IProps) => {
  const isRenewAvailable = isRenewable({ voucher })

  const handleClick = useCallback(() => {
    onClick?.(voucher)
  }, [voucher, onClick])

  const isPeriodVoucher = voucher?.spec?.seat?.type === 'period'

  const isConsumed = useMemo(() => {
    if (!isPeriodVoucher) {
      return voucher.isConsumed
    }

    const monthsToRenew = getBookMonths({ voucher })
    const monthsRenewed = getBookRenewedMonths({ voucher })

    return monthsToRenew > 0 && monthsRenewed >= monthsToRenew
  }, [voucher, isPeriodVoucher])

  return (
    <Card className={styles.container} onClick={handleClick}>
      <Text weight="500">{voucher?.invoiceData?.subject ?? voucher?.voucher?.name}</Text>

      <Text className={styles.purchaseAt} size="sm">
        Adquirido el {DateTime.fromISO(voucher.createdAt).toLocaleString(DateTime.DATE_SHORT)}
      </Text>

      {!voucher.isPaid && (
        <Tag className={styles.tag} variant="negative" textVariant={VARIANTS.white}>
          Pago pendiente
        </Tag>
      )}

      {isPeriodVoucher && (
        <Tag className={styles.tag} textVariant={VARIANTS.white}>
          Mensual
        </Tag>
      )}

      {isRenewAvailable && (
        <Tag className={styles.tag} variant="secondary" textVariant={VARIANTS.white}>
          Renovable
        </Tag>
      )}

      {isConsumed && <Tag className={styles.tag}>Consumido</Tag>}
    </Card>
  )
}

export default React.memo(Voucher)
