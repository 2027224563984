import FetchHelper from '../FetchHelper'

interface IReservateHours {
  date: string
  from: number
  to: number
}

class MeetingRoomAPI {
  static getReservations({ userId }: { userId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/meetingroom`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getAvailableHours({ userId }: { userId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/meetingroom/hours`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getAvailableSlots({ date }: { date: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`meetingroom?date=${date}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static reserve({ userId, hours }: { userId: string; hours: IReservateHours[] }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/meetingroom`, {
      method: 'POST',
      headers: generateHeaders({ headers: {} }),
      body: hours,
    })
  }

  static updateReservation({
    userId,
    reservationId,
    date,
    from,
    to,
  }: {
    userId: string
    reservationId: string
    date: string
    from: number
    to: number
  }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/meetingroom/${reservationId}`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: {
        date,
        from,
        to,
      },
    })
  }

  static openCoworking({
    userId,
    meetingRoomId,
    coordinates,
    dummyCoordinates,
  }: {
    userId: string
    meetingRoomId: string
    coordinates: GeolocationPosition
    dummyCoordinates: boolean
  }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/meetingroom/${meetingRoomId}/access/enter`, {
      method: 'POST',
      headers: generateHeaders({ headers: {} }),
      body: {
        coords: {
          latitude: coordinates.coords.latitude,
          longitude: coordinates.coords.longitude,
          dummy: dummyCoordinates,
        },
        timestamp: coordinates.timestamp,
      },
    })
  }
}

export default MeetingRoomAPI
