import React, { useEffect } from 'react'
import styles from './WorkmateProfileScreen.module.css'
import Row from './components/row/Row'
import { useHistory, useLocation } from 'react-router-dom'
import Screen from '../../../../components/molecules/screen/Screen'
import { VARIANTS } from '../../../../types/Components.types'
import Avatar from '../../../../components/molecules/avatar/Avatar'
import Text from '../../../../components/atoms/text/Text'
import { IWorkmate } from '../../../../types/app.types'

interface IState {
  workmate: IWorkmate['user']
}

const WorkmateProfileScreen = () => {
  const history = useHistory()
  const { state } = useLocation<IState>()
  const workmate = state?.workmate

  useEffect(() => {
    if (!workmate) {
      history.goBack()
    }
  }, [workmate, history])

  if (!workmate) {
    return null
  }

  return (
    <Screen
      headerProps={{
        variant: VARIANTS.parrot,
        title: 'Perfil',
        backEnabled: true,
      }}
    >
      <div className={styles.container}>
        <div className={styles.mainInfo}>
          <Avatar resource={workmate?.facephoto} />
          <Text
            weight="700"
            align="center"
            size="l"
            className={styles.name}
          >{`${workmate?.firstname} ${workmate?.lastname}`}</Text>
        </div>
        <div className={styles.infoWrapper}>
          {workmate?.dni && <Row label="DNI" info={workmate?.dni} />}

          {workmate?.phone && <Row label="Teléfono de contacto" info={workmate?.phone} />}

          {workmate?.address?.street && <Row label="Dirección" info={workmate?.address?.street} />}

          {workmate?.address?.postalCode && <Row label="Código Postal" info={workmate?.address?.postalCode} />}

          {workmate?.address?.state && <Row label="Provincia" info={workmate?.address?.state} />}

          {workmate?.address?.locality && <Row label="Localidad" info={workmate?.address?.locality} />}

          {workmate?.address?.country && <Row label="País" info={workmate?.address?.country} />}

          {workmate?.profession && <Row label="Profesión" info={workmate?.profession} />}

          {workmate?.linkedIn && <Row label="Linked-in" info={workmate?.linkedIn} />}

          {workmate?.facebook && <Row label="Facebook" info={workmate?.facebook} />}

          {workmate?.twitter && <Row label="Twitter" info={workmate?.twitter} />}
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(WorkmateProfileScreen)
