export const SET_HOURS = 'MEETING_ROOM/SET_HOURS'
export const SET_RESERVATIONS = 'MEETING_ROOM/SET_RESERVATIONS'

export const setHours = ({ hours }) => {
  return {
    type: SET_HOURS,
    payload: { hours },
  }
}

export const setReservations = ({ reservations }) => {
  return {
    type: SET_RESERVATIONS,
    payload: { reservations },
  }
}
