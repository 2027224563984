import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import BackButton from '../../../../../components/atoms/backButton/BackButton'
import Text from '../../../../../components/atoms/text/Text'
import LinkRow from '../../../../../components/molecules/linkRow/LinkRow'
import LoadingData from '../../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../../components/molecules/screen/Screen'
import routes from '../../../../../config/routes'
import { useProfile } from '../../../../../modules/auth/hooks'
import { getGUCSLAvailableHours } from '../../../../../modules/auth/utils'
import { useMeetingRoomHours } from '../../../../../modules/meetingRoom/hooks'
import { VARIANTS } from '../../../../../types/Components.types'
import styles from './GUCSLBenefitsScreen.module.css'

const GUCSLBenefitsScreen = () => {
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: availableHours, isLoading: isLoadingAvailableHours } = useMeetingRoomHours({ userId: profile?.id })
  const freeMeetingRoomHours = getGUCSLAvailableHours({ hours: availableHours ?? [] })

  const isLoading = isLoadingProfile || isLoadingAvailableHours

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Mis ventajas del GUCSL
          </Text>
        </div>

        <div className={styles.content}>
          <Text>
            Recuerda que por pertenecer al Grupo de Usuarios del Coworking Municipal de San Lorenzo de El Escorial te
            corresponden una serie de ventajas:
          </Text>

          <div className={styles.chartWrapper}>
            <CircularProgressbar
              className={styles.chart}
              value={freeMeetingRoomHours?.availableHours ?? 0}
              maxValue={freeMeetingRoomHours?.hours ?? 0}
              text={`${freeMeetingRoomHours?.availableHours ?? 0}h`}
              styles={{
                path: {
                  stroke: 'rgba(26, 145, 215)',
                },
                text: {
                  fill: 'rgba(26, 145, 215)',
                },
              }}
            />
            <Text align="center">Acceso a la sala de reuniones este mes de manera gratuita</Text>
          </div>
          <div className={styles.actions}>
            <Text weight="500" className={styles.actionsTitle}>
              Acciones
            </Text>

            <LinkRow className={styles.row} path={routes.book.new.meetingsRoom.details}>
              Reservar la sala de reuniones
            </LinkRow>

            <LinkRow className={styles.row} path={routes.gucsl}>
              Información sobre el GUCSL
            </LinkRow>

            <LinkRow className={styles.row} path={routes.settings.account.gucsl.cancel}>
              Solicitar baja del GUCSL
            </LinkRow>
          </div>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(GUCSLBenefitsScreen)
