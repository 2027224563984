import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconProfile = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M15.008 30C23.208 30 30 23.208 30 14.992 30 6.792 23.192 0 14.992 0S0 6.792 0 14.992C0 23.208 6.808 30 15.008 30zm9.144-6.7c-1.501-1.748-4.828-3.295-9.144-3.295-4.317 0-7.643 1.547-9.144 3.28-1.98-2.181-3.172-5.09-3.172-8.293 0-6.838 5.462-12.33 12.3-12.33a12.318 12.318 0 0112.347 12.33 12.36 12.36 0 01-3.187 8.309zm-9.144-5.755c2.816.031 5.028-2.383 5.028-5.523 0-2.955-2.228-5.416-5.028-5.416-2.816 0-5.044 2.46-5.029 5.416.016 3.14 2.197 5.508 5.029 5.523z" />
      </svg>
    </i>
  )
}

export default IconProfile
