const theme = {
  accentColor: 'rgba(var(--clrSecondary))',
  floatingNav: {
    background: 'rgb(52, 72, 94, var(--alphaMax))',
    chevron: 'rgba(var(--clrPanda))',
    color: 'rgba(var(--clrWhite))',
  },
  headerColor: 'rgba(0, 0, 0, 0)',
  selectionColor: 'rgba(var(--clrSecondary))',
  textColor: {
    active: 'rgba(var(--clrWhite))',
    default: '#7C86A2',
  },
  todayColor: 'rgba(var(--clrPanda))',
  weekdayColor: 'rgba(0, 0, 0, 0)',
}

export default theme
