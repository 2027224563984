import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconEuroSquare = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M24.79 30c1.73 0 3.032-.433 3.903-1.3.871-.865 1.307-2.147 1.307-3.845V5.145c0-1.698-.436-2.98-1.307-3.846C27.822.433 26.521 0 24.79 0H5.211C3.49 0 2.192.43 1.315 1.29.438 2.152 0 3.437 0 5.146v19.71c0 1.71.438 2.994 1.315 3.854.877.86 2.176 1.29 3.896 1.29h19.578zm-.12-2.923H5.33c-.763 0-1.352-.205-1.768-.615-.416-.41-.624-1.02-.624-1.83V5.368c0-.81.208-1.42.624-1.83.416-.41 1.005-.615 1.768-.615h19.34c.763 0 1.355.205 1.776.615.422.41.633 1.02.633 1.83v19.264c0 .81-.211 1.42-.633 1.83-.42.41-1.013.615-1.776.615zm-7.432-4.393a6.35 6.35 0 001.05-.077c.303-.051.562-.123.778-.214.444-.194.666-.507.666-.94 0-.274-.088-.49-.264-.65a1.113 1.113 0 00-.675-.273 5.237 5.237 0 00-.658.008 13.82 13.82 0 01-.914.026c-1.139 0-2.144-.28-3.015-.838-.872-.558-1.478-1.396-1.82-2.512h4.955a.735.735 0 00.555-.223.781.781 0 00.213-.564c0-.512-.256-.769-.768-.769h-5.262a6.85 6.85 0 01-.034-.667v-.367c0-.12.005-.225.017-.316h5.279a.722.722 0 00.555-.231.789.789 0 00.213-.556c0-.524-.256-.786-.768-.786h-4.938c.342-1.094.948-1.923 1.82-2.487.871-.564 1.876-.846 3.015-.846.33 0 .63.011.897.034.268.023.493.028.675.017.262-.023.481-.114.658-.274.176-.16.264-.376.264-.65a1.02 1.02 0 00-.153-.563c-.103-.16-.285-.302-.547-.428a2.532 2.532 0 00-.735-.196 8.044 8.044 0 00-1.059-.06c-1.743 0-3.286.456-4.63 1.368-1.344.911-2.226 2.273-2.648 4.085H8.218a.735.735 0 00-.556.222.781.781 0 00-.213.564c0 .217.07.402.213.556.143.154.328.23.556.23H9.72v1.351H8.218a.735.735 0 00-.556.222.76.76 0 00-.213.547c0 .228.07.416.213.564a.735.735 0 00.556.223h1.725c.433 1.812 1.321 3.176 2.665 4.094 1.344.917 2.887 1.376 4.63 1.376z" />
      </svg>
    </i>
  )
}

export default IconEuroSquare
