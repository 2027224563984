import { useFormik } from 'formik'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import Text from '../../../../../../components/atoms/text/Text'
import IdentityCard from '../../../../../../components/molecules/identityCard/IdentityCard'
import { useProfileAssistant } from '../../../../../../providers/profileAssistant'
import { VARIANTS } from '../../../../../../types/Components.types'
import { IStepProps, IStepRef } from '../types'
import styles from './IdentityCardStep.module.css'
import identityCardFormSchema from './schema/identityCard'

const IdentityCardStep = forwardRef<IStepRef, IStepProps>(({ onSubmit }, ref) => {
  const { identityCard, setIdentityCard } = useProfileAssistant()

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    initialValues: {
      identityCard,
    },
    validationSchema: identityCardFormSchema,
    onSubmit: async (values) => {
      return new Promise<void>((resolve) => {
        setIdentityCard({
          identityCard: {
            front: values.identityCard.front!,
            back: values.identityCard.back!,
          },
        })
        resolve()
        onSubmit?.()
      })
    },
  })

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }))

  const [front, setFront] = useState('')
  const [back, setBack] = useState('')

  const onFrontChange = useCallback(
    ({ files }: { files: FileList }) => {
      setFieldValue('identityCard.front', files[0])
    },
    [setFieldValue],
  )

  const onBackChange = useCallback(
    ({ files }: { files: FileList }) => {
      setFieldValue('identityCard.back', files[0])
    },
    [setFieldValue],
  )

  useEffect(() => {
    const { identityCard } = values
    if (identityCard.front) {
      const _URL = window.URL || window.webkitURL
      const blob = _URL.createObjectURL(identityCard.front)

      const img = new Image()
      img.src = blob

      img.onload = function () {
        setFront(blob)
      }
    }

    if (identityCard.back) {
      const _URL = window.URL || window.webkitURL
      const blob = _URL.createObjectURL(identityCard.back)

      const img = new Image()
      img.src = blob

      img.onload = function () {
        setBack(blob)
      }
    }
  }, [values])

  return (
    <div className={styles.container}>
      <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
        Sólo una cosa más
      </Text>

      <Text className={styles.description}>
        Por motivos de seguridad y control de acceso necesitamos una foto nítida por ambas caras de tu documento de
        identificación (DNI/NIE).
      </Text>

      <form className={styles.content} onSubmit={handleSubmit}>
        <div className={styles.section}>
          <Text size="ml" variant={VARIANTS.primary} weight="600" align="center" className={styles.sectionTitle}>
            Parte frontal
          </Text>

          <IdentityCard onChange={onFrontChange} resource={front} />

          {touched.identityCard && errors.identityCard?.front && (
            <Text variant={VARIANTS.negative} align="center" className={styles.error}>
              {errors.identityCard?.front}
            </Text>
          )}
        </div>

        <div className={styles.section}>
          <Text size="ml" variant={VARIANTS.primary} weight="600" align="center" className={styles.sectionTitle}>
            Parte trasera
          </Text>

          <IdentityCard onChange={onBackChange} resource={back} />

          {touched.identityCard && errors.identityCard?.back && (
            <Text variant={VARIANTS.negative} align="center" className={styles.error}>
              {errors.identityCard?.back}
            </Text>
          )}
        </div>
      </form>
    </div>
  )
})

export default IdentityCardStep
