import React, { useCallback } from 'react'
import styles from './CookiesScreen.module.css'
import { useLocation } from 'react-router-dom'
import { ILocationState } from '../../../types/app.types'
import Screen from '../../../components/molecules/screen/Screen'
import ScreenTitle from '../../../components/molecules/screenTitle/ScreenTitle'
import CookiesText from '../../../components/molecules/cookiesText/CookiesText'

const CookiesScreen = () => {
  const { state } = useLocation<ILocationState>()

  const isBackEnabled = useCallback(() => {
    if (!state || !state.backDisabled) {
      return true
    }
    return false
  }, [state])

  return (
    <Screen hasDecorator>
      <div className={styles.container}>
        <ScreenTitle title="Política de cookies" backEnabled={isBackEnabled()} />

        <div className={styles.content}>
          <CookiesText />
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(CookiesScreen)
