import React from 'react'
import styles from './Button.module.css'
import Loader from '../loader/Loader'
import c from 'classnames'
import { VARIANTS } from '../../../types/Components.types'

interface IProps {
  className?: string
  variant?: VARIANTS
  inverted?: boolean
  isLoading?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  autoSize?: boolean
  onClick?: () => void
  children: React.ReactNode
}

const Button = ({
  className,
  variant,
  inverted,
  children,
  isLoading,
  disabled = false,
  type = 'button',
  autoSize = false,
  ...rest
}: IProps) => {
  return (
    <button
      className={c(
        styles.button,
        className,
        variant && styles[variant],
        inverted && styles.inverted,
        disabled && styles.disabled,
        autoSize && styles.autoSize,
      )}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {!isLoading && <span className={styles.label}>{children}</span>} {isLoading && <Loader />}
    </button>
  )
}

export default React.memo(Button)
