import React from 'react'
import { useParams } from 'react-router-dom'
import Text from '../../../../components/atoms/text/Text'
import BookCard from '../../../../components/molecules/bookCard/BookCard'
import LinkRow from '../../../../components/molecules/linkRow/LinkRow'
import LoadingData from '../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../components/molecules/screen/Screen'
import routes, { generateRoute } from '../../../../config/routes'
import { useProfile } from '../../../../modules/auth/hooks'
import { useReservatedSeatById } from '../../../../modules/seats/hooks'
import { getBookDay, getBookShift, getBookType } from '../../../../modules/seats/utils'
import { usePurchasedVoucher } from '../../../../modules/vouchers/hooks'
import { getBookAutoRenew, getHumanizedBookMonths } from '../../../../modules/vouchers/utils'
import { SHIFTS } from '../../../../providers/bookAssistant/shifts'
import { TYPES } from '../../../../providers/bookAssistant/types'
import { VARIANTS } from '../../../../types/Components.types'
import styles from './ReservationDetailsScreen.module.css'

interface IParams {
  bookId: string
}

const ReservationDetailsScreen = () => {
  const { bookId } = useParams<IParams>()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: reservation, isLoading: isLoadingReservation } = useReservatedSeatById({
    userId: profile?.id,
    seatId: bookId,
  })
  const { data: voucher, isLoading: isLoadingVoucher } = usePurchasedVoucher({
    userId: profile?.id,
    voucherId: reservation?.seat.userVoucher,
  })

  const isLoading = isLoadingProfile || isLoadingReservation || isLoadingVoucher

  if (isLoading || !reservation || !voucher) {
    return <LoadingData />
  }

  const shift = getBookShift({ voucher }) ?? SHIFTS.morning
  const type = getBookType({ voucher }) ?? TYPES.DAY
  const autoRenew = getBookAutoRenew({ voucher })

  return (
    <Screen
      headerProps={{
        variant: VARIANTS.pig,
        title: 'Mi reserva',
        backEnabled: true,
      }}
    >
      <div className={styles.container}>
        <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
          Aquí tienes los detalles de tu reserva
        </Text>

        <Text className={styles.title}>
          Para acceder al coworking deberás llevar tu teléfono móvil y podrás abrir la puerta desde la pantalla
          principal, el día de la reserva.
        </Text>

        <Text>{`Recuerda que pasadas las ${shift.to}:00 tendrás que abandonar el coworking`}</Text>

        <BookCard
          seat={reservation?.name ?? ''}
          day={getBookDay({ reservation }).toJSDate()}
          shift={shift}
          type={type}
          months={getHumanizedBookMonths({ voucher })}
          className={styles.card}
        />

        <div className={styles.actions}>
          <Text weight="500" className={styles.actionsTitle}>
            Acciones
          </Text>

          <LinkRow
            className={styles.row}
            path={generateRoute(routes.book.workmates, [{ name: 'bookId', value: reservation.seat.id }])}
          >
            Ver compañeros
          </LinkRow>

          <LinkRow
            className={styles.row}
            path={generateRoute(routes.book.new.meetingsRoom.details, [{ name: 'bookId', value: reservation.seat.id }])}
          >
            Reservar sala de reuniones
          </LinkRow>

          <LinkRow
            className={styles.row}
            path={generateRoute(routes.settings.account.vouchers.byId, [
              { name: 'voucherId', value: reservation.seat.userVoucher },
            ])}
          >
            Ver bono de la reserva
          </LinkRow>

          {type === TYPES.MONTH && (
            <LinkRow
              className={styles.row}
              path={generateRoute(routes.book.changeSeat, [{ name: 'bookId', value: reservation.seat.id }])}
            >
              Cambiar sitio
            </LinkRow>
          )}

          {autoRenew && (
            <LinkRow
              className={styles.row}
              path={generateRoute(routes.book.cancel, [{ name: 'bookId', value: reservation.seat.id }])}
            >
              Cancelar la renovación automática del puesto
            </LinkRow>
          )}
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(ReservationDetailsScreen)
