import React, { useCallback, useEffect, useState, useRef } from 'react'
import styles from './Ok.module.css'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { ReactComponent as PaymentCompleteIllustration } from './assets/payment_complete_illustration.svg'
import routes from '../../../../../config/routes'
import { useConfirmPaymentMutation } from '../../../../../modules/vouchers/hooks'
import Screen from '../../../../../components/molecules/screen/Screen'
import Text from '../../../../../components/atoms/text/Text'
import Loader from '../../../../../components/atoms/loader/Loader'
import Button from '../../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../../types/Components.types'

interface IParams {
  bank: string
  paymentId: string
}

const Ok = () => {
  const { bank, paymentId } = useParams<IParams>()
  const history = useHistory()
  const location = useLocation()
  const [isWorking, setIsWorking] = useState(true)
  const queryParams = useRef(new URLSearchParams(location.search)).current
  const { mutate: confirmPayment } = useConfirmPaymentMutation()

  const handleContinueClick = useCallback(() => {
    history.replace(routes.home)
  }, [history])

  useEffect(() => {
    confirmPayment(
      {
        bank,
        paymentId,

        merchantParameters: queryParams.get('Ds_MerchantParameters')!,
        signature: queryParams.get('Ds_Signature')!,
      },
      {
        onSuccess: () => {
          setIsWorking(false)
        },
      },
    )
  }, [bank, confirmPayment, paymentId, queryParams])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <PaymentCompleteIllustration />
          {!isWorking && (
            <>
              <Text size="l" align="center" variant={VARIANTS.primary} weight="700" className={styles.title}>
                ¡Listo!
              </Text>
              <Text className={styles.description} align="center">
                La renovación de tu bono se ha realizado correctamente. ¡Ya puedes disfrutar de tu sitio!
              </Text>
            </>
          )}
          {isWorking && <Loader className={styles.loader} variant="primary" />}
        </div>

        {!isWorking && (
          <Button variant={VARIANTS.primary} className={styles.action} onClick={handleContinueClick}>
            ¡Vamos!
          </Button>
        )}
      </div>
    </Screen>
  )
}

export default React.memo(Ok)
