import * as Yup from 'yup'

const profileFormSchema = Yup.object({
  firstname: Yup.string().required('Debes especificar tu nombre'),
  lastname: Yup.string().required('Debes especificar tus apellidos'),
  profession: Yup.string(),
  linkedIn: Yup.string().nullable(),
  facebook: Yup.string().nullable(),
  twitter: Yup.string().nullable(),
  address: Yup.object().shape({
    street: Yup.string().required('La dirección es obligatoria'),
    postalCode: Yup.string().required('El código postal es obligatorio'),
    state: Yup.string().required('La provincia es obligatoria'),
    locality: Yup.string().required('La localidad es obligatoria'),
    country: Yup.string().required('El país es obligatorio'),
  }),
})

export default profileFormSchema
