import React from 'react'
import Text from '../../../../components/atoms/text/Text'
import styles from './Cancellations.module.css'
import { terms } from '../../../../config'

const Cancellations = () => {
  return (
    <>
      <section className={styles.container}>
        <Text weight="500" size="xl" className={styles.title} align="center">
          Política de cancelaciones o desistimiento
        </Text>

        <Text size="ml" align="center">
          Nos ceñimos a la Ordenanza reguladora de uso y funcionamiento del Coworking, en la disposición Décima,
          apartado j):
        </Text>

        <Text size="ml" align="center" weight="500" className={styles.p}>
          Las reservas una vez pagadas no se puede devolver el coste. Si no se usa el servicio por la circunstancia que
          sea, se pierde. Algunos servicios admiten el cambio de fecha, tal y como se ha expuesto en puntos anteriores.
        </Text>

        <a className={styles.button} href={terms} rel="noreferrer" target="_blank">
          Consulta la normativa completa
        </a>
      </section>

      <section className={styles.container}>
        <Text weight="500" size="xl" className={styles.title} align="center">
          Política de envíos
        </Text>

        <Text size="ml" align="center">
          No es aplicable a los servicios que se pueden contratar en la web.
        </Text>
      </section>
    </>
  )
}

export default React.memo(Cancellations)
