import React, { useCallback } from 'react'
import styles from './GUCSLScreen.module.css'
import { useHistory, useLocation } from 'react-router-dom'
import routes from '../../../config/routes'
import Screen from '../../../components/molecules/screen/Screen'
import ScreenTitle from '../../../components/molecules/screenTitle/ScreenTitle'
import Text from '../../../components/atoms/text/Text'
import Button from '../../../components/atoms/button/Button'
import { VARIANTS } from '../../../types/Components.types'
import { ILocationState } from '../../../types/app.types'
import { useProfile, useUpdateProfileMutation } from '../../../modules/auth/hooks'

const GUCSLScreen = () => {
  const history = useHistory()
  const { state } = useLocation<ILocationState>()
  const updateProfileMutation = useUpdateProfileMutation()
  const { data: profile } = useProfile()

  const isBackEnabled = useCallback(() => {
    if (!state || !state.backDisabled) {
      return true
    }
    return false
  }, [state])

  const handleCancelClick = useCallback(() => {
    history.replace(routes.app)
  }, [history])

  const handleJoinClick = useCallback(() => {
    updateProfileMutation.mutate(
      { userId: profile!.id, isGUCSL: true },
      {
        onSuccess: () => {
          history.replace(routes.app)
        },
      },
    )
  }, [history, profile, updateProfileMutation])

  return (
    <Screen hasDecorator>
      <div className={styles.container}>
        <ScreenTitle
          title="Grupo de Usuarios del Coworking Municipal de San Lorenzo de El Escorial"
          backEnabled={isBackEnabled()}
        />

        <div className={styles.content}>
          <Text weight="600" className={styles.header}>
            ¿Qué es el GUCSL?
          </Text>

          <Text className={styles.block}>
            Este grupo actuará como apoyo "in situ" al CDI para velar por el buen funcionamiento del coworking, así como
            proponer y colaborar con nuevas iniciativas.
          </Text>

          <Text weight="600" className={styles.header}>
            ¿Cuáles serán mis obligaciones como miembro?
          </Text>

          <Text className={styles.block}>
            - Recibir a nuevos usuarios que no conozcan el espacio y mostrarles el mismo
          </Text>

          <Text className={styles.block}>- Informar de la normativa a otros usuarios</Text>

          <Text className={styles.block}>
            - Informar al CDI de aquellos problemas que interfieran con el normal funcionamiento del coworking
          </Text>

          <Text weight="600" className={styles.header}>
            ¿Tengo algún beneficio?
          </Text>

          <Text className={styles.block}>¡Sí! Por formar parte de este grupo, te beneficiarás de lo siguiente:</Text>

          <Text className={styles.block}>
            - Uso gratuito de{' '}
            <Text weight="500" element="span">
              3 horas mensuales adicionales
            </Text>{' '}
            de la sala de reuniones
          </Text>

          <Text className={styles.block}>
            - Formar parte de los comités de evaluación de propuestas de emprendedores/as para el uso de espacio
            gratuito
          </Text>

          <Text className={styles.block}>
            - Proponer iniciativas para el coworking (actividades, nuevos servicios, etc)
          </Text>

          <div className={styles.actions}>
            <Button inverted variant={VARIANTS.max} className={styles.action} onClick={handleCancelClick}>
              Ahora no
            </Button>

            <Button variant={VARIANTS.primary} className={styles.action} onClick={handleJoinClick}>
              ¡Unirme!
            </Button>
          </div>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(GUCSLScreen)
