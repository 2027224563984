import { DateTime, DurationObjectUnits } from 'luxon'
import { TYPES } from '../../providers/bookAssistant/types'
import { IReservatedSeat, IUserPurchaseVoucher } from '../../types/app.types'

export const orderBooksByDate = (a: IReservatedSeat, b: IReservatedSeat) => {
  return getBookDay({ reservation: a }).toMillis() - getBookDay({ reservation: b }).toMillis()
}

export const getReservationDay = ({ reservation }: { reservation: IReservatedSeat }) => {
  if (!reservation) {
    return DateTime.local()
  }
  return DateTime.fromISO(reservation.from)
}

export const getBookDay = ({ reservation }: { reservation: IReservatedSeat }) => {
  const { seat } = reservation
  if (!seat) {
    return DateTime.local()
  }
  return DateTime.fromISO(seat.reserved_from)
}

export const getBookSeatType = ({ voucher }: { voucher?: IUserPurchaseVoucher }) => {
  if (!voucher) {
    return null
  }
  const { spec } = voucher
  return spec.seatType
}

export const getBookShift = ({ voucher }: { voucher?: IUserPurchaseVoucher }) => {
  if (!voucher) {
    return null
  }
  const { seat } = voucher?.spec
  return seat?.schedule
}

export const getBookType = ({ voucher }: { voucher?: IUserPurchaseVoucher }) => {
  if (!voucher) {
    return null
  }
  const { seat } = voucher?.spec
  return seat?.type === 'period' ? TYPES.MONTH : TYPES.DAY
}

export const getBookDaysLeft = ({ reservation }: { reservation: IReservatedSeat }): Partial<DurationObjectUnits> => {
  const { seat } = reservation
  if (!seat) {
    return { months: 0, days: 0, hours: 0 }
  }
  const reserved = DateTime.fromISO(seat.reserved_from)
  return reserved.diffNow(['months', 'days', 'hours']).toObject()
}

export const getHumanizedBookDaysLeft = ({ reservation }: { reservation: IReservatedSeat }) => {
  const { months = 0, days = 0, hours = 0 } = getBookDaysLeft({ reservation })
  if (months > 0) {
    return `En ${Math.floor(months)} mes${Math.floor(months) !== 1 ? 'es' : ''}`
  }

  if (days > 0) {
    return `En ${Math.floor(days)} día${Math.floor(days) !== 1 ? 's' : ''}`
  }

  if (hours > 0) {
    return `En ${Math.floor(hours)} hora${Math.floor(hours) !== 1 ? 's' : ''}`
  }

  return ''
}
