import React from 'react'
import styles from './SectionHeader.module.css'
import c from 'classnames'
import Text from '../../../../../components/atoms/text/Text'

interface IProps {
  icon?: React.ReactNode
  children: React.ReactNode
  className?: string
}

const SectionHeader = ({ icon, children, className }: IProps) => {
  return (
    <div className={c(styles.container, className)}>
      {icon && <span className={styles.iconWrapper}>{icon}</span>}
      <Text weight="600" size="ml">
        {children}
      </Text>
    </div>
  )
}

export default React.memo(SectionHeader)
