import React, { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '../../../../components/atoms/button/Button'
import Link from '../../../../components/atoms/link/Link'
import Text from '../../../../components/atoms/text/Text'
import Screen from '../../../../components/molecules/screen/Screen'
import routes from '../../../../config/routes'
import { Geolocation } from '../../../../modules/geolocation/service'
import { VARIANTS } from '../../../../types/Components.types'
import { ILocationState } from '../../../../types/app.types'
import styles from './GeolocationScreen.module.css'
import { ReactComponent as ErrorIllustration } from './assets/error_illustration.svg'
import { ReactComponent as GeolocationIllustration } from './assets/geolocation_illustration.svg'

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

const GeolocationScreen = () => {
  const history = useHistory()
  const [error, setError] = useState<Error | null>(null)
  const [isGrantingGeolocation, setIsGrantingGeolocation] = useState(false)
  const { state } = useLocation<ILocationState>()

  const askForPermissions = useCallback(async () => {
    setIsGrantingGeolocation(true)
    await Geolocation.askForPermission().catch((error) => {
      setError(error)
      setIsGrantingGeolocation(false)
    })
    setIsGrantingGeolocation(false)

    if (Geolocation.state === 'denied') {
      setError(new Error('Not Granted'))
      return
    }
    return history.replace(state && state.from ? state.from.pathname : routes.app)
  }, [history, state])

  const grantPermissions = useCallback(async () => {
    Geolocation.grantPermission()
    return history.replace(state && state.from ? state.from.pathname : routes.app)
  }, [history, state])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        {!error && (
          <div className={styles.content}>
            <GeolocationIllustration />
            <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title} align="center">
              Necesitamos acceder a tu ubicación
            </Text>
            <Text className={styles.description} align="center">
              Para poder abrir la puerta del coworking con tu móvil, es necesario que nos dejes acceder a tu posición
              únicamente en el momento de acceder
            </Text>
          </div>
        )}
        {!error && (
          <>
            <Button
              variant={VARIANTS.primary}
              className={styles.action}
              onClick={askForPermissions}
              isLoading={isGrantingGeolocation}
            >
              Sí, te doy permisos
            </Button>

            {!isMobile() ? (
              <Link
                className={styles.skip}
                path={state && state.from ? state.from.pathname : routes.app}
                onClick={() => Geolocation.grantPermission()}
              >
                Saltar
              </Link>
            ) : null}
          </>
        )}

        {error && (
          <>
            <div className={styles.content}>
              <ErrorIllustration />
              <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title} align="center">
                ¡Vaya!
              </Text>
              <Text className={styles.description} align="center">
                Parece que rechazaste los permisos para acceder a tu ubicación o no somos capaces de ubicarte. Recuerda
                que son necesarios para acceder al coworking, pero no es necesario para otras gestiones.
              </Text>
            </div>

            <Button variant={VARIANTS.primary} className={styles.action} onClick={grantPermissions}>
              Continuar
            </Button>
          </>
        )}
      </div>
    </Screen>
  )
}

export default React.memo(GeolocationScreen)
