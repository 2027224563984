import React, { useCallback } from 'react'
import styles from './RecoverPasswordCompleteScreen.module.css'
import Text from '../../../../../components/atoms/text/Text'
import { useHistory } from 'react-router-dom'
import { ReactComponent as CompleteIllustration } from './assets/complete_illustration.svg'
import Screen from '../../../../../components/molecules/screen/Screen'
import Button from '../../../../../components/atoms/button/Button'
import routes from '../../../../../config/routes'
import { VARIANTS } from '../../../../../types/Components.types'

const RecoverPasswordCompleteScreen = ({ ...rest }) => {
  const history = useHistory()

  const goToLogin = useCallback(() => {
    history.replace(routes.login)
  }, [history])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <CompleteIllustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
            ¡Listo!
          </Text>
          <Text className={styles.description} align="center">
            Tu contraseña ha sido reestablecida correctamente. Ya puedes acceder a tu cuenta con la nueva contraseña
          </Text>
        </div>

        <Button variant={VARIANTS.primary} className={styles.action} onClick={goToLogin}>
          ¡Vamos!
        </Button>
      </div>
    </Screen>
  )
}

export default React.memo(RecoverPasswordCompleteScreen)
