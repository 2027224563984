import React, { useCallback } from 'react'
import styles from './ListItem.module.css'
import c from 'classnames'
import { useHistory } from 'react-router-dom'
import { IReservatedSeat } from '../../../../../../types/app.types'
import routes, { generateRoute } from '../../../../../../config/routes'
import Card from '../../../../../../components/atoms/card/Card'
import Text from '../../../../../../components/atoms/text/Text'
import { getBookDay, getHumanizedBookDaysLeft } from '../../../../../../modules/seats/utils'
import { VARIANTS } from '../../../../../../types/Components.types'

interface IProps {
  className?: string
  reservation: IReservatedSeat
}

const ListItem: React.FC<IProps> = ({ className, reservation }) => {
  const history = useHistory()

  const goToreservationDetails = useCallback(() => {
    history.push(generateRoute(routes.book.details, [{ name: 'bookId', value: reservation.seat.id }]))
  }, [history, reservation])

  return (
    <button className={c(styles.container, className)} onClick={goToreservationDetails}>
      <Card className={styles.decorator}>
        <Text weight="500" variant={VARIANTS.white}>
          {reservation.name}
        </Text>
      </Card>
      <div className={styles.infoWrapper}>
        <Text weight="600">{getBookDay({ reservation: reservation }).toFormat("d 'de' LLLL")}</Text>
        <Text size="sm">{getHumanizedBookDaysLeft({ reservation })}</Text>
      </div>
    </button>
  )
}

export default React.memo(ListItem)
