import React, { useCallback, useEffect } from 'react'
import styles from './DatePicker.module.css'
import { DateTime } from 'luxon'
import { useDebouncedCallback } from 'use-debounce'
import { toast } from 'react-toastify'
import { TYPES } from '../../../../../../../providers/bookAssistant/types'
import { useBookAssistant } from '../../../../../../../providers/bookAssistant'
import { useCardTransition } from '../../../../../../../providers/cardTransition/cardTransition'
import Text from '../../../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../../../types/Components.types'
import Calendar from '../../../../../../../components/atoms/calendar/Calendar'
import Button from '../../../../../../../components/atoms/button/Button'

interface IProps {
  highlightedDates: Date[]
}

const setHightlightClass = ({ date, type }: { date: Date; type: string }) => {
  const day = document.querySelector(`li[data-date="${DateTime.fromJSDate(date).toISODate()}"]`)
  if (!day) {
    return
  }

  if (type === TYPES.COWORKING_3_DAYS) {
    if (DateTime.fromJSDate(date).weekday === 1 || DateTime.fromJSDate(date).weekday === 5) {
      day.classList.add('Cal__Day__enabled')

      if (DateTime.fromJSDate(date).diffNow('days').days > 0) {
        day.classList.remove('Cal__Day__disabled')
      }
    } else {
      day.classList.remove('Cal__Day__disabled')
    }
  }
  day.classList.add(styles.highlighted)
}

const DatePicker = ({ highlightedDates = [] }: IProps) => {
  const { type, coworkingDays, setCoworkingDays } = useBookAssistant()
  const { closeCard } = useCardTransition()

  const handleContinueClick = useCallback(() => {
    closeCard?.()
  }, [closeCard])

  const handleDaySelected = useCallback(
    (value: Date) => {
      const days = []
      const selectedDate = DateTime.fromJSDate(value)
      switch (type) {
        case TYPES.COWORKING_SATURDAY:
          days.push(value)
          break

        case TYPES.COWORKING_HOLIDAY:
          if (
            !highlightedDates.some((highlight) => {
              const isSunday = DateTime.fromJSDate(highlight).weekday === 7
              return isSunday || selectedDate.startOf('day').equals(DateTime.fromJSDate(highlight).startOf('day'))
            })
          ) {
            toast.error('Oops! Este día no es Domingo ni festivo')
            break
          }
          days.push(value)
          break

        case TYPES.COWORKING_3_DAYS:
          if (
            !highlightedDates.some((highlight) => {
              const isMondayOrFriday =
                DateTime.fromJSDate(highlight).weekday === 1 || DateTime.fromJSDate(highlight).weekday === 5
              return (
                isMondayOrFriday && selectedDate.startOf('day').equals(DateTime.fromJSDate(highlight).startOf('day'))
              )
            })
          ) {
            toast.error('Oops! Este día no es Lunes ni Viernes festivo')
            break
          }
          if (selectedDate.weekday === 1) {
            days.push(selectedDate.minus({ days: 2 }).toJSDate())
            days.push(selectedDate.minus({ days: 1 }).toJSDate())
            days.push(value)
          } else {
            days.push(value)
            days.push(selectedDate.plus({ days: 1 }).toJSDate())
            days.push(selectedDate.plus({ days: 2 }).toJSDate())
          }
          break

        case TYPES.COWORKING_WEEKEND:
          if (selectedDate.weekday === 6) {
            days.push(value)
            days.push(selectedDate.plus({ days: 1 }).toJSDate())
          } else {
            days.push(selectedDate.minus({ days: 1 }).toJSDate())
            days.push(value)
          }
          break
        default:
          break
      }
      setCoworkingDays({ coworkingDays: days })
    },
    [highlightedDates, setCoworkingDays, type],
  )

  const getDisabledDays = useCallback(() => {
    if (type === TYPES.COWORKING_HOLIDAY) {
      return []
    }

    if (type === TYPES.COWORKING_SATURDAY) {
      return [1, 2, 3, 4, 5, 7] as (0 | 1 | 2 | 3 | 4 | 5 | 6)[]
    }

    if (type === TYPES.COWORKING_WEEKEND) {
      return [1, 2, 3, 4, 5] as (0 | 1 | 2 | 3 | 4 | 5 | 6)[]
    }

    if (type === TYPES.COWORKING_3_DAYS) {
      return []
    }

    return []
  }, [type])

  const handleScrollChange = useDebouncedCallback(() => {
    highlightedDates.forEach((date) => setHightlightClass({ date, type }))
  }, 600)

  useEffect(() => {
    highlightedDates.forEach((date) => setHightlightClass({ date, type }))
  }, [highlightedDates, type, coworkingDays])

  const minDate = DateTime.local().startOf('day').plus({ days: 1 })
  const multipleDateSelection = type === TYPES.COWORKING_WEEKEND || type === TYPES.COWORKING_3_DAYS

  return (
    <div className={styles.container}>
      <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
        Elige el día
      </Text>

      <div className={styles.content}>
        <Calendar
          className={styles.calendar}
          selected={multipleDateSelection ? coworkingDays : coworkingDays[0]}
          onSelect={handleDaySelected}
          minDate={minDate.toJSDate()}
          disabledDays={getDisabledDays()}
          multipleDateSelection={multipleDateSelection}
          onScroll={handleScrollChange}
        />
      </div>

      <div className={styles.actions}>
        <Button
          variant={VARIANTS.primary}
          className={styles.action}
          onClick={handleContinueClick}
          disabled={coworkingDays.length === 0}
        >
          Confirmar
        </Button>
      </div>
    </div>
  )
}

export default React.memo(DatePicker)
