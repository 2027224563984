import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../../../components/atoms/button/Button'
import Text from '../../../../../components/atoms/text/Text'
import ListItem from '../../../../../components/molecules/listItem/ListItem'
import Screen from '../../../../../components/molecules/screen/Screen'
import ToggleButton from '../../../../../components/molecules/toggleButton/ToggleButton'
import routes from '../../../../../config/routes'
import { useProfile } from '../../../../../modules/auth/hooks'
import { useHolidays, useReservateCoworkingMutation } from '../../../../../modules/seats/hooks'
import { useBookAssistant } from '../../../../../providers/bookAssistant'
import { TYPES } from '../../../../../providers/bookAssistant/types'
import { useCardTransition } from '../../../../../providers/cardTransition/cardTransition'
import { VARIANTS } from '../../../../../types/Components.types'
import styles from './BookPlaceScreen.module.css'
import DatePicker from './components/datePicker/DatePicker'
import UploadFile from './components/uploadFile/UploadFile'

const BookPlaceScreen = () => {
  const history = useHistory()
  const { type, setType, coworkingDays, attachments } = useBookAssistant()
  const { data: holidays } = useHolidays()
  const { data: profile } = useProfile()
  const reservateCoworkingMutation = useReservateCoworkingMutation()

  const { openCard } = useCardTransition()

  const handleContinueClick = useCallback(async () => {
    reservateCoworkingMutation.mutate(
      {
        userId: profile!.id,
        days: coworkingDays,
        attachments,
      },
      {
        onSuccess: () => {
          history.replace(routes.app)
        },
      },
    )
  }, [reservateCoworkingMutation, profile, coworkingDays, attachments, history])

  const handleBookTypeDayClick = useCallback(() => {
    setType({ type: TYPES.COWORKING_SATURDAY })
  }, [setType])

  const handleBookTypeWeekendClick = useCallback(() => {
    setType({ type: TYPES.COWORKING_WEEKEND })
  }, [setType])

  const handleBookTypeHolidayClick = useCallback(() => {
    setType({ type: TYPES.COWORKING_HOLIDAY })
  }, [setType])

  const handleBookType3DaysClick = useCallback(() => {
    setType({ type: TYPES.COWORKING_3_DAYS })
  }, [setType])

  const handleDateSelectorClick = useCallback(() => {
    openCard?.({
      Component: <DatePicker highlightedDates={holidays} />,
    })
  }, [openCard, holidays])

  const getHumanizedDay = useCallback(() => {
    switch (type) {
      case TYPES.COWORKING_SATURDAY:
      case TYPES.COWORKING_HOLIDAY:
        return DateTime.fromJSDate(coworkingDays[0]).toLocaleString(DateTime.DATE_MED)

      case TYPES.COWORKING_WEEKEND:
        return `${DateTime.fromJSDate(coworkingDays[0]).day} y ${DateTime.fromJSDate(coworkingDays[1]).day} de ${
          DateTime.fromJSDate(coworkingDays[0]).monthShort
        }`

      case TYPES.COWORKING_3_DAYS:
        return `${DateTime.fromJSDate(coworkingDays[0]).day}, ${DateTime.fromJSDate(coworkingDays[1]).day} y ${
          DateTime.fromJSDate(coworkingDays[2]).day
        } de ${DateTime.fromJSDate(coworkingDays[0]).monthShort}`
      default:
        return ''
    }
  }, [type, coworkingDays])

  const isCoworkingDayReservation = type === TYPES.COWORKING_SATURDAY
  const isCoworkingWeekendReservation = type === TYPES.COWORKING_WEEKEND
  const isCoworkingHolidayReservation = type === TYPES.COWORKING_HOLIDAY
  const isCoworking3DaysReservation = type === TYPES.COWORKING_3_DAYS

  return (
    <Screen
      headerProps={{
        backEnabled: true,
        variant: VARIANTS.flamingo,
        title: 'Nueva reserva',
      }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <ListItem label="Condiciones" className={styles.terms}>
            <Text size="sm" className={styles.termsDescription}>
              Para reservar el coworking se cobrará una fianza del 50% del importe del alquiler. Además deberás disponer
              de un seguro de responsabilidad civil y de daños el edificio y mobiliario por{' '}
              <Text element="span" size="sm" weight="600">
                importe mínimo de 300.000€
              </Text>
              .
            </Text>
          </ListItem>

          <div className={styles.reservationTypeWrapper}>
            <Text>Tipo de reserva</Text>

            <div className={styles.reservationType}>
              <ToggleButton
                className={styles.reservationButton}
                isActive={isCoworkingDayReservation}
                onClick={handleBookTypeDayClick}
              >
                <Text size="sm" variant={isCoworkingDayReservation ? VARIANTS.white : VARIANTS.secondary} weight="500">
                  SÁBADO
                </Text>
              </ToggleButton>

              <ToggleButton
                className={styles.reservationButton}
                isActive={isCoworkingWeekendReservation}
                onClick={handleBookTypeWeekendClick}
              >
                <Text
                  size="sm"
                  variant={isCoworkingWeekendReservation ? VARIANTS.white : VARIANTS.secondary}
                  weight="500"
                >
                  FIN DE SEMANA
                </Text>
              </ToggleButton>

              <ToggleButton
                className={styles.reservationButton}
                isActive={isCoworkingHolidayReservation}
                onClick={handleBookTypeHolidayClick}
              >
                <Text
                  size="sm"
                  variant={isCoworkingHolidayReservation ? VARIANTS.white : VARIANTS.secondary}
                  weight="500"
                >
                  DOMINGO/FESTIVO
                </Text>
              </ToggleButton>

              <ToggleButton
                className={styles.reservationButton}
                isActive={isCoworking3DaysReservation}
                onClick={handleBookType3DaysClick}
              >
                <Text
                  size="sm"
                  variant={isCoworking3DaysReservation ? VARIANTS.white : VARIANTS.secondary}
                  weight="500"
                >
                  3 DÍAS
                </Text>
              </ToggleButton>
            </div>
          </div>

          <ListItem label="Cuando quieres reservar">
            <Button
              className={styles.button}
              autoSize
              variant={VARIANTS.secondary}
              inverted
              onClick={handleDateSelectorClick}
              disabled={!type}
            >
              <Text size="sm" variant={VARIANTS.secondary} weight="500">
                {coworkingDays.length > 0 ? getHumanizedDay() : 'Seleccionar'}
              </Text>
            </Button>
          </ListItem>

          <ListItem label="Documentación">
            <UploadFile />
          </ListItem>
        </div>

        <div className={styles.actions}>
          <Button
            variant={VARIANTS.primary}
            className={styles.action}
            onClick={handleContinueClick}
            disabled={coworkingDays.length === 0 || attachments.length === 0}
          >
            Solicitar
          </Button>
        </div>
      </div>
    </Screen>
  )
}

export default BookPlaceScreen
