import React from 'react'
import styles from './Logo.module.css'
import { ReactComponent as LogoSVG } from './assets/logo.svg'
import { ReactComponent as AyuntaminetoSVG } from './assets/ayuntamiento.svg'
import c from 'classnames'

interface IProps {
  className?: string
  size?: 's' | 'm' | 'l'
}

const Logo = ({ className, size = 'm' }: IProps) => {
  return (
    <div className={c(styles.wrapper, className)}>
      <AyuntaminetoSVG className={c(styles.ayuntamiento, styles[size])} />
      <LogoSVG className={styles[size]} />
    </div>
  )
}

export default React.memo(Logo)
