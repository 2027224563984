import React, { useState, useEffect, useRef } from 'react'
import styles from './ValidateScreen.module.css'
import { ReactComponent as CompleteIllustration } from './assets/illustration.svg'
import { useHistory, useLocation } from 'react-router-dom'
import Screen from '../../../../../components/molecules/screen/Screen'
import Text from '../../../../../components/atoms/text/Text'
import Loader from '../../../../../components/atoms/loader/Loader'
import { VARIANTS } from '../../../../../types/Components.types'
import { useValidateAccountMutation } from '../../../../../modules/auth/hooks'
import routes from '../../../../../config/routes'

const ValidateScreen = () => {
  const history = useHistory()
  const location = useLocation()
  const [hasError, setHasError] = useState(false)
  const queryParams = useRef(new URLSearchParams(location.search)).current

  const { mutate: validateAccount } = useValidateAccountMutation()

  useEffect(() => {
    validateAccount(
      {
        userId: queryParams.get('userId') as string,
        nonce: queryParams.get('nonce') as string,
        token: queryParams.get('token') as string,
        mode: queryParams.get('mode') as string,
      },
      {
        onSuccess: () => {
          history.replace(routes.app)
        },
        onError: () => {
          setHasError(true)
        },
      },
    )
  }, [queryParams, history, validateAccount])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <CompleteIllustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title} align="center">
            Estamos validando tu cuenta
          </Text>

          {!hasError && <Loader variant="primary" className={styles.loader} />}

          {hasError && (
            <Text className={styles.description} align="center">
              ¡Vaya! Parece que no pudimos validar tu cuenta en este momento. Ponte en contacto con nosotros si el
              problema persiste
            </Text>
          )}
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(ValidateScreen)
