import React, { useCallback, useRef } from 'react'
import Button from '../../../../../../../components/atoms/button/Button'
import Text from '../../../../../../../components/atoms/text/Text'
import { useBookAssistant } from '../../../../../../../providers/bookAssistant'
import { VARIANTS } from '../../../../../../../types/Components.types'
import styles from './UploadFile.module.css'

const UploadFile = () => {
  const { attachments, setAttachments } = useBookAssistant()
  const input = useRef<HTMLInputElement>(null)

  const handleOpenFileSelector = useCallback(() => {
    // Clean files first
    setAttachments({ attachments: [] })
    input.current?.click()
  }, [setAttachments])

  const handleAttachmentsChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(evt.target.files ?? [])
      setAttachments({ attachments: Array.isArray(files) ? files : [files] })
    },
    [setAttachments],
  )

  const getFileName = useCallback(() => {
    const file = attachments[0]
    return file.name
  }, [attachments])

  return (
    <div>
      <Button className={styles.button} autoSize variant={VARIANTS.secondary} inverted onClick={handleOpenFileSelector}>
        <Text size="sm" variant={VARIANTS.secondary} weight="500" truncate>
          {attachments.length === 0 ? 'Adjuntar' : getFileName()}
        </Text>
      </Button>
      <input ref={input} className={styles.input} type="file" onChange={handleAttachmentsChange} />
    </div>
  )
}

export default React.memo(UploadFile)
