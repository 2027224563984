/* eslint-disable react-hooks/exhaustive-deps */
import { Reducer, useCallback, useEffect, useReducer } from 'react'

export const prepareSlidesCarouselData = (slides: any[]) => {
  const slidesArr: any[] = []
  slides.forEach((item, index) => {
    if (item.carouselIndex) {
      console.error('Prepare Slides Carousel Data is overeading de key __carouselIndex becouse it is used internaly')
    }
    slidesArr.push({ ...item, carouselIndex: index })
  })
  return slidesArr
}

interface IState {
  items: any
  activeIndex: number
  firstActiveIndex: number
  lastActiveIndex: number
  prevActiveIndex: number
  direction: 'next' | 'back'
}

interface IAction {
  items?: any
  firstActiveIndex?: number
  lastActiveIndex?: number
  activeIndex?: number
}

interface Slide {
  id: string
  [key: string]: any
}

interface IProps {
  active?: number
  slides: Slide[]
  loop?: boolean
  activesLength?: number
}

function useCarousel({ active = 0, slides = [], loop = false, activesLength = 1 }: IProps) {
  const [_itemsState, _dispatchItems] = useReducer<Reducer<IState, IAction>>(
    (state, action) => {
      let nextState = { ...state }
      let changed = false // it is to return the previous state if there isn't changes

      if (action.items) {
        nextState.items = action.items
        changed = true
      }

      if (action.firstActiveIndex || action.firstActiveIndex === 0) {
        nextState.firstActiveIndex = action.firstActiveIndex
        changed = true
      }

      if (action.lastActiveIndex || action.lastActiveIndex === 0) {
        nextState.lastActiveIndex = action.lastActiveIndex
        changed = true
      }

      if (action.activeIndex || action.activeIndex === 0) {
        nextState.prevActiveIndex = state.activeIndex
        nextState.activeIndex = action.activeIndex
        nextState.direction =
          (nextState.activeIndex > nextState.prevActiveIndex && 'next') ||
          (nextState.activeIndex < nextState.prevActiveIndex && 'back') ||
          nextState.direction
        changed = true
      }

      return changed ? nextState : state
    },
    {
      items: {},
      activeIndex: active,
      firstActiveIndex: active,
      lastActiveIndex: active + (activesLength - 1),
      prevActiveIndex: active - 1,
      direction: active === slides.length - 1 ? 'back' : 'next',
    },
  )

  const _isLast = _itemsState.activeIndex === slides.length - 1
  const _isFirst = _itemsState.activeIndex === 0

  const generateActiveItems = useCallback(
    (index: number | void) => {
      const _slides = [...slides]
      const nextActiveIndex = Number.isInteger(index) ? index : _itemsState.activeIndex

      const getBoundaries = () => {
        const { firstActiveIndex, lastActiveIndex } = _itemsState

        let nextBoundaries = {
          firstActiveIndex,
          lastActiveIndex,
        }

        let diff = 0
        if (nextActiveIndex > lastActiveIndex) {
          // right boundary -> next
          diff = (nextActiveIndex as number) - lastActiveIndex
          const outOfRightBoundary = lastActiveIndex + diff >= slides.length

          if (!outOfRightBoundary) {
            nextBoundaries = {
              firstActiveIndex: firstActiveIndex + diff,
              lastActiveIndex: lastActiveIndex + diff,
            }
          }
        }

        if (nextActiveIndex < firstActiveIndex) {
          // left boundary -> back
          diff = firstActiveIndex - (nextActiveIndex as number)
          const outOfRightBoundary = lastActiveIndex + diff < 0

          if (!outOfRightBoundary) {
            nextBoundaries = {
              firstActiveIndex: firstActiveIndex - diff,
              lastActiveIndex: lastActiveIndex - diff,
            }
          }
        }

        return nextBoundaries
      }

      const { firstActiveIndex, lastActiveIndex } = getBoundaries()
      const back = _slides[firstActiveIndex - 1]
      const actives = _slides.slice(firstActiveIndex, lastActiveIndex + 1)
      const next = _slides[lastActiveIndex + 1]

      let nextItems = {}
      if (back) {
        nextItems = {
          ...nextItems,
          [back.id]: back,
        }
      }

      if (actives && actives.length) {
        const activesObject: { [key: string]: any } = {}
        actives.forEach((item) => {
          activesObject[item.id] = item
        })

        nextItems = {
          ...nextItems,
          ...activesObject,
        }
      }

      if (next) {
        nextItems = {
          ...nextItems,
          [next.id]: next,
        }
      }

      _dispatchItems({
        activeIndex: nextActiveIndex as number,
        firstActiveIndex,
        lastActiveIndex,
        items: nextItems,
      })
    },
    [_itemsState],
  )

  const _hanldeNext = useCallback(() => {
    if (_isLast && !loop) {
      return
    }

    const nextActiveIndex = _isLast ? 0 : _itemsState.activeIndex + 1
    generateActiveItems(nextActiveIndex)
  }, [_isLast, _itemsState.activeIndex, generateActiveItems])

  const _hanldeBack = useCallback(() => {
    if (_isFirst && !loop) {
      return
    }

    const nextActiveIndex = _isFirst ? slides.length - 1 : _itemsState.activeIndex - 1
    generateActiveItems(nextActiveIndex)
  }, [_isFirst, _itemsState.activeIndex, generateActiveItems])

  useEffect(() => {
    generateActiveItems()
  }, [_itemsState.activeIndex])

  useEffect(() => {
    _dispatchItems({ activeIndex: active })
  }, [active])

  return {
    ..._itemsState,
    activeItem: slides[_itemsState.activeIndex],
    next: _hanldeNext,
    back: _hanldeBack,
    isFirst: _isFirst,
    isLast: _isLast,
    generateActiveItems,
    setActiveIndex: (activeIndex: number) => _dispatchItems({ activeIndex }),
  }
}

export default useCarousel
