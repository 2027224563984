import React from 'react'
import Text from '../../../../components/atoms/text/Text'
import LoadingData from '../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../components/molecules/screen/Screen'
import routes, { generateRoute } from '../../../../config/routes'
import { useProfile } from '../../../../modules/auth/hooks'
import { useMeetingRoomReservations } from '../../../../modules/meetingRoom/hooks'
import { useReservatedSeats } from '../../../../modules/seats/hooks'
import { VARIANTS } from '../../../../types/Components.types'
import EmptyState from './components/emptyState/EmptyState'
import ListItem from './components/listItem/ListItem'
import MeetingRoomSlotListItem from './components/meetingRoomSlotListItem/MeetingRoomSlotListItem'
import styles from './ReservationsListScreen.module.css'

const ReservationsListScreen = () => {
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: reservations, isLoading: isLoadingReservations } = useReservatedSeats({ userId: profile?.id })
  const { data: meetingRoomReservations, isLoading: isLoadingMeetingRoomReservations } = useMeetingRoomReservations({
    userId: profile?.id,
  })

  const isLoading = isLoadingProfile || isLoadingReservations || isLoadingMeetingRoomReservations

  const emptyStatePath = React.useMemo(() => {
    if (!reservations || reservations?.length === 0) {
      return routes.book.new.seat.details
    }
    return generateRoute(routes.book.new.meetingsRoom.details, [{ name: 'bookId', value: reservations[0]?.seat?.id }])
  }, [reservations])

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <Screen
      headerProps={{
        variant: VARIANTS.pig,
        title: 'Mis últimas reservas',
        backEnabled: true,
      }}
    >
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.header}>
            <Text size="ml" weight="600">
              Puestos
            </Text>
          </div>

          {reservations?.map((reservation) => {
            return <ListItem key={reservation.seat.id} reservation={reservation} />
          })}

          {reservations?.length === 0 && <EmptyState path={routes.book.new.seat.details} />}
        </div>

        {meetingRoomReservations?.length !== 0 && (
          <div className={styles.section}>
            <div className={styles.header}>
              <Text size="ml" weight="600">
                Sala de reuniones
              </Text>
            </div>

            {meetingRoomReservations?.map((reservation) => {
              return <MeetingRoomSlotListItem key={reservation.id} reservation={reservation} />
            })}

            {meetingRoomReservations?.length === 0 && <EmptyState path={emptyStatePath} />}
          </div>
        )}
      </div>
    </Screen>
  )
}

export default React.memo(ReservationsListScreen)
