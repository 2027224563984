import { DateTime } from 'luxon'
import { SHIFTS, IShift } from '../providers/bookAssistant/shifts'

export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (immediate && !timeout) func.apply(context, args)
  }
}

export const humanizeShift = (shift: Partial<IShift>) => {
  if (!shift) {
    return 'Unkown'
  }

  const match = Object.values(SHIFTS).find(({ from, to }) => shift.from === from && shift.to === to)
  if (!match) {
    return 'Unkown'
  }

  return match.shortLabel
}

export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
  const rawData = window.atob(base64)
  return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)))
}

export const isSameDay = (a, b) => {
  return a.hasSame(b, 'day') && a.hasSame(b, 'month') && a.hasSame(b, 'year')
}

export function nextWorkingDay(date) {
  let nextDay = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date)
  nextDay = nextDay.plus({ days: 1 })
  while (nextDay.isWeekend) {
    nextDay = nextDay.plus({ days: 1 })
  }

  return nextDay
}

export const IS_SAFARI = () => navigator.userAgent.indexOf('Safari') > -1

export type CardType = 'Visa' | 'Mastercard' | 'American Express' | 'Discover' | 'Unknown'

export function getCardType(cardNumber: string): CardType {
  if (!cardNumber) {
    return 'Unknown'
  }

  // Remove any non-digit characters
  const cleanedNumber = cardNumber.replace(/\D/g, '')

  // Check the first digit(s)
  if (/^4/.test(cleanedNumber)) {
    return 'Visa'
  } else if (/^(51|52|53|54|55)/.test(cleanedNumber)) {
    return 'Mastercard'
  } else if (/^(34|37)/.test(cleanedNumber)) {
    return 'American Express'
  } else if (/^6/.test(cleanedNumber)) {
    return 'Discover'
  } else if (
    /^(2221|2222|2223|2224|2225|2226|2227|2228|2229|223|224|225|226|227|228|229|23|24|25|26|270|271|2720)/.test(
      cleanedNumber,
    )
  ) {
    return 'Mastercard'
  } else {
    return 'Unknown'
  }
}

export function getCardTypeIcon(cardType: CardType) {
  switch (cardType) {
    case 'Visa':
      return 'visa'
    case 'Mastercard':
      return 'mastercard'
    case 'American Express':
      return 'amex'
    case 'Discover':
      return 'discover'
    default:
      return 'generic'
  }
}
