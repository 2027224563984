import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconWifi = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M14.994 21.64a2.599 2.599 0 00-2.6 2.591 2.6 2.6 0 002.6 2.592 2.6 2.6 0 002.6-2.592 2.6 2.6 0 00-2.6-2.591zm6.344-3.53a8.916 8.916 0 00-6.344-2.625 8.922 8.922 0 00-6.329 2.608 1.745 1.745 0 001.239 2.984c.467 0 .907-.182 1.237-.51a5.43 5.43 0 013.853-1.588c1.46 0 2.831.568 3.862 1.597.331.331.772.514 1.24.514a1.745 1.745 0 001.242-2.98zm8.15-8.123C25.618 6.126 20.47 4 14.994 4 9.524 4 4.382 6.12.513 9.972a1.744 1.744 0 001.239 2.982c.468 0 .908-.18 1.239-.51a16.904 16.904 0 0112.003-4.951c4.54 0 8.806 1.762 12.014 4.962.33.33.772.513 1.24.513a1.747 1.747 0 001.239-2.981z" />
        <path d="M25.31 14.15a14.51 14.51 0 00-10.316-4.262 14.51 14.51 0 00-10.302 4.247c-.331.33-.514.769-.514 1.235 0 .467.181.906.513 1.236.33.33.771.512 1.24.512.467 0 .907-.182 1.239-.51a11.022 11.022 0 017.824-3.227c2.96 0 5.742 1.15 7.835 3.238.331.33.772.513 1.24.513a1.747 1.747 0 001.241-2.982z" />
      </svg>
    </i>
  )
}

export default IconWifi
