import React from 'react'
import { useParams } from 'react-router-dom'
import BackButton from '../../../../../../components/atoms/backButton/BackButton'
import Text from '../../../../../../components/atoms/text/Text'
import InvoiceRow from '../../../../../../components/molecules/invoiceRow/InvoiceRow'
import LoadingData from '../../../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../../../components/molecules/screen/Screen'
import { useProfile } from '../../../../../../modules/auth/hooks'
import { useDownloadInvoiceMutation, useInvoices } from '../../../../../../modules/invoices/hooks'
import { VARIANTS } from '../../../../../../types/Components.types'
import styles from './InvoicesByYearScreen.module.css'
import EmptyState from '../../../../../../components/molecules/emptyState/EmptyState'
import { IInvoice } from '../../../../../../types/app.types'

const InvoicesByYearScreen = () => {
  const { year } = useParams<{ year: string }>()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: invoices, isLoading: isLoadingInvoices } = useInvoices(
    {
      userId: profile?.id,
      queryParams: new URLSearchParams({
        year,
      }),
    },
    {
      enabled: !!profile,
    },
  )
  const isLoading = isLoadingProfile || isLoadingInvoices

  const downloadInvoiceMutation = useDownloadInvoiceMutation()
  const handleDownloadInvoice = (invoice: IInvoice) => {
    downloadInvoiceMutation.mutate({ userId: profile!.id, paymentId: invoice.id })
  }

  if (isLoading) {
    return <LoadingData />
  }

  if (!profile) {
    return null
  }

  const hasInvoices = (invoices?.length ?? 0) > 0
  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            {`Facturas en el ${year}`}
          </Text>
        </div>

        <div className={styles.content}>
          {hasInvoices ? (
            invoices?.map((invoice) => {
              return (
                <InvoiceRow
                  headline={`${new Date(invoice.paidAt).toLocaleString('es-ES', { month: 'long' })} | ${
                    invoice.invoiceSerie
                  }-${invoice.invoiceNumber} `}
                  paidAt={invoice.paidAt}
                  onClick={() => handleDownloadInvoice(invoice)}
                />
              )
            })
          ) : (
            <EmptyState headline={`No tienes facturas en ${year}`} />
          )}
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(InvoicesByYearScreen)
