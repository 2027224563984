import React from 'react'
import Text from '../../../../components/atoms/text/Text'
import styles from './Coworking.module.css'
import place from './assets/place.jpg'
import video from './assets/video.mp4'
import { instrucciones, ordenanza, terms } from '../../../../config/index'

const Coworking = () => {
  return (
    <>
      <section className={styles.container}>
        <div className={styles.image}>
          <img src={place} alt="Coworking" />
        </div>

        <div className={styles.content}>
          <Text weight="500" size="xl" className={styles.title} transform="uppercase">
            El Espacio Coworking
          </Text>

          <Text size="ml" className={styles.p}>
            Se constituye como un espacio físico de titularidad pública municipal situado en la calle Infantes nº 4, en
            un edificio rehabilitado y dotado de forma específica para este fin. La inversión realizada íntegramente por
            el Ayuntamiento supera los 400.000 Euros.
          </Text>

          <Text size="ml" className={styles.p}>
            El edificio tiene una sola planta de unos 220 metros cuadrados. Consta de dos salas con un total de 35
            puestos de trabajo de 3 tipos diferentes, 8 de ellos para emprendedores becados. Tiene sala de reuniones
            para 8 personas, zona de office, 2 cabinas con reducción acústica, 21 taquillas y dos grandes terrazas
            exteriores. Dispone también de copiadora A4 y A3 (de gestión externa) en donde se puede escanear, copiar e
            imprimir desde el portátil. El edificio dispone de climatización aerotérmica de frío y calor y WiFi para los
            usuarios.
          </Text>

          <Text size="ml" className={styles.p}>
            El edificio es un lugar único. La reforma lo ha convertido un espacio luminoso, al eliminar las celosías que
            lo oscurecían. Tiene buenas vistas: en la fachada sur al parque y hacia las llanuras que cierra la ciudad de
            Madrid y a las montañas de la sierra en la fachada norte. Está integrado en una zona verde con gran
            tradición en San Lorenzo. A su alrededor y muy cerca, dispone de servicios como cafeterías, hoteles y
            restaurantes. En cuanto a comunicaciones, está a 2 minutos andando de la estación de autobuses. La zona es
            muy tranquila al tener una sola calle con circulación de coches (calle Cañada Nueva) con poca circulación
            durante casi todo el día. El Coworking está abierto de lunes a viernes no festivos de 8:00 a 21:00 horas.
          </Text>

          <Text size="ml" className={styles.p}>
            Los puestos se pueden alquilar por meses en toda la jornada, por meses en media jornada o por días sueltos
            en jornada completa. Hay disponible hay un bono de 10 días pudiendo elegir libremente los días sueltos. No
            se puede alquilar por horas. En alquileres por meses conservas el mismo sitio. La gestión del Coworking se
            realiza exclusivamente de forma electrónica por Internet a través de esta web.
          </Text>

          <Text size="ml" className={styles.p}>
            No se admiten mascotas.
          </Text>

          <Text size="ml" className={styles.p}>
            La gestión del Coworking se realiza íntegramente de forma electrónica por Internet a través de esta web.
          </Text>

          <Text size="ml" className={styles.p}>
            El departamento municipal responsable de la supervisión es el Centro de Difusión de la Innovación{' '}
            <a href="http://cdi.aytosanlorenzo.es" rel="noreferrer" target="_blank">
              http://cdi.aytosanlorenzo.es
            </a>{' '}
            Teléfono: 918901602{' '}
            <a href="mailto:cdi@aytosanlorenzo.es" rel="noreferrer" target="_blank">
              cdi@aytosanlorenzo.es
            </a>{' '}
            Puedes consultar las dudas que tengas. Los usuarios por meses pueden adherirse al Grupo de Usuarios del
            Coworking, GUCSL, formando parte del grupo de decisión de la gestión. A cambio tendrán algunas ventajas como
            se explica en la Ordenanza. La Ordenanza de gestión y funcionamiento fue publicada de forma definitiva en el{' '}
            <a href={ordenanza} rel="noreferrer" target="_blank">
              Boletín Oficial de la Comunidad de Madrid el 4 de Abril de 2023
            </a>
            .
          </Text>

          <div className={styles.actions}>
            <a className={styles.button} href={terms} rel="noreferrer" target="_blank">
              Consulta la normativa completa
            </a>

            <a className={styles.button} href={instrucciones} rel="noreferrer" target="_blank">
              Instrucciones de uso
            </a>
          </div>
        </div>
      </section>

      <section className={styles.container}>
        <div className={styles.content}>
          <Text weight="500" size="xl" className={styles.title} transform="uppercase">
            Breve historia del edificio
          </Text>

          <Text size="ml" className={styles.p}>
            En la primavera de 2018 el Ayuntamiento de San Lorenzo de Escorial decidió que era necesaria la
            Rehabilitación de este Pabellón.
          </Text>

          <Text size="ml" className={styles.p}>
            Optimizar el espacio, adecuarlo y transformarlo en un lugar habitable y eficiente, era el principal objetivo
            de la propuesta de OAOB Arquitectura, el estudio ganador del concurso para llevar a cabo esta
            Rehabilitación.
          </Text>

          <Text size="ml" className={styles.block}>
            Breve historia del Pabellón:
          </Text>

          <Text size="ml" className={styles.p}>
            Terminado en 1998, este edificio municipal fue sede de la OCRE durante un timepo, tras varios cambios de
            uso, y debido a la falta de compatibilidad entre la función para la que fue concebido (espacio no habitable
            que funcionaría como merendero), y los usos que terminaba albergando, el edificio terminó sirviendo
            simplemente como almacén.
          </Text>

          <Text size="ml" className={styles.p}>
            Las planchas de plomo de la cubierta llevaban tiempo amenazando con echar a volar, la madera acusaba la
            falta tratamiento, y los paños de vidrio simple susentados por las cuidadas carpinterías no sobrevivieron al
            vandalismo.
          </Text>

          <Text size="ml" className={styles.p}>
            El Proyecto de Rehabilitación y Transformación contempló la sustitución de su envolvente, pudiendo así
            acondicionar el espacio interior, convirtiendo el pabellón en un edificio de consumo y mantenimiento casi
            nulos; así como la transfromación de su espacio interior para albergar el uso de oficina-coworking, un
            espacio versátil que también podrá funcionar como sala de exposiciones los fines de semana.
          </Text>
        </div>

        <div className={styles.videoWrapper}>
          <video className={styles.video} src={video} controls />
        </div>
      </section>
    </>
  )
}

export default React.memo(Coworking)
