import React from 'react'
import BackButton from '../../../../../../components/atoms/backButton/BackButton'
import Text from '../../../../../../components/atoms/text/Text'
import LinkRow from '../../../../../../components/molecules/linkRow/LinkRow'
import LoadingData from '../../../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../../../components/molecules/screen/Screen'
import routes, { generateRoute } from '../../../../../../config/routes'
import { useProfile } from '../../../../../../modules/auth/hooks'
import { VARIANTS } from '../../../../../../types/Components.types'
import styles from './InvoicesHomeScreen.module.css'

const getYears = (startDate: Date, endDate: Date) => {
  const startYear = startDate.getFullYear()
  const endYear = endDate.getFullYear()
  const years = []
  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }
  return years
}

const InvoicesHomeScreen = () => {
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const isLoading = isLoadingProfile
  console.info(profile)

  if (isLoading) {
    return <LoadingData />
  }

  if (!profile) {
    return null
  }

  const years = getYears(new Date(profile.createdAt), new Date())
  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Mis facturas por año
          </Text>
        </div>

        <Text>
          Aquí puedes encontrar todas tus facturas agrupadas por año desde que creeaste la cuenta en Coworking San
          Lorenzo de El Escorial
        </Text>

        <div className={styles.content}>
          {years.map((year) => (
            <LinkRow
              key={year}
              className={styles.row}
              path={generateRoute(routes.settings.account.invoices.byYear, [{ name: 'year', value: year.toString() }])}
            >
              <Text weight={500}>{year}</Text>
            </LinkRow>
          ))}
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(InvoicesHomeScreen)
