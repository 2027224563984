import * as Sentry from '@sentry/react'
import { IUserPurchaseVoucher, IVoucher } from '../../types/app.types'
import { CoworkingQueryClient } from '../CoworkingQueryClient'
import VouchersAPI from './api'

export const getVouchers = async ({ queryParams }: { queryParams: URLSearchParams }): Promise<IVoucher[]> => {
  const response = await VouchersAPI.getVouchers({ queryParams })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return await response.json()
}

export const getPurchasedVouchers = async ({ userId }: { userId: string }): Promise<IUserPurchaseVoucher[]> => {
  const response = await VouchersAPI.getPurchasedVouchers({ userId })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return await response.json()
}

export const getPurchasedVoucher = async ({
  userId,
  voucherId,
}: {
  userId: string
  voucherId: string
}): Promise<IUserPurchaseVoucher> => {
  const response = await VouchersAPI.getPurchasedVoucher({ userId, voucherId })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return await response.json()
}

export const confirmPayment = async ({
  bank,
  paymentId,
  merchantParameters,
  signature,
}: {
  bank: string
  paymentId: string
  merchantParameters: string
  signature: string
}): Promise<void> => {
  const queryParams = new URLSearchParams({
    Ds_MerchantParameters: merchantParameters,
    Ds_Signature: signature,
    Ds_SignatureVersion: 'HMAC_SHA256_V1',
  })
  const response = await VouchersAPI.confirmPayment({ bank, paymentId, queryParams })
  if (response.status !== 200) {
    Sentry.captureException(new Error(response.status.toString()))
    throw new Error(response.status.toString())
  }
}

export const downloadInvoice = async ({
  userId,
  voucherId,
  paymentId,
}: {
  userId: string
  voucherId: string
  paymentId?: string
}) => {
  let response
  if (paymentId) {
    response = await VouchersAPI.downloadInvoice({ voucherId, userId, paymentId })
  } else {
    response = await VouchersAPI.downloadLastInvoice({ voucherId, userId })
  }

  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  const blob = await response.blob()
  const headers = response.headers
  const filename = headers.get('Content-Disposition')?.split('=')[1] ?? 'invoice.pdf'

  const downloadUrl = URL.createObjectURL(blob)
  const a: HTMLAnchorElement = document.createElement('a')
  document.body.appendChild(a)
  a.setAttribute('style', 'display: none')
  a.setAttribute('download', filename)
  a.href = downloadUrl
  a.click()
  document.body.removeChild(a)
}

export const payVoucher = async ({
  userId,
  voucherId,
}: {
  userId: string
  voucherId: string
}): Promise<IUserPurchaseVoucher> => {
  const response = await VouchersAPI.payVoucher({
    userId,
    voucherId,
    callback_ok: `${process.env.REACT_APP_RENEW_PAYMENT_OK_URL}`,
    callback_ko: `${process.env.REACT_APP_RENEW_PAYMENT_KO_URL}`,
  })

  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return await response.json()
}

export const renewVoucher = async ({ userId, voucherId }: { userId: string; voucherId: string }) => {
  const response = await VouchersAPI.renewVoucher({
    userId,
    voucherId,
    callback_ok: `${process.env.REACT_APP_RENEW_PAYMENT_OK_URL}`,
    callback_ko: `${process.env.REACT_APP_RENEW_PAYMENT_KO_URL}`,
  })

  if (response.status !== 200 && response.status !== 202) {
    throw new Error(response.status.toString())
  }

  const data = await response.json()
  await CoworkingQueryClient.setQueryData(['voucher', userId, voucherId, 'paid-data'], data)

  await CoworkingQueryClient.invalidateQueries({
    queryKey: ['purchased-vouchers', userId],
    exact: true,
  })
  return response.status
}

export const cancelAutoRenew = async ({ userId, voucherId }: { userId: string; voucherId: string }) => {
  const response = await VouchersAPI.cancelAutoRenew({ userId, voucherId })

  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return response.status
}

export const purchaseVoucher = async ({
  userId,
  voucherId,
  seatType,
  userSeatId,
  months,
  params,
}: {
  userId: string
  voucherId: string
  seatType: 'red' | 'yellow' | 'blue' | 'black'
  userSeatId: string
  months: number
  params: string | null
}): Promise<{ status: number; voucher: IUserPurchaseVoucher }> => {
  const response = await VouchersAPI.purchaseVoucher({
    userId,
    voucherId,
    seatType,
    userSeatId,
    months,
    callback_ok: `${process.env.REACT_APP_PAYMENT_OK_URL}/${params}`,
    callback_ko: `${process.env.REACT_APP_PAYMENT_KO_URL}/${params}`,
    reservationData: JSON.parse(atob(params ?? '{}')),
  })

  if (response.status !== 200 && response.status !== 202) {
    throw new Error(response.status.toString())
  }

  const voucher = await response.json()

  return { status: response.status, voucher }
}
