import FetchHelper from '../FetchHelper'

class AuthAPI {
  static validateAccount({ userId, queryParams }: { userId: string; queryParams: URLSearchParams }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/validation?${queryParams.toString()}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {}, isAuthenticated: false }),
    })
  }

  static login({ email, password }: { email: string; password: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('login', {
      method: 'POST',
      headers: generateHeaders({ headers: {}, isAuthenticated: false }),
      body: {
        email,
        password,
      },
    })
  }

  static refreshToken() {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('login/me', {
      headers: generateHeaders({ headers: {} }),
    })
  }

  static singup({ email, password }: { email: string; password: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('users', {
      method: 'POST',
      headers: generateHeaders({ headers: {}, isAuthenticated: false }),
      body: {
        email,
        password,
      },
    })
  }

  static resendValidationEmail({ userId }: { userId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/validation/resend/verification`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static forgotPassword({ email }: { email: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('users/password/forgot', {
      method: 'PUT',
      headers: generateHeaders({ headers: {}, isAuthenticated: false }),
      body: {
        email,
      },
    })
  }

  static recoverPassword({
    userId,
    nonce,
    token,
    password,
  }: {
    userId: string
    nonce: string
    token: string
    password: string
  }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('users/password/change', {
      method: 'PUT',
      headers: generateHeaders({ headers: {}, isAuthenticated: false }),
      body: {
        userId,
        nonce,
        token,
        password,
      },
    })
  }

  static updateProfile({ userId, profile }: { userId: string; profile: any }) {
    const { fetch, generateHeaders } = FetchHelper
    const formData = new FormData()
    Object.keys(profile).forEach((key) => {
      const value = profile[key]
      if (key === 'censo') {
        if (!(value instanceof Blob)) {
          return
        }
        formData.append('censo', value, (value as File).name)
      } else if (key === 'facephoto') {
        if (!(value instanceof Blob)) {
          return
        }
        formData.append('facephoto', value, (value as File).name)
      } else if (key === 'identityCard') {
        const { front, back } = value
        if (front instanceof Blob) {
          formData.append('dniphoto_front', front, (front as File).name)
        }

        if (back instanceof Blob) {
          formData.append('dniphoto_back', back, (back as File).name)
        }
      } else if (typeof value === 'object') {
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value)
      }
    })

    return fetch(`users/${userId}`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {}, useDefaultHeaders: false }),
      body: formData,
    })
  }

  static reportCrash({ error, errorInfo }: { error: Error; errorInfo: React.ErrorInfo }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('crash/report', {
      method: 'POST',
      headers: generateHeaders({ headers: {} }),
      body: { error, errorInfo },
    })
  }

  static removeCardId({ userId, cardId }: { userId: string; cardId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/paymentCards/${cardId}`, {
      method: 'DELETE',
      headers: generateHeaders({ headers: {} }),
    })
  }
}

export default AuthAPI
