import React from 'react'
import styles from './Header.module.css'
import c from 'classnames'
import BackButton from '../backButton/BackButton'
import Text from '../text/Text'
import { ReactComponent as HeaderDecorator } from './assets/header.svg'
import TouchableOpacity from '../touchableOpacity/TouchableOpacity'
import { VARIANTS } from '../../../types/Components.types'

interface IProps {
  className?: string
  variant?: VARIANTS
  backEnabled?: boolean
  title?: string
  action?: {
    icon: React.ReactNode
    onClick: () => void
  }
}

const Header = ({ variant, className, backEnabled = false, title = '', action }: IProps) => {
  return (
    <header className={c(styles.container, className)}>
      <HeaderDecorator className={c(styles.decorator, variant && styles[variant])} />
      <div className={styles.content}>
        {backEnabled && (
          <div className={styles.actionIconWrapper}>
            <BackButton variant={VARIANTS.white} />
          </div>
        )}
        {title && (
          <Text variant={VARIANTS.white} weight="600">
            {title}
          </Text>
        )}
        {action && (
          <div className={styles.rightActionWrapper}>
            <TouchableOpacity onClick={action.onClick}>{action.icon}</TouchableOpacity>
          </div>
        )}
      </div>
    </header>
  )
}

export default React.memo(Header)
