import React, { useCallback } from 'react'
import IconArrowLeft from '../../icons/IconArrowLeft'
import { useHistory, useLocation } from 'react-router-dom'
import TouchableOpacity from '../touchableOpacity/TouchableOpacity'
import { VARIANTS } from '../../../types/Components.types'
import { ILocationState } from '../../../types/app.types'

interface IProps {
  className?: string
  variant?: VARIANTS
}

const BackButton = ({ className, variant = VARIANTS.max }: IProps) => {
  const history = useHistory()
  const location = useLocation()

  const goBack = useCallback(() => {
    const state = location.state as ILocationState
    if (state?.goBack) {
      return history.push(state.goBack)
    }
    history.goBack()
  }, [history, location])

  return (
    <TouchableOpacity className={className} onClick={goBack}>
      <IconArrowLeft variant={variant} />
    </TouchableOpacity>
  )
}

export default React.memo(BackButton)
