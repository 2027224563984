import c from 'classnames'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Text from '../../../../../../components/atoms/text/Text'
import Avatar from '../../../../../../components/molecules/avatar/Avatar'
import routes, { generateRoute } from '../../../../../../config/routes'
import { IWorkmate } from '../../../../../../types/app.types'
import styles from './ListItem.module.css'

interface IProps {
  className?: string
  workmate: IWorkmate
}

const ListItem = ({ className, workmate }: IProps) => {
  const history = useHistory()
  const { seat, user } = workmate

  const goToWorkmateProfile = useCallback(() => {
    history.push(generateRoute(routes.book.workmate, [{ name: 'profileId', value: user.id }]), {
      workmate: user,
    })
  }, [history, user])

  return (
    <button className={c(styles.container, className)} onClick={goToWorkmateProfile}>
      <Avatar className={styles.avatar} resource={user?.facephoto} />
      <div className={styles.infoWrapper}>
        <Text weight="600">
          {user?.firstname} {user?.lastname}
        </Text>
        <Text size="sm">
          {seat && seat.name}
          {' / '}
          {`De ${DateTime.fromISO(seat.reserved_from).toFormat('HH:mm')} a ${DateTime.fromISO(
            seat.reserved_to,
          ).toFormat('HH:mm')}`}
        </Text>
      </div>
    </button>
  )
}

export default React.memo(ListItem)
