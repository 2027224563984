import React, { useCallback, useState, useEffect } from 'react'
import styles from './BookSeatPlaceScreen.module.css'
import { useHistory } from 'react-router-dom'
import c from 'classnames'
import { useBookAssistant } from '../../../../../providers/bookAssistant'
import { useSeatById, useSeats, useSeatsQueryParams } from '../../../../../modules/seats/hooks'
import { useCardTransition } from '../../../../../providers/cardTransition/cardTransition'
import Screen from '../../../../../components/molecules/screen/Screen'
import { VARIANTS } from '../../../../../types/Components.types'
import IconInfo from '../../../../../components/icons/IconInfo'
import Text from '../../../../../components/atoms/text/Text'
import Blueprint from '../../../../../components/molecules/blueprint/Blueprint'
import Loader from '../../../../../components/atoms/loader/Loader'
import Button from '../../../../../components/atoms/button/Button'
import SeatsHelp from '../../../../../components/molecules/seatsHelp/SeatsHelp'

const BookSeatPlaceScreen = () => {
  const { seat: seatId, setSeat } = useBookAssistant()
  const queryParams = useSeatsQueryParams()
  const { isLoading: isLoadingSeats } = useSeats({ queryParams })
  const { data: seat } = useSeatById({ seatId, queryParams })
  const history = useHistory()
  const { openCard } = useCardTransition()
  const [isFetching, setIsFetching] = useState(true)

  const handleContinueClick = useCallback(() => {
    history.goBack()
  }, [history])

  const handleSeatSelected = useCallback(
    (seatId: string) => {
      setSeat({ seat: seatId })
    },
    [setSeat],
  )

  useEffect(() => {
    setIsFetching(isLoadingSeats)
  }, [isLoadingSeats])

  return (
    <Screen
      headerProps={{
        backEnabled: true,
        variant: VARIANTS.flamingo,
        title: 'Nueva reserva',
        action: isFetching
          ? undefined
          : {
              icon: <IconInfo variant="white" />,
              onClick: () => openCard({ Component: <SeatsHelp /> }),
            },
      }}
    >
      <div className={styles.container}>
        <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
          Selecciona tu puesto de trabajo {seat?.name}
        </Text>

        <div className={c(styles.content, isFetching && styles.loading)}>
          {!isFetching && <Blueprint onSeatSelected={handleSeatSelected} />}
          {isFetching && <Loader variant={VARIANTS.primary} />}
        </div>

        <div className={styles.actions}>
          <Button variant={VARIANTS.primary} className={styles.action} onClick={handleContinueClick} disabled={!seat}>
            Continuar
          </Button>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(BookSeatPlaceScreen)
