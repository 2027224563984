import React, { useLayoutEffect, useRef } from 'react'
import c from 'classnames'
import styles from './CarouselItem.module.css'

const noop = () => {}

interface IProps {
  className?: string
  widthItem?: number
  heightItem?: number
  carouselIndex: number
  children: React.ReactNode
  leftPosition: number
  onRendered?: (data: { carouselIndex: number; width: number }) => void
}

const CarouselItem = ({
  className,
  widthItem,
  heightItem,
  carouselIndex,
  children,
  leftPosition,
  onRendered = noop,
}: IProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    if (!widthItem) {
      onRendered({ carouselIndex, width: ref.current?.offsetWidth })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthItem])

  return (
    <div
      ref={ref}
      className={c(styles.item, className)}
      style={{
        position: 'absolute',
        top: 0,
        left: `${leftPosition}px`,
        width: widthItem ? `${widthItem}px` : 'auto',
        height: heightItem ? `${heightItem}px` : 'auto',
        maxWidth: widthItem ? `${widthItem}px` : undefined,
        minWidth: widthItem ? `${widthItem}px` : undefined,
      }}
    >
      {children}
    </div>
  )
}

export default React.memo(CarouselItem)
