import c from 'classnames'
import { DateTime } from 'luxon'
import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../components/atoms/button/Button'
import Logo from '../../components/atoms/logo/Logo'
import Text from '../../components/atoms/text/Text'
import routes from '../../config/routes'
import { VARIANTS } from '../../types/Components.types'
import { IS_SAFARI } from '../../utils'
import styles from './LandingScreen.module.css'
import { ReactComponent as BackgroundDecorator } from './assets/background_decorator.svg'
import coworking from './assets/coworking.jpg'
import Cancellations from './components/cancellations/Cancellations'
import Contact from './components/contact/Contact'
import Cookies from './components/cookies/Cookies'
import Coworking from './components/coworking/Coworking'
import GDPR from './components/gdpr/GDPR'
import Highlights from './components/highlights/Highlights'
import Prices from './components/prices/Prices'
import PromotionalSlider from './components/promotionalSlider/PromotionalSlider'
import Timetable from './components/timetable/Timetable'

const LandingScreen = ({ ...rest }) => {
  const history = useHistory()
  const isOpened = useRef(
    DateTime.fromObject({ year: 2021, month: 6, day: 17, hour: 9 }).diffNow('hours').hours <= 0,
  ).current

  const goToApp = () => {
    history.push(routes.app)
  }

  return (
    <div className={styles.container}>
      <section className={styles.mainSection}>
        <BackgroundDecorator
          className={c(styles.backgroundDecorator, IS_SAFARI() && styles.backgroundDecoratorSafari)}
        />
        <div className={styles.welcomeContainer}>
          <div className={styles.welcomeWrapper}>
            <Logo className={styles.logo} size="l" />

            <Text weight="700" size="xxl" className={styles.title}>
              Bienvenido al coworking de San Lorenzo de El Escorial
            </Text>

            <Text className={styles.p}>El Coworking fue inaugurado el 25 de mayo 2021</Text>

            <Text className={styles.p}>
              ¡Ven a conocerlo!: pide cita con el Centro de Difusión de la Innovación, teléfono 918901602 o correo:
              <a href="mailto:cdi@aytosanlorenzo.es" rel="noreferrer" target="_blank">
                cdi@aytosanlorenzo.es
              </a>
            </Text>

            {isOpened && (
              <Button variant={VARIANTS.primary} onClick={goToApp} className={styles.cta}>
                Reserva tu sitio
              </Button>
            )}
          </div>

          <img src={coworking} alt="Edificio del Coworking de San Lorenzo del Escorial" className={styles.image} />
        </div>
      </section>

      <section className={styles.intro}>
        <Text align="center" size="ml">
          El Ayuntamiento de San Lorenzo de El Escorial pone a tu disposición el Coworking Municipal, un edificio recién
          renovado en el Parque Felipe II (
          <a href="https://goo.gl/maps/5qm5AMN8RoGunV4j7" rel="noreferrer" target="_blank">
            Calle Infantes 4
          </a>
          ) en el que trabajar en un entorno ideal
        </Text>
      </section>

      <Highlights isOpened={isOpened} />

      <Coworking />

      <PromotionalSlider />

      <Timetable />

      <Prices />

      <Cookies />

      <Cancellations />

      <GDPR />

      <Contact />
    </div>
  )
}

export default React.memo(LandingScreen)
