import React, { useCallback, useState } from 'react'
import styles from './EmailValidationPendingScreen.module.css'
import { ReactComponent as CompleteIllustration } from './assets/illustration.svg'
import Screen from '../../../../../components/molecules/screen/Screen'
import Text from '../../../../../components/atoms/text/Text'
import Button from '../../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../../types/Components.types'
import { useProfile, useResendValidationEmailMutation } from '../../../../../modules/auth/hooks'
import LoadingData from '../../../../../components/molecules/loadingData/LoadingData'

const EmailValidationPendingScreen = () => {
  const [resent, setResent] = useState(false)
  const { data: profile, isLoading } = useProfile()
  const resendEmail = useResendValidationEmailMutation()
  const userId = profile?.id

  const handleResendClick = useCallback(() => {
    if (!userId) {
      return
    }

    resendEmail.mutate(
      { userId },
      {
        onSuccess: () => {
          setResent(true)
        },
      },
    )
  }, [resendEmail, userId])

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <CompleteIllustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title} align="center">
            ¡Oops!
          </Text>
          <Text className={styles.description} align="center">
            Tienes que validar tu cuenta de correo electrónico antes de continuar
          </Text>
        </div>
        <div className={styles.resendWrapper}>
          {!resent && (
            <Button onClick={handleResendClick} className={styles.resendButton}>
              <Text weight="500" size="ml">
                Envíamelo otra vez
              </Text>
            </Button>
          )}

          {resent && (
            <Text weight="500" size="ml">
              ¡Listo! Revisa la bandeja de SPAM
            </Text>
          )}
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(EmailValidationPendingScreen)
