import React from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../../../../components/atoms/loader/Loader'
import Screen from '../../../../components/molecules/screen/Screen'
import { useProfile } from '../../../../modules/auth/hooks'
import { useReservationWorkmates } from '../../../../modules/seats/hooks'
import { IWorkmate } from '../../../../types/app.types'
import { VARIANTS } from '../../../../types/Components.types'
import EmptyState from './components/emptyState/EmptyState'
import ListItem from './components/listItem/ListItem'
import styles from './ReservationWorkmatesScreen.module.css'

interface IParams {
  bookId: string
}

const ReservationWorkmatesScreen = ({ ...rest }) => {
  const { bookId } = useParams<IParams>()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data, isLoading: isLoadingWorkmates } = useReservationWorkmates({ userId: profile?.id, seatId: bookId })

  const isLoading = isLoadingProfile || isLoadingWorkmates

  const workmates = React.useMemo(() => {
    if (!data) {
      return []
    }
    const sanitizedWorkmates: IWorkmate[] = []
    data.forEach(({ Users_Seats = [], ...seat }) => {
      Users_Seats.forEach(({ User, reserved_from, reserved_to }) => {
        sanitizedWorkmates.push({
          user: User,
          seat: {
            ...seat,
            reserved_from,
            reserved_to,
          },
        })
      })
    })
    return sanitizedWorkmates
  }, [data])

  return (
    <Screen
      headerProps={{
        variant: VARIANTS.pig,
        title: 'Compañeros',
        backEnabled: true,
      }}
    >
      <div className={styles.container}>
        {!isLoading &&
          workmates.map((workmate) => {
            return <ListItem key={workmate.user.id} workmate={workmate} />
          })}
        {!isLoading && workmates.length === 0 && <EmptyState className={styles.emptyState} />}

        {isLoading && (
          <div className={styles.loadingWrapper}>
            <Loader variant="primary" />
          </div>
        )}
      </div>
    </Screen>
  )
}

export default React.memo(ReservationWorkmatesScreen)
