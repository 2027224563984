import React from 'react'
import styles from './Link.module.css'
import { Link as RRLink } from 'react-router-dom'
import c from 'classnames'

interface IProps {
  className?: string
  children: React.ReactNode
  path: string
  replace?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const Link = ({ className, children, path, ...rest }: IProps) => {
  return (
    <RRLink to={path} className={c(styles.link, className)} {...rest}>
      {children}
    </RRLink>
  )
}

export default React.memo(Link)
