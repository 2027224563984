import React from 'react'
import styles from './FormField.module.css'
import c from 'classnames'
import Text from '../../atoms/text/Text'
import { VARIANTS } from '../../../types/Components.types'

interface IProps {
  field: React.ReactNode
  error?: string | undefined | null
  size?: 'medium' | 'large' | 'small' | 'xsmall'
  className?: string
}

const FormField = ({ field, error, size, className }: IProps) => {
  return (
    <div className={c(styles.container, size && styles[size], className)}>
      {field}
      {error && (
        <Text variant={VARIANTS.negative} element="span" className={styles.error}>
          {error}
        </Text>
      )}
    </div>
  )
}

export default React.memo(FormField)
