interface IGenerateHeadersOptions {
  headers?: any
  isAuthenticated?: boolean
  useDefaultHeaders?: boolean
}

interface ISetAuthTokenOptions {
  authToken: string | null
}

interface IFetchOptions {
  body?: any
  headers?: any
  externalUrl?: boolean
  [key: string]: any
}

class FetchHelper {
  _authtoken: string | null = null

  setAuthToken = ({ authToken }: ISetAuthTokenOptions) => {
    this._authtoken = authToken
  }

  generateHeaders = ({ headers = {}, isAuthenticated = true, useDefaultHeaders = true }: IGenerateHeadersOptions) => {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'x-language': 'es',
    }
    let generatedHeaders: any = {}

    if (useDefaultHeaders) {
      generatedHeaders = {
        ...defaultHeaders,
      }
    }

    generatedHeaders = {
      ...generatedHeaders,
      ...headers,
    }

    if (isAuthenticated) {
      generatedHeaders['authorization'] = `Bearer ${this._authtoken}`
    }

    return generatedHeaders
  }

  fetch = (endpoint: string, { body = null, headers = {}, externalUrl = false, ...rest }: IFetchOptions) => {
    const contentType = headers['content-type'] || headers['Content-Type']
    return fetch(externalUrl ? endpoint : new URL(endpoint, process.env.REACT_APP_BASE_HOST).href, {
      ...rest,
      headers,
      body: body && contentType === 'application/json' ? JSON.stringify(body) : body,
    })
  }
}

export default new FetchHelper()
