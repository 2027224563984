import React from 'react'
import styles from './Row.module.css'
import c from 'classnames'
import Text from '../../../../../components/atoms/text/Text'

interface IProps {
  className?: string
  label: string
  info?: string
}

const Row = ({ className, label, info = 'N/A' }: IProps) => {
  return (
    <div className={c(styles.container, className)}>
      <Text weight="700" size="sm" className={styles.label}>
        {label}
      </Text>
      <Text>{info ? info : 'N/A'}</Text>
    </div>
  )
}

export default React.memo(Row)
