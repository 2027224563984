import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconCamera = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M25.94 27.355c2.689 0 4.06-1.358 4.06-4.019v-13.32c0-2.648-1.371-4.005-4.06-4.005h-2.93c-.994 0-1.303-.188-1.894-.834l-1.022-1.115C19.45 3.362 18.804 3 17.5 3h-5.067c-1.304 0-1.963.363-2.608 1.062L8.804 5.177c-.578.646-.9.834-1.895.834h-2.85C1.371 6.01 0 7.368 0 10.016v13.32c0 2.661 1.371 4.019 4.06 4.019h21.88zm-.066-2.218H4.14c-1.223 0-1.922-.659-1.922-1.949V10.164c0-1.29.699-1.936 1.922-1.936H7.5c1.142 0 1.747-.215 2.392-.927l.982-1.089c.725-.806 1.102-1.008 2.217-1.008h3.737c1.116 0 1.492.202 2.218.995L20.04 7.3c.632.712 1.237.927 2.38.927h3.454c1.223 0 1.922.646 1.922 1.936v13.024c0 1.29-.7 1.95-1.922 1.95zM15 23.161a6.895 6.895 0 006.909-6.935A6.884 6.884 0 0015 9.304a6.892 6.892 0 00-6.909 6.922A6.895 6.895 0 0015 23.16zm9.086-9.946c.874 0 1.586-.712 1.586-1.586a1.58 1.58 0 00-1.586-1.586 1.58 1.58 0 00-1.586 1.586c0 .874.712 1.586 1.586 1.586zM15 21.105a4.867 4.867 0 01-4.866-4.88A4.856 4.856 0 0115 11.36a4.856 4.856 0 014.866 4.866A4.859 4.859 0 0115 21.105z" />
      </svg>
    </i>
  )
}

export default IconCamera
