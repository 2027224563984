import FetchHelper from '../FetchHelper'

class NotificationsAPI {
  static getVAPIDKey() {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('push/vapid', {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static addPushToken({ token }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('push/token', {
      method: 'POST',
      headers: generateHeaders({ headers: {} }),
      body: {
        token,
        platform: 'webpush',
      },
    })
  }
}

export default NotificationsAPI
