import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../../../../components/atoms/loader/Loader'
import Text from '../../../../components/atoms/text/Text'
import Screen from '../../../../components/molecules/screen/Screen'
import { useProfile } from '../../../../modules/auth/hooks'
import { usePurchasedVoucherWithPaidData } from '../../../../modules/vouchers/hooks'
import styles from './ReservationPaymentScreen.module.css'

interface IParams {
  voucherId: string
}

const ReservationPaymentScreen = () => {
  const form = useRef<HTMLFormElement>(null)
  const { voucherId } = useParams<IParams>()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: voucher, isLoading: isLoadingPaidData } = usePurchasedVoucherWithPaidData({
    userId: profile?.id,
    voucherId,
  })

  const isLoading = isLoadingProfile || isLoadingPaidData
  const paidData = voucher?.paidData?.[0]

  useEffect(() => {
    if (!isLoading && paidData) {
      form.current?.submit()
    }
  }, [isLoading, paidData])

  return (
    <Screen>
      <form ref={form} className={styles.container} action={paidData?.paymentUri} method="POST">
        <input hidden type="text" name="Ds_SignatureVersion" defaultValue="HMAC_SHA256_V1" />
        <input
          hidden
          type="text"
          id="Ds_MerchantParameters"
          name="Ds_MerchantParameters"
          defaultValue={paidData?.merchantParameters}
        />
        <input hidden type="text" id="Ds_Signature" name="Ds_Signature" defaultValue={paidData?.signature} />

        <Text className={styles.message}>Conectando con la pasarela de pago</Text>

        <Loader variant="primary" />
      </form>
    </Screen>
  )
}

export default React.memo(ReservationPaymentScreen)
