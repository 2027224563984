import { useFormik } from 'formik'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/atoms/button/Button'
import Input from '../../../../components/atoms/input/Input'
import FormField from '../../../../components/molecules/formField/FormField'
import Screen from '../../../../components/molecules/screen/Screen'
import { useProfile, useUpdateProfileMutation } from '../../../../modules/auth/hooks'
import { VARIANTS } from '../../../../types/Components.types'
import styles from './EditProfileScreen.module.css'
import profileFormSchema from './schema/profile'

const EditProfileScreen = () => {
  const { data: profile } = useProfile()
  const updateProfile = useUpdateProfileMutation()

  const history = useHistory()

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting } = useFormik({
    initialValues: {
      firstname: profile!.firstname,
      lastname: profile!.lastname,
      profession: profile!.profession,
      linkedIn: profile!.linkedIn,
      facebook: profile!.facebook,
      twitter: profile!.twitter,
      address: {
        street: '',
        postalCode: '',
        state: '',
        locality: '',
        country: '',
        ...(profile!.address || {}),
      },
    },
    validationSchema: profileFormSchema,
    onSubmit: (values) => {
      return new Promise<void>((resolve, reject) => {
        updateProfile.mutate(
          { userId: profile!.id, ...values },
          {
            onSuccess: () => {
              resolve()
              goBack()
            },
            onError: () => {
              reject()
            },
          },
        )
      })
    },
  })

  return (
    <Screen
      headerProps={{
        variant: VARIANTS.parrot,
        title: 'Mi perfil',
        backEnabled: true,
      }}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.container}>
          <FormField
            field={
              <Input
                name="firstname"
                placeholder="Nombre"
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched.firstname ? errors.firstname : null}
          />

          <FormField
            field={
              <Input
                name="lastname"
                placeholder="Apellidos"
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched.lastname ? errors.lastname : null}
          />

          <FormField
            field={
              <Input
                name="address.street"
                placeholder="Dirección"
                value={values.address.street}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched.address?.street ? errors.address?.street : null}
          />

          <FormField
            field={
              <Input
                name="address.postalCode"
                placeholder="Código postal"
                value={values.address.postalCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched.address?.postalCode ? errors.address?.postalCode : null}
          />

          <FormField
            field={
              <Input
                name="address.state"
                placeholder="Provincia"
                value={values.address.state}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched.address?.state ? errors.address?.state : null}
          />

          <FormField
            field={
              <Input
                name="address.locality"
                placeholder="Localidad"
                value={values.address.locality}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched.address?.locality ? errors.address?.locality : null}
          />

          <FormField
            field={
              <Input
                name="address.country"
                placeholder="País"
                value={values.address.country}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched.address?.country ? errors.address?.country : null}
          />

          <FormField
            field={
              <Input
                name="profession"
                placeholder="Mi profesión"
                value={values.profession}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched.profession ? errors.profession : null}
          />

          <FormField
            field={
              <Input
                name="linkedIn"
                placeholder="Linked-in"
                value={values.linkedIn}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched.linkedIn ? errors.linkedIn : null}
          />

          <FormField
            field={
              <Input
                name="facebook"
                placeholder="Facebook"
                value={values.facebook}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched?.facebook ? errors?.facebook : null}
          />

          <FormField
            field={
              <Input
                name="twitter"
                placeholder="Twitter"
                value={values.twitter}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched?.twitter ? errors?.twitter : null}
          />
        </div>
        <div className={styles.actions}>
          <Button variant={VARIANTS.primary} className={styles.action} type="submit" isLoading={isSubmitting}>
            Guardar
          </Button>
        </div>
      </form>
    </Screen>
  )
}

export default React.memo(EditProfileScreen)
