import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconDecrement = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M27.61 13H2.39C1.07 13 0 14.117 0 15.5S1.07 18 2.39 18h25.22c1.32 0 2.39-1.117 2.39-2.5S28.93 13 27.61 13z" />
      </svg>
    </i>
  )
}

export default IconDecrement
