import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconChevronRight = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M7.722 30c.49 0 .888-.181 1.215-.49L22.44 16.297c.38-.362.58-.798.58-1.287 0-.49-.2-.96-.562-1.305L8.955.508A1.696 1.696 0 007.722 0 1.71 1.71 0 006 1.722c0 .471.2.906.508 1.233l12.326 12.054L6.508 27.045A1.805 1.805 0 006 28.278C6 29.257 6.761 30 7.722 30z" />
      </svg>
    </i>
  )
}

export default IconChevronRight
