import React, { useRef, useCallback, useEffect } from 'react'
import styles from './Input.module.css'
import c from 'classnames'
import Text from '../text/Text'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string
  className?: string
  wrapperClass?: string
  invalid?: boolean
  icon?: React.ReactNode
  cleanInput?: boolean
  onInputClean?: () => void
  variant?: 'default' | 'light'
  toUpperCase?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  autoFocus?: boolean
  placeholder?: string
  [key: string]: any
}

const Input = ({
  value,
  type = 'text',
  name,
  className,
  wrapperClass,
  invalid,
  icon,
  cleanInput = false,
  onInputClean,
  variant = 'default',
  toUpperCase = false,
  onChange,
  autoFocus,
  placeholder,
  ...rest
}: IProps) => {
  const inputElement = useRef<HTMLInputElement>(null)

  const focusInput = useCallback(() => {
    inputElement?.current?.focus()
  }, [])

  useEffect(() => {
    if (!inputElement.current) {
      return
    }

    autoFocus && focusInput()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleIconClick = useCallback(() => {
    cleanInput ? onInputClean && onInputClean() : focusInput()
  }, [cleanInput, focusInput, onInputClean])

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (toUpperCase) {
        evt.target.value = ('' + evt.target.value).toUpperCase()
      }
      onChange?.(evt)
    },
    [onChange, toUpperCase],
  )

  return (
    <div className={c(styles.wrapper, wrapperClass)}>
      <Text weight="500" className={styles.placeholder}>
        {placeholder}
      </Text>
      <input
        ref={inputElement}
        className={c(styles.input, className, invalid && styles.error, icon && styles.inputWithIcon, styles[variant])}
        name={name}
        value={value}
        type={type}
        onChange={handleChange}
        {...rest}
      />
      {icon && (
        <span className={styles.iconWrapper} onClick={handleIconClick}>
          {icon}
        </span>
      )}
    </div>
  )
}

export default React.memo(Input)
