import React, { useCallback } from 'react'
import styles from './PrivacyScreen.module.css'
import { useProfile, useUpdateProfileMutation } from '../../../../../modules/auth/hooks'
import Screen from '../../../../../components/molecules/screen/Screen'
import BackButton from '../../../../../components/atoms/backButton/BackButton'
import Text from '../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../types/Components.types'
import SwitchRow from '../../components/switchRow/SwitchRow'

type PrivacyKey = 'publicEMail' | 'publicPhone' | 'publicFacebook' | 'publicTwitter' | 'publicLinkedIn'

const PrivacyScreen = () => {
  const { data: profile } = useProfile()
  const updateProfileMutation = useUpdateProfileMutation()

  const handlePrivacyChange = useCallback(
    (privacyKey: PrivacyKey, value: boolean) => {
      profile![privacyKey] = value
      const toUpdate = {
        userId: profile!.id,
        [privacyKey]: value,
      }
      updateProfileMutation.mutate(toUpdate)
    },
    [profile, updateProfileMutation],
  )

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Ajustes de privacidad
          </Text>
        </div>

        <Text>Aquí puedes configurar los datos que pueden ver el resto de compañeros del coworking sobre ti</Text>

        <div className={styles.content}>
          <SwitchRow
            name="publicEMail"
            checked={profile?.publicEMail ?? false}
            onChange={(isChecked) => handlePrivacyChange('publicEMail', isChecked)}
          >
            Email
          </SwitchRow>

          <SwitchRow
            name="publicPhone"
            checked={profile?.publicPhone ?? false}
            onChange={(isChecked) => handlePrivacyChange('publicPhone', isChecked)}
          >
            Teléfono
          </SwitchRow>

          <SwitchRow
            name="publicFacebook"
            checked={profile?.publicFacebook ?? false}
            onChange={(isChecked) => handlePrivacyChange('publicFacebook', isChecked)}
          >
            Facebook
          </SwitchRow>

          <SwitchRow
            name="publicTwitter"
            checked={profile?.publicTwitter ?? false}
            onChange={(isChecked) => handlePrivacyChange('publicTwitter', isChecked)}
          >
            Twitter
          </SwitchRow>

          <SwitchRow
            name="publicLinkedIn"
            checked={profile?.publicLinkedIn ?? false}
            onChange={(isChecked) => handlePrivacyChange('publicLinkedIn', isChecked)}
          >
            Linked-In
          </SwitchRow>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(PrivacyScreen)
