import React, { useCallback, useState } from 'react'
import styles from './BookSeatConfirmationScreen.module.css'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Illustration } from './assets/illustration.svg'
import { useReservateSeatMutation, useSeatById, useSeatsQueryParams } from '../../../../../modules/seats/hooks'
import routes from '../../../../../config/routes'
import Text from '../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../types/Components.types'
import BookCard from '../../../../../components/molecules/bookCard/BookCard'
import Button from '../../../../../components/atoms/button/Button'
import Screen from '../../../../../components/molecules/screen/Screen'
import { useBookAssistant } from '../../../../../providers/bookAssistant'
import { useProfile } from '../../../../../modules/auth/hooks'
import LoadingData from '../../../../../components/molecules/loadingData/LoadingData'

const BookSeatConfirmationScreen = () => {
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { type, seat: seatId, day, shift, months, clear } = useBookAssistant()
  const queryParams = useSeatsQueryParams()
  const { data: seat, isLoading: isLoadingSeat } = useSeatById({ seatId, queryParams })
  const history = useHistory()
  const [isWorking, setIsWorking] = useState(false)

  const isLoading = isLoadingSeat || isLoadingProfile

  const reservateMutation = useReservateSeatMutation()

  const handleContinueClick = useCallback(() => {
    setIsWorking(true)
    reservateMutation.mutate(
      { userId: profile!.id, type, seatId: seatId!, seatType: seat!.type, date: day!, shift, months },
      {
        onSuccess: ({ status, params }) => {
          setIsWorking(false)
          switch (status) {
            case 402:
              history.push(`${routes.book.vouchers}?b=${params}`)
              return

            default:
              history.replace(routes.book.new.seat.success)
              break
          }
        },
        onError: (status) => {
          setIsWorking(false)
        },
      },
    )
  }, [reservateMutation, profile, type, seatId, seat, day, shift, months, history])

  const handleCancelClick = useCallback(() => {
    history.push(routes.home)
    clear?.()
  }, [history, clear])

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <Screen
      headerProps={{
        backEnabled: true,
        variant: VARIANTS.flamingo,
        title: 'Confirmación',
      }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <Illustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
            ¿Todo en orden?
          </Text>
          <Text align="center">Confirma que los datos de tu reserva son los correctos antes de continuar</Text>

          <BookCard
            className={styles.card}
            seat={seat?.name ?? ''}
            type={type}
            day={day!}
            shift={shift}
            months={months}
          />
        </div>

        <div className={styles.actions}>
          <Button inverted variant={VARIANTS.max} className={styles.action} onClick={handleCancelClick}>
            Cancelar
          </Button>

          <Button
            variant={VARIANTS.primary}
            className={styles.action}
            onClick={handleContinueClick}
            disabled={!seat || !day}
            isLoading={isWorking}
          >
            Continuar
          </Button>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(BookSeatConfirmationScreen)
