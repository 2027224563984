import React, { useCallback, useEffect, useState, useRef } from 'react'
import styles from './Ok.module.css'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { ReactComponent as PaymentCompleteIllustration } from './assets/payment_complete_illustration.svg'
import { useBookAssistant } from '../../../../providers/bookAssistant'
import { TYPES } from '../../../../providers/bookAssistant/types'
import routes, { generateRoute } from '../../../../config/routes'
import Screen from '../../../../components/molecules/screen/Screen'
import Text from '../../../../components/atoms/text/Text'
import Loader from '../../../../components/atoms/loader/Loader'
import Button from '../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../types/Components.types'
import { useConfirmPaymentMutation } from '../../../../modules/vouchers/hooks'
import { CoworkingQueryClient } from '../../../../modules/CoworkingQueryClient'

interface IParams {
  bank: string
  paymentId: string
  book: string
}

const Ok = () => {
  const { bank, paymentId, book } = useParams<IParams>()
  const history = useHistory()
  const location = useLocation()
  const { setData } = useBookAssistant()
  const [isWorking, setIsWorking] = useState(true)
  const queryParams = useRef(new URLSearchParams(location.search)).current
  const { mutate: confirmPayment } = useConfirmPaymentMutation()

  const handleContinueClick = useCallback(() => {
    const params = JSON.parse(atob(book))

    switch (params.type) {
      case TYPES.MONTH:
      case TYPES.DAY:
        history.replace(routes.book.new.seat.confirmation, { goBack: routes.app })
        return

      default:
        history.replace(generateRoute(routes.book.new.meetingsRoom.details, [{ name: 'bookId', value: params.bookId }]))
    }
  }, [history, book])

  useEffect(() => {
    confirmPayment(
      {
        bank,
        paymentId,
        merchantParameters: queryParams.get('Ds_MerchantParameters')!,
        signature: queryParams.get('Ds_Signature')!,
      },
      {
        onSuccess: () => {
          const params = JSON.parse(atob(book))
          params.date = new Date(params.date)
          setData({ data: params })
          setIsWorking(false)
          CoworkingQueryClient.invalidateQueries(['reservated-seats'])
        },
        onError: () => {
          setIsWorking(false)
        },
      },
    )
  }, [bank, paymentId, queryParams, book, confirmPayment, setData])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <PaymentCompleteIllustration />
          {!isWorking && (
            <>
              <Text size="l" align="center" variant={VARIANTS.primary} weight="700" className={styles.title}>
                ¡Listo!
              </Text>
              <Text className={styles.description} align="center">
                El pago de tu bono se ha realizado correctamente. ¡Ya puedes reservar tu sitio!
              </Text>
            </>
          )}
          {isWorking && <Loader className={styles.loader} variant="primary" />}
        </div>

        {!isWorking && (
          <Button variant={VARIANTS.primary} className={styles.action} onClick={handleContinueClick}>
            ¡Vamos!
          </Button>
        )}
      </div>
    </Screen>
  )
}

export default React.memo(Ok)
