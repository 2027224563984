import { IMeetingRoomHour } from '../../types/app.types'
import { IUser } from './types'

export const isBillingAddressComplete = ({ user }: { user?: IUser }) => {
  return (
    user &&
    user.empresaDireccion &&
    user.empresaDireccion.street &&
    user.empresaDireccion.postalCode &&
    user.empresaDireccion.state &&
    user.empresaDireccion.locality &&
    user.empresaDireccion.country
  )
}

export const getGUCSLAvailableHours = ({ hours }: { hours: IMeetingRoomHour[] }) => {
  return hours.find((hour) => !!hour.isGUCSLOffer && !hour.isConsumed)
}

export const isProfileCompleted = ({ user }: { user?: IUser }) => {
  return user && user.firstname && user.lastname && user.phone && user.profession && user.dni && user.facephoto
}
