import React from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './CardTransition.module.css'
import { useCardTransition } from '..'
import IconClose from '../../../../components/icons/IconClose'
import TouchableOpacity from '../../../../components/atoms/touchableOpacity/TouchableOpacity'

interface IProps {
  shown: boolean
  children: React.ReactNode
}

const CardTransition = ({ shown, children }: IProps) => {
  const { closeCard } = useCardTransition()

  return (
    <CSSTransition in={shown} timeout={300} classNames="slideUp" unmountOnExit>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <TouchableOpacity onClick={closeCard}>
              <IconClose variant="max" size="s" />
            </TouchableOpacity>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default React.memo(CardTransition)
