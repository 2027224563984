import { useFormik } from 'formik'
import { forwardRef, useCallback, useImperativeHandle } from 'react'
import Text from '../../../../../../components/atoms/text/Text'
import ListItem from '../../../../../../components/molecules/listItem/ListItem'
import { useProfileAssistant } from '../../../../../../providers/profileAssistant'
import { VARIANTS } from '../../../../../../types/Components.types'
import { IStepProps, IStepRef } from '../types'
import UploadFile from './components/uploadFile/UploadFile'
import styles from './PadronStep.module.css'
import censoFormSchema from './schema/censo'

const PadronStep = forwardRef<IStepRef, IStepProps>(({ onSubmit }, ref) => {
  const { censo, setCenso } = useProfileAssistant()

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      censo,
    },
    validationSchema: censoFormSchema,
    onSubmit: async (values) => {
      return new Promise<void>((resolve) => {
        setCenso({ censo: values.censo! })
        resolve()
        onSubmit?.()
      })
    },
  })

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }))

  const handleCensoChange = useCallback(
    (files: File[]) => {
      setFieldValue('censo', files[0])
    },
    [setFieldValue],
  )

  return (
    <div className={styles.container}>
      <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
        ¿Estás empadronado?
      </Text>

      <Text className={styles.description}>
        Si la respuesta es sí, ¡estás de suerte! El coworking ofrece descuentos a los usuarios empadronados en San
        Lorenzo de El Escorial.
      </Text>

      <Text className={styles.description}>
        Pero si tienes docimicilio de actividad o fiscal en San Lorenzo, añade la declaración censal para que lo
        verifiquemos y, ¡poder disfrutar de los descuentos!
      </Text>

      <form className={styles.content} onSubmit={handleSubmit}>
        <div className={styles.section}>
          <ListItem label="Añade tu declaración censal">
            <UploadFile files={values.censo ? [values.censo] : []} onChange={handleCensoChange} />
          </ListItem>
        </div>
      </form>
    </div>
  )
})

export default PadronStep
