import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconArrowLeft = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M12.285 26.52c.865 0 1.51-.629 1.51-1.494 0-.424-.152-.814-.424-1.086l-3.597-3.665-5.176-4.7 3.852.237h20.006c.916 0 1.544-.645 1.544-1.544 0-.916-.628-1.561-1.544-1.561H8.45l-3.835.238 5.159-4.7 3.597-3.666c.272-.271.424-.662.424-1.086 0-.865-.645-1.493-1.51-1.493-.407 0-.78.153-1.154.509L.526 13.097c-.34.323-.526.73-.526 1.171 0 .424.187.832.526 1.154l10.64 10.622c.339.323.712.475 1.12.475z" />
      </svg>
    </i>
  )
}

export default IconArrowLeft
