import React from 'react'
import styles from './AspectRatio.module.css'
import c from 'classnames'

interface IProps {
  ratio?: '16/9' | '4/3' | 1
  children: React.ReactNode
  className?: string
}

const ASPECT_RATIOS = {
  '16/9': 'ratio16_9',
  '4/3': 'ratio4_3',
  1: 'ratio1_1',
}

const AspectRatio = ({ ratio = '16/9', children, className, ...rest }: IProps) => {
  return (
    <div className={c(styles.container, styles[ASPECT_RATIOS[ratio]], className)} {...rest}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default React.memo(AspectRatio)
