import React, { useCallback } from 'react'
import styles from './MeetingRoomSlotListItem.module.css'
import c from 'classnames'
import { useHistory } from 'react-router-dom'
import { IMeetingRoomReservation } from '../../../../../../types/app.types'
import Text from '../../../../../../components/atoms/text/Text'
import routes, { generateRoute } from '../../../../../../config/routes'
import {
  getHumanizedDuration,
  getHumanizedReservationDaysLeft,
  getReservationDay,
} from '../../../../../../modules/meetingRoom/utils'

interface IProps {
  className?: string
  reservation: IMeetingRoomReservation
}

const MeetingRoomSlotListItem = ({ className, reservation }: IProps) => {
  const history = useHistory()

  const goToReservationDetails = useCallback(() => {
    history.push(generateRoute(routes.book.meetingRoomDetails, [{ name: 'reservationId', value: reservation.id }]))
  }, [history, reservation])

  return (
    <button className={c(styles.container, className)} onClick={goToReservationDetails}>
      <div className={styles.infoWrapper}>
        <Text weight="600">
          {getReservationDay({ reservation }).toFormat("d 'de' LLLL")} - {getHumanizedDuration({ reservation })}
        </Text>
        <Text size="sm">{getHumanizedReservationDaysLeft({ reservation })}</Text>
      </div>
    </button>
  )
}

export default React.memo(MeetingRoomSlotListItem)
