import React from 'react'
import Button from '../../../../components/atoms/button/Button'
import Text from '../../../../components/atoms/text/Text'
import styles from './GDPR.module.css'
import { useHistory } from 'react-router-dom'
import routes from '../../../../config/routes'
import { VARIANTS } from '../../../../types/Components.types'

const GDPR = () => {
  const history = useHistory()

  const goToGDPR = React.useCallback(() => {
    history.push(routes.gdpr)
  }, [history])

  return (
    <section className={styles.container}>
      <Text weight="500" size="xl" className={styles.title} align="center">
        ¿Quieres conocer como tratamos tus datos?
      </Text>

      <Text size="ml" align="center">
        Nuestra política de tratamiento de datos cumple con la GDPR y es 100% transparente.
      </Text>

      <Button variant={VARIANTS.max} inverted onClick={goToGDPR} className={styles.cta}>
        Enséñamelo
      </Button>
    </section>
  )
}

export default React.memo(GDPR)
