import { IInvoice } from '../../types/app.types'
import InvoicesAPI from './api'

export const getInvoices = async ({
  userId,
  queryParams,
}: {
  userId: string
  queryParams: URLSearchParams
}): Promise<IInvoice[]> => {
  const response = await InvoicesAPI.getInvoices({ userId, queryParams })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return await response.json()
}

export const downloadInvoice = async ({ userId, paymentId }: { userId: string; paymentId: string }) => {
  const response = await InvoicesAPI.downloadInvoice({ userId, paymentId })

  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  const blob = await response.blob()
  const headers = response.headers
  const filename = headers.get('Content-Disposition')?.split('=')[1] ?? 'invoice.pdf'

  const downloadUrl = URL.createObjectURL(blob)
  const a: HTMLAnchorElement = document.createElement('a')
  document.body.appendChild(a)
  a.setAttribute('style', 'display: none')
  a.setAttribute('download', filename)
  a.href = downloadUrl
  a.click()
  document.body.removeChild(a)
}
