import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconClose = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M2.503 29.69c.33-.079.62-.237.871-.475l11.721-11.722 11.723 11.722c.237.238.521.396.852.475.33.08.66.08.99 0 .33-.079.621-.244.872-.495s.417-.542.496-.872c.08-.33.08-.66 0-.99a1.764 1.764 0 00-.476-.853L17.83 14.759 29.552 3.037c.238-.238.397-.522.476-.852.08-.33.08-.664 0-1a1.699 1.699 0 00-.496-.863 1.814 1.814 0 00-.881-.495 2.094 2.094 0 00-.991 0 1.753 1.753 0 00-.842.475L15.095 12.025 3.375.302a1.89 1.89 0 00-.872-.475 2.169 2.169 0 00-.991-.01c-.33.072-.621.24-.872.505a1.89 1.89 0 00-.476.872c-.08.33-.08.66 0 .99.08.331.238.615.476.853l11.72 11.722L.64 26.48c-.238.238-.4.522-.486.852a2.05 2.05 0 00-.01 1c.08.338.245.625.496.863.25.25.541.416.872.495.33.08.66.08.99 0z" />
      </svg>
    </i>
  )
}

export default IconClose
