import { createStore, applyMiddleware, compose, combineReducers } from 'redux'

import thunkMiddleware from 'redux-thunk'

export const CLEAN_ACTION = 'CONFIG_STORE/CLEAN_STORE'

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

let initialReducers = {}
let isInitialized = false

function configureStore(initialState: any, reducers: any, initialReducersCombined?: any) {
  if (isInitialized) {
    console.error(`Store is initialized you can't init two times`)
    return
  }
  isInitialized = true
  initialReducers = reducers
  const store: any = createStore(
    initialReducersCombined ? initialReducersCombined : combineReducers(initialReducers),
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware)),
  )

  store.asyncReducers = {}
  return store
}

function createReducer(asyncReducers = {}) {
  const appReducer = combineReducers({
    ...initialReducers,
    ...asyncReducers,
  })

  return (state: any, action: { type: string }) => {
    if (action.type === CLEAN_ACTION) {
      state = undefined
    }

    return appReducer(state, action)
  }
}

function injectAsyncReducer(store: any, name: string, asyncReducer: any) {
  store.asyncReducers[name] = asyncReducer
  store.replaceReducer(createReducer(store.asyncReducers))
}

export { configureStore, createReducer, injectAsyncReducer }
