import React from 'react'
import Screen from '../../../../components/molecules/screen/Screen'
import Button from '../../../../components/atoms/button/Button'
import styles from './LoginScreen.module.css'
import { useHistory } from 'react-router-dom'
import Text from '../../../../components/atoms/text/Text'
import BackButton from '../../../../components/atoms/backButton/BackButton'
import { useFormik } from 'formik'
import Input from '../../../../components/atoms/input/Input'
import logiFormSchema from './schemas/login'
import FormField from '../../../../components/molecules/formField/FormField'
import routes from '../../../../config/routes'
import Link from '../../../../components/atoms/link/Link'
import { VARIANTS } from '../../../../types/Components.types'
import { useLoginMutation } from '../../../../modules/auth/hooks'
import { useQuery } from '../../../../utils/hooks/useQuery'

const LoginScreen = () => {
  const history = useHistory()
  const login = useLoginMutation()
  const queryParams = useQuery()

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, isValid, isSubmitting } = useFormik({
    initialValues: {
      email: queryParams.get('uid') ?? '',
      password: '',
    },
    validateOnMount: true,
    validationSchema: logiFormSchema,
    onSubmit: async (values) => {
      login.mutate(
        { email: values.email, password: values.password },
        {
          onSuccess: () => {
            history.replace(routes.app)
          },
        },
      )
    },
  })

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Accede a tu cuenta
          </Text>
        </div>
        <Text>Y vuelve a disfrutar de los servicios ofrecidos por el coworking</Text>

        <div className={styles.content}>
          <form onSubmit={handleSubmit}>
            <FormField
              field={
                <Input
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputMode="email"
                />
              }
              error={touched.email && errors.email ? errors.email : null}
            />

            <FormField
              field={
                <Input
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              error={touched.password && errors.password ? errors.password : null}
            />

            <Link path={routes.password.forgot} className={styles.forgotPasswordLink}>
              <Text size="sm">Olvidé mi contraseña</Text>
            </Link>

            <Button
              variant={VARIANTS.primary}
              className={styles.action}
              type="submit"
              disabled={!isValid}
              isLoading={isSubmitting}
            >
              Entrar
            </Button>
          </form>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(LoginScreen)
