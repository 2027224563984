import React from 'react'
import styles from './EmptyState.module.css'
import { ReactComponent as EmptyIllustration } from '../../assets/empty.svg'
import c from 'classnames'
import Text from '../../../../../../components/atoms/text/Text'

interface IProps {
  className?: string
}

const EmptyState = ({ className }: IProps) => {
  return (
    <div className={c(styles.container, className)}>
      <EmptyIllustration />

      <Text weight="500" align="center" className={styles.title}>
        ¡Vaya! Parece que de momento estarás sólo en el coworking :(
      </Text>
    </div>
  )
}

export default React.memo(EmptyState)
