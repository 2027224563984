import React, { Reducer, useCallback, useReducer } from 'react'
import { useIsGUCSL } from '../../modules/auth/hooks'
import {
  SET_TYPE,
  SET_NUMBER_OF_MONTHS,
  SET_DAY,
  SET_SEAT,
  SET_SHIFT,
  SET_BLUEPRINT_INITIALIZED,
  SET_HOURS,
  SET_DATA,
  SET_COWORKING_DAYS,
  SET_ATTACHMENTS,
  CLEAR,
} from './actions'
import { IShift, SHIFTS } from './shifts'
import { TYPES } from './types'

interface IProps {
  children: React.ReactNode
}

interface IState {
  type: string
  months: number
  day: Date | null
  seat: string | null | undefined
  shift: Partial<IShift>
  blueprintInitialized: boolean
  hours: string[]
  coworkingDays: Date[]
  attachments: File[]
}

interface ISetTypeAction {
  type: typeof SET_TYPE
  payload: {
    type: string
  }
}

interface ISetNumberOfMonthsAction {
  type: typeof SET_NUMBER_OF_MONTHS
  payload: {
    months: number
  }
}

interface ISetDayAction {
  type: typeof SET_DAY
  payload: {
    day: Date
  }
}

interface ISetSeatAction {
  type: typeof SET_SEAT
  payload: {
    seat: string
  }
}

interface ISetShiftAction {
  type: typeof SET_SHIFT
  payload: {
    shift: IShift
  }
}

interface ISetBlueprintInitializedAction {
  type: typeof SET_BLUEPRINT_INITIALIZED
  payload: {
    blueprintInitialized: boolean
  }
}

interface ISetHoursAction {
  type: typeof SET_HOURS
  payload: {
    hours: string[]
  }
}

interface ISetCoworkingDaysAction {
  type: typeof SET_COWORKING_DAYS
  payload: {
    coworkingDays: Date[]
  }
}

interface ISetAttachmentsAction {
  type: typeof SET_ATTACHMENTS
  payload: {
    attachments: File[]
  }
}

interface ISetDataAction {
  type: typeof SET_DATA
  payload: {
    data: Partial<IState>
  }
}

interface IClearAction {
  type: typeof CLEAR
  payload: void | null
}

type IAction =
  | ISetTypeAction
  | ISetNumberOfMonthsAction
  | ISetDayAction
  | ISetSeatAction
  | ISetShiftAction
  | ISetBlueprintInitializedAction
  | ISetHoursAction
  | ISetCoworkingDaysAction
  | ISetAttachmentsAction
  | ISetDataAction
  | IClearAction

interface IData extends IState {
  date: Date
  schedule: IShift
  seatId: string
}

interface IBookAssistantContext {
  type: string
  months: number
  day: Date | null
  seat: string | null | undefined
  shift: Partial<IShift>
  blueprintInitialized: boolean
  isEligibleForGUCSL: boolean
  hours: string[]
  coworkingDays: Date[]
  attachments: File[]
  setType: ({ type }: { type: string }) => void
  setNumberOfMonths: ({ months }: { months: number }) => void
  setDay: ({ day }: { day: Date }) => void
  setSeat: ({ seat }: { seat: string }) => void
  setShift: ({ shift }: { shift: IShift }) => void
  setBlueprintInitialized: ({ blueprintInitialized }: { blueprintInitialized: boolean }) => void
  setHours: ({ hours }: { hours: string[] }) => void
  setCoworkingDays: ({ coworkingDays }: { coworkingDays: Date[] }) => void
  setAttachments: ({ attachments }: { attachments: File[] }) => void
  setData: ({ data }: { data: IData }) => void
  clear: () => void
}

export const BookAssistantContext = React.createContext<IBookAssistantContext | null>(null)

const reducer = (prevState: IState, { type, payload }: IAction) => {
  switch (type) {
    case SET_TYPE:
      return {
        ...prevState,
        type: payload.type,
        months: payload.type === TYPES.MONTH ? -1 : 0,
        shift: payload.type === TYPES.DAY ? SHIFTS.day : prevState.shift,
        coworkingDays: [],
      }

    case SET_NUMBER_OF_MONTHS:
      return {
        ...prevState,
        months: payload.months,
      }

    case SET_DAY:
      return {
        ...prevState,
        day: payload.day,
      }

    case SET_SEAT:
      return {
        ...prevState,
        seat: payload.seat,
      }

    case SET_SHIFT:
      return {
        ...prevState,
        shift: payload.shift,
      }

    case SET_BLUEPRINT_INITIALIZED:
      return {
        ...prevState,
        blueprintInitialized: payload.blueprintInitialized,
      }

    case SET_HOURS:
      return {
        ...prevState,
        hours: payload.hours,
      }

    case SET_COWORKING_DAYS:
      return {
        ...prevState,
        coworkingDays: payload.coworkingDays,
      }

    case SET_ATTACHMENTS:
      return {
        ...prevState,
        attachments: payload.attachments || [],
      }

    case SET_DATA:
      return {
        ...prevState,
        ...payload.data,
      }

    case CLEAR:
      return {
        ...prevState,
        day: null,
        seat: null,
      }

    default:
      return prevState
  }
}

export const BookAssistantProvider = ({ children }: IProps) => {
  const { isGUCSL } = useIsGUCSL()
  const [{ type, months, day, shift, seat, blueprintInitialized, hours, coworkingDays, attachments }, dispatch] =
    useReducer<Reducer<IState, IAction>>(reducer, {
      type: TYPES.DAY,
      months: 0,
      day: null,
      shift: SHIFTS.day,
      seat: null,
      blueprintInitialized: false,
      hours: [],
      coworkingDays: [],
      attachments: [],
    })

  const setType = useCallback(({ type }: { type: string }) => {
    dispatch({ type: SET_TYPE, payload: { type } })
  }, [])

  const setNumberOfMonths = useCallback(({ months }: { months: number }) => {
    dispatch({ type: SET_NUMBER_OF_MONTHS, payload: { months } })
  }, [])

  const setDay = useCallback(({ day }: { day: Date }) => {
    dispatch({ type: SET_DAY, payload: { day } })
  }, [])

  const setShift = useCallback(({ shift }: { shift: IShift }) => {
    dispatch({ type: SET_SHIFT, payload: { shift } })
  }, [])

  const setSeat = useCallback(({ seat }: { seat: string }) => {
    dispatch({ type: SET_SEAT, payload: { seat } })
  }, [])

  const setHours = useCallback(({ hours }: { hours: string[] }) => {
    dispatch({ type: SET_HOURS, payload: { hours } })
  }, [])

  const setCoworkingDays = useCallback(({ coworkingDays }: { coworkingDays: Date[] }) => {
    dispatch({ type: SET_COWORKING_DAYS, payload: { coworkingDays } })
  }, [])

  const setAttachments = useCallback(({ attachments }: { attachments: File[] }) => {
    dispatch({ type: SET_ATTACHMENTS, payload: { attachments } })
  }, [])

  const setData = useCallback(({ data }: { data: IData }) => {
    dispatch({
      type: SET_DATA,
      payload: {
        data: {
          type: data.type,
          months: data.months,
          day: data.date,
          shift: data.schedule,
          seat: data.seatId,
          blueprintInitialized: false,
          hours: data.hours || [],
        },
      },
    })
  }, [])

  const clear = useCallback(() => {
    dispatch({ type: CLEAR, payload: void 0 })
  }, [])

  const setBlueprintInitialized = useCallback(({ blueprintInitialized }: { blueprintInitialized: boolean }) => {
    dispatch({ type: SET_BLUEPRINT_INITIALIZED, payload: { blueprintInitialized } })
  }, [])

  // const initBlueprint = useCallback(
  //   ({ svg, modifiers = {} }) => {
  //     seats.forEach(({ id, name, disabled = false }) => {
  //       const seatElement = svg.querySelector(`[id=${name}]`)
  //       if (!seatElement) {
  //         console.error(`Seat named like ${name} was not found in the blueprint`)
  //         return
  //       }

  //       if (disabled && modifiers.disabled) {
  //         seatElement.classList.add(modifiers.disabled)
  //       }

  //       if (disabled) {
  //         return
  //       }

  //       seatElement.addEventListener('click', (evt) => {
  //         setSeat({ seat: id })
  //         const currentSelectedSeat = svg.querySelector(`.${modifiers.selected}`)
  //         if (currentSelectedSeat) {
  //           currentSelectedSeat.classList.remove(modifiers.selected)
  //         }
  //         seatElement.classList.add(modifiers.selected)
  //       })
  //     })
  //   },
  //   [seats, setSeat],
  // )

  const isEligibleForGUCSL = React.useMemo(() => {
    return type === TYPES.MONTH && !isGUCSL
  }, [isGUCSL, type])

  return (
    <BookAssistantContext.Provider
      value={{
        type,
        months,
        day,
        shift,
        seat,
        blueprintInitialized,
        hours,
        setType,
        setNumberOfMonths,
        setDay,
        setShift,
        setSeat,
        setBlueprintInitialized,
        // initBlueprint,
        setHours,
        setData,
        clear,
        isEligibleForGUCSL,
        coworkingDays,
        setCoworkingDays,
        attachments,
        setAttachments,
      }}
    >
      {children}
    </BookAssistantContext.Provider>
  )
}

export const useBookAssistant = () => {
  const ctx = React.useContext(BookAssistantContext)
  if (!ctx) {
    throw Error('The `useBookAssistant` hook must be called from a descendent of the `BookAssistantProvider`.')
  }

  return ctx
}
