import React from 'react'
import styles from './LinkRow.module.css'
import c from 'classnames'
import Link from '../../atoms/link/Link'
import Text from '../../atoms/text/Text'
import IconChevronRight from '../../icons/IconChevronRight'

interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string
  children: React.ReactNode
  path?: string
  href?: string
}

const LinkRow = ({ path, className, children, href, ...rest }: IProps) => {
  if (href) {
    return (
      <a className={c(styles.container, className)} href={href} {...rest}>
        <Text>{children}</Text>

        <IconChevronRight size="xs" variant="max" />
      </a>
    )
  }

  return (
    <Link path={path as string} className={c(styles.container, className)} {...rest}>
      <Text>{children}</Text>

      <IconChevronRight size="xs" variant="max" />
    </Link>
  )
}

export default React.memo(LinkRow)
