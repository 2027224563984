import React from 'react'
import Text from '../../../../components/atoms/text/Text'
import styles from './Highlights.module.css'
import IconBulb from '../../../../components/icons/IconBulb'
import IconEuroSquare from '../../../../components/icons/IconEuroSquare'
import IconHome from '../../../../components/icons/IconHome'
import { useHistory } from 'react-router-dom'
import routes from '../../../../config/routes'
import Button from '../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../types/Components.types'

interface IProps {
  isOpened: boolean
}

const Highlights = ({ isOpened }: IProps) => {
  const history = useHistory()

  const goToApp = () => {
    history.push(routes.app)
  }

  return (
    <section className={styles.container}>
      <div className={styles.block}>
        <IconBulb variant="max" size="m" />
        <Text weight="500" size="xl" className={styles.title}>
          Escoge tu sitio
        </Text>

        <Text size="ml">
          Elige la fecha, la hora que más se ajuste a tus necesitades y, ¡resérvalo! Tu sitio estará impoluto cuando
          llegues al coworking.
        </Text>

        {isOpened && (
          <Button variant={VARIANTS.primary} onClick={goToApp} className={styles.cta}>
            Reserva tu sitio
          </Button>
        )}
      </div>

      <div className={styles.block}>
        <IconEuroSquare variant="max" size="m" />
        <Text weight="500" size="xl" className={styles.title}>
          Compra tu bono
        </Text>

        <Text size="ml">
          ¿Quieres utilizar la sala de reuniones? ¿O prefieres acceder al coworking durante todo el mes? Seguro que hay
          un bono que cubra tus necesidades.
        </Text>

        {isOpened && (
          <Button variant={VARIANTS.primary} onClick={goToApp} className={styles.cta}>
            Reserva tu sitio
          </Button>
        )}
      </div>

      <div className={styles.block}>
        <IconHome variant="max" size="m" />
        <Text weight="500" size="xl" className={styles.title}>
          Sientete en casa
        </Text>

        <Text size="ml">
          Disfruta de todas las comodidades que te brinda el coworking. Servicio de copistería, sala de reuniones, luz
          natural y artificial…
        </Text>

        {isOpened && (
          <Button variant={VARIANTS.primary} onClick={goToApp} className={styles.cta}>
            Reserva tu sitio
          </Button>
        )}
      </div>
    </section>
  )
}

export default React.memo(Highlights)
