import c from 'classnames'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Text from '../../../components/atoms/text/Text'
import TouchableOpacity from '../../../components/atoms/touchableOpacity/TouchableOpacity'
import IconSettings from '../../../components/icons/IconSettings'
import Avatar from '../../../components/molecules/avatar/Avatar'
import LoadingData from '../../../components/molecules/loadingData/LoadingData'
import UnlockSlider from '../../../components/molecules/unlockSlider/UnlockSlider'
import WifiPassword from '../../../components/molecules/wifiPassword/WifiPassword'
import routes from '../../../config/routes'
import { useProfile } from '../../../modules/auth/hooks'
import { useTodayReservatedSeats } from '../../../modules/seats/hooks'
import { useRenewableVouchers } from '../../../modules/vouchers/hooks'
import { useBookAssistant } from '../../../providers/bookAssistant'
import { VARIANTS } from '../../../types/Components.types'
import styles from './HomeScreen.module.css'
import Actions from './components/actions/Actions'
import BooksCarousel from './components/books/booksCarousel/BooksCarousel'
import Decorator from './components/decorator/Decorator'
import UpcomingEvents from './components/upcomingEvents/UpcomingEvents'

const HomeScreen = ({ ...rest }) => {
  const history = useHistory()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { isLoading: isLoadingTodayReservation, data: todayReservations } = useTodayReservatedSeats({
    userId: profile?.id,
  })
  const { isLoading: isLoadingVouchers } = useRenewableVouchers({ userId: profile?.id })
  const isLoading = isLoadingProfile || isLoadingTodayReservation || isLoadingVouchers
  const { clear } = useBookAssistant()

  const openingTime = DateTime.local().set({ hour: 8 })
  const closeTime = DateTime.local().set({ hour: 21 })
  const isInCoworkingTime = openingTime.diffNow('hours').hours <= 0 && closeTime.diffNow('hours').hours >= 0

  const todayReservatedSeat = todayReservations?.[0]

  useEffect(() => {
    clear?.()
  }, [clear])

  const goToProfile = useCallback(() => {
    history.push(routes.profile.details)
  }, [history])

  const goToSettings = useCallback(() => {
    history.push(routes.settings.home)
  }, [history])

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <TouchableOpacity onClick={goToProfile}>
          <Avatar resource={profile?.facephoto} className={styles.avatar} />
          <Text size="s" variant={VARIANTS.white}>
            Hola,{' '}
            <Text weight="600" variant={VARIANTS.white} element="span" size="s">
              {profile?.firstname}
            </Text>
          </Text>
        </TouchableOpacity>

        <TouchableOpacity onClick={goToSettings}>
          <IconSettings variant="white" />
        </TouchableOpacity>
      </header>

      <UpcomingEvents />

      <div className={styles.wrapper}>
        <Decorator />

        <div className={c(styles.wrapperContent)}>
          <>
            <Actions />

            <WifiPassword className={styles.wifiWrapper} />

            {isInCoworkingTime && todayReservatedSeat && (
              <div className={styles.unlockWrapper}>
                <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
                  Accede al coworking
                </Text>
                <UnlockSlider book={todayReservatedSeat} />
              </div>
            )}

            <div className={styles.carouselContent}>
              <div className={styles.carouselInfo}>
                <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
                  Últimas reservas
                </Text>

                <Text size="sm" className={styles.p}>
                  Aquí puedes reservar la sala de reuniones, ver tus compañeros, ver los datos de tus reservas...
                </Text>
              </div>

              <BooksCarousel />
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default React.memo(HomeScreen)
