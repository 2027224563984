import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../../../components/atoms/button/Button'
import Text from '../../../../components/atoms/text/Text'
import LoadingData from '../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../components/molecules/screen/Screen'
import { useProfile } from '../../../../modules/auth/hooks'
import { useReservatedSeatById } from '../../../../modules/seats/hooks'
import { useCancelAutoRenewMutation } from '../../../../modules/vouchers/hooks'
import { VARIANTS } from '../../../../types/Components.types'
import { ReactComponent as Illustration } from './assets/warning_illustration.svg'
import styles from './ReservationCancelScreen.module.css'

interface IParams {
  bookId: string
}

const ReservationCancelScreen = () => {
  const history = useHistory()
  const [isWorking, setIsWorking] = useState(false)
  const { bookId } = useParams<IParams>()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: reservation, isLoading: isLoadingReservation } = useReservatedSeatById({
    userId: profile?.id,
    seatId: bookId,
  })

  const isLoading = isLoadingProfile || isLoadingReservation

  const cancelAutoRenew = useCancelAutoRenewMutation()

  const handleConfirmClick = useCallback(async () => {
    setIsWorking(true)
    cancelAutoRenew.mutate(
      {
        userId: profile!.id,
        voucherId: reservation!.seat.userVoucher,
      },
      {
        onSuccess: () => {
          history.goBack()
        },
        onSettled: () => {
          setIsWorking(false)
        },
      },
    )
  }, [cancelAutoRenew, history, profile, reservation])

  const handleCancelClick = useCallback(() => {
    history.goBack()
  }, [history])

  useEffect(() => {
    if (!isLoading && !reservation) {
      return history.goBack()
    }
  }, [history, isLoading, reservation])

  if (!reservation || isLoading) {
    return <LoadingData />
  }

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <Illustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
            Un momento
          </Text>
          <Text align="center">
            Si cancelas esta reserva dejarás de tener acceso al coworking el día{' '}
            {DateTime.fromISO(reservation.seat.reserved_from).endOf('month').toLocaleString(DateTime.DATE_SHORT)}.
            ¿Quieres continuar?
          </Text>
        </div>

        <div className={styles.actions}>
          <Button
            inverted
            variant={VARIANTS.max}
            className={styles.action}
            onClick={handleCancelClick}
            disabled={isWorking}
          >
            Cancelar
          </Button>

          <Button
            variant={VARIANTS.negative}
            className={styles.action}
            onClick={handleConfirmClick}
            disabled={isWorking}
            isLoading={isWorking}
          >
            Continuar
          </Button>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(ReservationCancelScreen)
