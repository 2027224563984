import FetchHelper from '../FetchHelper'

class SeatsAPI {
  static getSeats({ queryParams }: { queryParams?: URLSearchParams }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`seats?${queryParams?.toString() ?? ''}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static bookSeat({
    type,
    date,
    month,
    schedule,
    seatId,
    seatType,
    userId,
    months,
  }: {
    type: string
    date: string
    month?: string
    schedule: {
      from: number
      to: number
    }
    seatId: string
    seatType: string
    userId: string
    months: number
  }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/seats`, {
      method: 'POST',
      headers: generateHeaders({ headers: {} }),
      body: {
        type,
        date,
        month,
        schedule,
        months,
        seatId,
        seatType,
      },
    })
  }

  static getUserBookedSeats({ userId }: { userId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/seats`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getBookWorkmates({ userId, seatId }: { userId: string; seatId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/seats/${seatId}/coworkers`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static openCoworking({
    userId,
    seatId,
    coordinates,
    dummyCoordinates,
  }: {
    userId: string
    seatId: string
    coordinates: GeolocationPosition
    dummyCoordinates: boolean
  }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/seats/${seatId}/access/enter`, {
      method: 'POST',
      headers: generateHeaders({ headers: {} }),
      body: {
        coords: {
          latitude: coordinates.coords.latitude,
          longitude: coordinates.coords.longitude,
          dummy: dummyCoordinates ?? false,
        },
        timestamp: coordinates.timestamp,
      },
    })
  }

  static updateReservation({
    userId,
    bookId,
    seatId,
    date,
  }: {
    userId: string
    bookId: string
    seatId: string
    date: string
  }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/seats/${bookId}`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: {
        seatId,
        date,
      },
    })
  }

  static cancelReservation({ userId, seatId }: { userId: string; seatId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/seats/${seatId}`, {
      method: 'DELETE',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getHolidays() {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('festivos', {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static reserveCoworking({
    userId,
    from,
    to,
    attachments,
  }: {
    userId: string
    from: string
    to: string
    attachments: File[]
  }) {
    const { fetch, generateHeaders } = FetchHelper
    const formData = new FormData()
    attachments.forEach((attachment) => {
      formData.append('attachments[]', attachment, attachment.name)
    })
    formData.append('from', from)
    formData.append('to', to)
    return fetch(`users/${userId}/coworking`, {
      method: 'POST',
      headers: generateHeaders({ headers: {}, useDefaultHeaders: false }),
      body: formData,
    })
  }

  static getWifiPassword({ userId, seatId }: { userId: string; seatId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/seats/${seatId}/wifi`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }
}

export default SeatsAPI
