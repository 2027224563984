import * as Sentry from '@sentry/react'
import React, { ErrorInfo } from 'react'
import routes from '../../../config/routes'
import { VARIANTS } from '../../../types/Components.types'
import Button from '../../atoms/button/Button'
import Text from '../../atoms/text/Text'
import Screen from '../screen/Screen'
import styles from './ErrorBoundary.module.css'
import { ReactComponent as ErrorIllustration } from './assets/error_illustration.svg'

interface IProps {
  history: any
  children?: React.ReactNode
}

interface IState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): IState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error)
  }

  handleGoHome = () => {
    this.props.history.push(routes.app)
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Screen hasDecorator decoratorSize="large">
          <div className={styles.container}>
            <div className={styles.content}>
              <ErrorIllustration />
              <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title} align="center">
                ¡Vaya!
              </Text>
              <Text className={styles.description} align="center">
                Parece que algo salió mal
              </Text>
            </div>

            <div className={styles.actions}>
              <Button variant={VARIANTS.primary} onClick={this.handleGoHome}>
                ¡Sácame de aquí!
              </Button>
            </div>
          </div>
        </Screen>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
