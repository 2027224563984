import { urlBase64ToUint8Array } from '../../utils/index'
import NotificationsAPI from './api'

class NotificationsService {
  available = false

  constructor() {
    if ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window) {
      this.available = true
    }
  }

  getState() {
    if (!this.available) {
      return 'granted'
    }
    return process.env.NODE_ENV === 'development' ? 'granted' : Notification.permission
  }

  askPermission() {
    return new Promise((resolve, reject) => {
      if (!this.available) {
        return reject(new Error('Not supported'))
      }
      const permissionResult = Notification.requestPermission(function (result) {
        resolve(result)
      })

      if (permissionResult) {
        permissionResult.then(resolve, reject)
      }
    }).then(function (permissionResult) {
      if (permissionResult !== 'granted') {
        throw new Error("We weren't granted permission.")
      }
    })
  }

  registerPush() {
    if (!this.available) {
      return Promise.resolve()
    }

    return navigator.serviceWorker
      .getRegistration()
      .then(async (registration) => {
        if (!registration) {
          console.error('SW not registered')
          return Promise.reject(new Error('SW not registered'))
        }
        const response = await NotificationsAPI.getVAPIDKey()
        if (response.status !== 200) {
          return Promise.reject(new Error('Invalid Vapid response'))
        }
        const { vapid } = await response.json()
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(vapid),
        }

        return registration.pushManager.subscribe(subscribeOptions)
      })
      .then(async (pushSubscription) => {
        console.log('Received PushSubscription: ', JSON.stringify(pushSubscription))
        await NotificationsAPI.addPushToken({ token: JSON.stringify(pushSubscription) })
        return pushSubscription
      })
      .catch((e) => console.info('User may blocked the notifications popup', e))
  }
}

const Notifications = new NotificationsService()
export { Notifications }
