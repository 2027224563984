import React, { useCallback, useState } from 'react'
import styles from './NotificationsScreen.module.css'
import { useHistory, useLocation } from 'react-router-dom'
import { ReactComponent as NotificationsIllustration } from './assets/notifications_illustration.svg'
import { ReactComponent as ErrorIllustration } from './assets/error_illustration.svg'
import { ILocationState } from '../../../../types/app.types'
import Screen from '../../../../components/molecules/screen/Screen'
import Text from '../../../../components/atoms/text/Text'
import Button from '../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../types/Components.types'
import { Notifications } from '../../../../modules/notifications/service'
import routes from '../../../../config/routes'

const NotificationsScreen = () => {
  const history = useHistory()
  const [error, setError] = useState<Error | null>(null)
  const [isGrantingNotifications, setIsGrantingNotifications] = useState(false)
  const { state } = useLocation<ILocationState>()

  const askForPermissions = useCallback(async () => {
    setIsGrantingNotifications(true)
    await Notifications.askPermission().catch((error) => {
      setError(error)
      setIsGrantingNotifications(false)
    })

    await Notifications.registerPush().catch((error) => {
      setError(error)
      setIsGrantingNotifications(false)
    })

    setIsGrantingNotifications(false)
    return history.replace(state?.from ? state.from.pathname : routes.app)
  }, [history, state])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        {!error && (
          <div className={styles.content}>
            <NotificationsIllustration />
            <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title} align="center">
              Notificaciones
            </Text>
            <Text className={styles.description} align="center">
              Te prometemos que sólo te avisaremos de tus próximas reservas del coworking o unos minutos antes de que
              tengas que abandonarlo
            </Text>
          </div>
        )}
        {!error && (
          <Button
            variant={VARIANTS.primary}
            className={styles.action}
            onClick={askForPermissions}
            isLoading={isGrantingNotifications}
          >
            Sí, te doy permisos
          </Button>
        )}

        {error && (
          <div className={styles.content}>
            <ErrorIllustration />
            <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title} align="center">
              ¡Vaya!
            </Text>
            <Text className={styles.description} align="center">
              Parece que rechazaste los permisos para acceder a tu ubicación. Recuerda que son necesarios para acceder
              al coworking.
            </Text>

            <Text className={styles.description} align="center">
              Si quieres continuar usando la aplicación, tendrás que ir a ajustes de tu navegador para concedernos los
              permisos :(
            </Text>
          </div>
        )}
      </div>
    </Screen>
  )
}

export default React.memo(NotificationsScreen)
