import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../../../../components/atoms/button/Button'
import Text from '../../../../../../components/atoms/text/Text'
import LoadingData from '../../../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../../../components/molecules/screen/Screen'
import routes, { generateRoute } from '../../../../../../config/routes'
import { useProfile } from '../../../../../../modules/auth/hooks'
import { useRenewableVouchers } from '../../../../../../modules/vouchers/hooks'
import { VARIANTS } from '../../../../../../types/Components.types'
import styles from './RenewVoucherScreen.module.css'

const RenewVoucherScreen = () => {
  const history = useHistory()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: vouchersRenewables, isLoading: isLoadingVouchers } = useRenewableVouchers({ userId: profile?.id })
  const isLoading = isLoadingProfile || isLoadingVouchers

  const handleSkipClick = () => {
    history.replace(routes.book.new.seat.details)
  }

  const handleContinueClick = () => {
    history.replace(
      generateRoute(routes.settings.account.vouchers.byId, [{ name: 'voucherId', value: vouchersRenewables![0]?.id }]),
    )
  }

  React.useEffect(() => {
    if (!isLoading && !vouchersRenewables) {
      history.replace(routes.book.new.seat.details)
    }
  }, [vouchersRenewables, isLoading, history])

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <Screen
      headerProps={{
        backEnabled: true,
        variant: VARIANTS.flamingo,
        title: '¡Renovación pendiente!',
      }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <Text size="ml" weight="500">
            Opps! Parece que tienes una renovación pendiente
          </Text>
          <Text>Tienes bonos pendientes de renovar. ¡Hazlo ahora para mantener tu sitio!</Text>
        </div>

        <div className={styles.actions}>
          <Button variant={VARIANTS.primary} inverted className={styles.action} onClick={handleSkipClick}>
            Saltar
          </Button>

          <Button variant={VARIANTS.primary} className={styles.action} onClick={handleContinueClick}>
            Continuar
          </Button>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(RenewVoucherScreen)
