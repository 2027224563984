import React, { useCallback } from 'react'
import styles from './VouchersScreen.module.css'
import Voucher from './components/voucher/Voucher'
import { useHistory } from 'react-router-dom'
import routes, { generateRoute } from '../../../../../../config/routes'
import Screen from '../../../../../../components/molecules/screen/Screen'
import BackButton from '../../../../../../components/atoms/backButton/BackButton'
import Text from '../../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../../types/Components.types'
import { useProfile } from '../../../../../../modules/auth/hooks'
import { usePurchasedVouchers } from '../../../../../../modules/vouchers/hooks'
import { IUserPurchaseVoucher } from '../../../../../../types/app.types'
import LoadingData from '../../../../../../components/molecules/loadingData/LoadingData'

const VouchersScreen = () => {
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: vouchers, isLoading: isLoadingVouchers } = usePurchasedVouchers({ userId: profile?.id })
  const isLoading = isLoadingProfile || isLoadingVouchers
  const history = useHistory()

  const handleVoucherClick = useCallback(
    (voucher: IUserPurchaseVoucher) => {
      return history.push(
        generateRoute(routes.settings.account.vouchers.byId, [{ name: 'voucherId', value: voucher.id }]),
        {
          isPaid: voucher.isPaid,
        },
      )
    },
    [history],
  )

  if (isLoading) {
    return <LoadingData />
  }

  const toRenderVouchers = vouchers ?? []
  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Mis bonos
          </Text>
        </div>

        <Text>Aquí tienes todos los bonos que has comprado y tienen validez</Text>

        <div className={styles.content}>
          {toRenderVouchers.map((voucher) => {
            return <Voucher key={voucher.id} voucher={voucher} onClick={handleVoucherClick} />
          })}
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(VouchersScreen)
