import React, { useCallback, useState, useEffect } from 'react'
import styles from './BookChangeSeatScreen.module.css'
import { useHistory, useParams } from 'react-router-dom'
import c from 'classnames'
import { DateTime } from 'luxon'
import { useReservatedSeatById, useSeatById, useUpdateReservateSeatMutation } from '../../../../../modules/seats/hooks'
import { useProfile } from '../../../../../modules/auth/hooks'
import { usePurchasedVoucher } from '../../../../../modules/vouchers/hooks'
import { useCardTransition } from '../../../../../providers/cardTransition/cardTransition'
import { useBookAssistant } from '../../../../../providers/bookAssistant'
import Screen from '../../../../../components/molecules/screen/Screen'
import Text from '../../../../../components/atoms/text/Text'
import IconInfo from '../../../../../components/icons/IconInfo'
import { VARIANTS } from '../../../../../types/Components.types'
import SeatsHelp from '../../../../../components/molecules/seatsHelp/SeatsHelp'
import Blueprint from '../../../../../components/molecules/blueprint/Blueprint'
import Loader from '../../../../../components/atoms/loader/Loader'
import Button from '../../../../../components/atoms/button/Button'
import { TYPES } from '../../../../../providers/bookAssistant/types'
import { getBookSeatType, getBookShift } from '../../../../../modules/seats/utils'
import { SHIFTS } from '../../../../../providers/bookAssistant/shifts'

interface IParams {
  bookId: string
}

const BookChangeSeatScreen = () => {
  const { bookId } = useParams<IParams>()
  const { seat: seatId, clear, setSeat } = useBookAssistant()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: reservation, isLoading: isLoadingReservation } = useReservatedSeatById({
    userId: profile?.id,
    seatId: bookId,
  })
  const { data: voucher, isLoading: isLoadingVoucher } = usePurchasedVoucher({
    userId: profile?.id,
    voucherId: reservation?.seat.userVoucher,
  })
  const [queryParams, setQueryParams] = useState<URLSearchParams>()
  const { data: seat } = useSeatById({ seatId, queryParams })
  const { data: currentSeat } = useSeatById({ seatId: reservation?.seat.SeatId, queryParams })
  const isLoading = isLoadingProfile || isLoadingReservation || isLoadingVoucher

  const history = useHistory()
  const { openCard } = useCardTransition()

  const updateReservatedSeat = useUpdateReservateSeatMutation()

  const handleSeatSelected = useCallback(
    (seatId: string) => {
      setSeat({ seat: seatId })
    },
    [setSeat],
  )

  const handleContinueClick = useCallback(() => {
    updateReservatedSeat.mutate(
      {
        userId: profile!.id,
        date: DateTime.local().plus({ days: 1 }).toJSDate(),
        seatId: seatId!,
        bookId,
      },
      {
        onSuccess: () => {
          clear?.()
          history.goBack()
        },
      },
    )
  }, [updateReservatedSeat, profile, seatId, bookId, clear, history])

  useEffect(() => {
    if (!voucher) {
      return
    }

    const params: {
      from: string
      to: string
      type: string
      date?: string
      months: string
      fromDate?: string
      seatType?: string
    } = {
      type: TYPES.MONTH,
      date: DateTime.local().plus({ days: 1 }).toISODate(),
      from: String(getBookShift({ voucher })?.from ?? SHIFTS.morning.from),
      to: String(getBookShift({ voucher })?.to ?? SHIFTS.morning.to),
      months: String(voucher.maxMonthsToRenew > 0 ? voucher.maxMonthsToRenew : 12),
      fromDate: String(true),
      seatType: getBookSeatType({ voucher }) ?? '',
    }

    setQueryParams(new URLSearchParams(params))
  }, [voucher])

  return (
    <Screen
      headerProps={{
        backEnabled: true,
        variant: VARIANTS.flamingo,
        title: 'Cambiar sitio',
        action: isLoading
          ? undefined
          : {
              icon: <IconInfo variant="white" />,
              onClick: () => openCard({ Component: <SeatsHelp /> }),
            },
      }}
    >
      <div className={styles.container}>
        <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
          Selecciona tu puesto de trabajo {seat?.name ?? currentSeat?.name}
        </Text>

        <div className={c(styles.content, isLoading && styles.loading)}>
          {!isLoading && <Blueprint onSeatSelected={handleSeatSelected} />}
          {isLoading && <Loader variant="primary" />}
        </div>

        <div className={styles.actions}>
          <Button variant={VARIANTS.primary} className={styles.action} onClick={handleContinueClick} disabled={!seat}>
            Aceptar
          </Button>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(BookChangeSeatScreen)
