import React, { useCallback } from 'react'
import styles from './CarouselIndicators.module.css'
import c from 'classnames'

interface IProps {
  id?: string
  className?: string
  numberOfSlides?: number
  currentSlide: number
}

const CarouselIndicators = ({ id, className, numberOfSlides = 0, currentSlide }: IProps) => {
  const getIndicators = useCallback(() => {
    const indicators = []
    for (let i = 0; i < numberOfSlides; i++) {
      indicators.push(
        <span key={`${id}-${i}`} className={c(styles.indicator, i === currentSlide && styles.active)}></span>,
      )
    }
    return indicators
  }, [currentSlide, id, numberOfSlides])

  return (
    <div id={id} className={c(styles.container, className)}>
      {getIndicators()}
    </div>
  )
}

export default React.memo(CarouselIndicators)
