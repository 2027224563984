import React from 'react'
import styles from './RadioCard.module.css'
import c from 'classnames'
import Card from '../../atoms/card/Card'
import Radio from '../../atoms/radio/Radio'
import Text from '../../atoms/text/Text'
import Tag from '../../atoms/tag/Tag'
import { VARIANTS } from '../../../types/Components.types'

interface IProps {
  className?: string
  name: string
  option: {
    value: string
    label: string
    description?: string
    extraInfo?: string
  }
  onChange?: (name: string, value: string) => void
  selectedValue?: string
  price?: {
    cost: number
    discount: number
    percent: number
    total: number
  }
}

const RadioCard = ({ className, name, option, onChange, selectedValue, price, ...rest }: IProps) => {
  return (
    <Card
      className={c(styles.container, className)}
      key={option.value}
      onClick={() => {
        onChange?.(name, option.value)
      }}
    >
      <Radio
        className={styles.radio}
        name={name}
        isChecked={option.value === selectedValue}
        label={option.label}
        value={option.value}
        {...rest}
      />
      {option.description && (
        <Text size="sm" className={styles.description}>
          {option.description}
        </Text>
      )}

      {option.extraInfo && (
        <Text size="sm" className={styles.extraInfo}>
          {option.extraInfo}
        </Text>
      )}

      {price && (
        <div className={styles.priceWrapper}>
          <div className={c(styles.price, price.discount > 0 && styles.disabled)}>
            <Text size="l" weight="600" element="span">
              {price.cost !== 0 ? price.cost : 'Gratis'}
            </Text>
            {price.cost !== 0 && <Text element="span">€</Text>}
          </div>

          {price.discount > 0 && (
            <div className={styles.discount}>
              <Text size="l" weight="600" element="span">
                {price.total === 0 ? 'Gratis' : price.total}
              </Text>
              {price.total !== 0 && <Text element="span">€</Text>}

              <Tag className={styles.tag} variant="negative" textVariant={VARIANTS.white}>
                -{price.percent}%
              </Tag>
            </div>
          )}
        </div>
      )}
    </Card>
  )
}

export default React.memo(RadioCard)
