import React, { useContext, useReducer, useCallback, Reducer } from 'react'
import {
  SET_NAME,
  SET_PHONE_NUMBER,
  SET_PROFESSION,
  SET_AVATAR,
  SET_DNI,
  SET_IDENTITY_CARD,
  SET_CENSO,
} from './actions'

interface IProfileState {
  firstname: string
  lastname: string
  phone: string
  facephoto: File | null
  profession: string
  dni: string
  identityCard: { front: File | null; back: File | null }
  censo: File | null
}

interface ISetNameAction {
  type: 'PA/SET_NAME'
  payload: {
    firstname: string
    lastname: string
  }
}

interface ISetPhoneNumberAction {
  type: 'PA/SET_PHONE_NUMBER'
  payload: {
    phone: string
  }
}

interface ISetProfessionAction {
  type: 'PA/SET_PROFESSION'
  payload: {
    profession: string
  }
}

interface ISetAvatarAction {
  type: 'PA/SET_AVATAR'
  payload: {
    facephoto: File
  }
}

interface ISetDNIAction {
  type: 'PA/SET_DNI'
  payload: {
    dni: string
  }
}

interface ISetCensoAction {
  type: 'PA/SET_CENSO'
  payload: {
    censo: File
  }
}

interface ISetIdentityCardAction {
  type: 'PA/SET_IDENTITY_CARD'
  payload: {
    identityCard: { front: File; back: File }
  }
}

type IAction =
  | ISetNameAction
  | ISetPhoneNumberAction
  | ISetProfessionAction
  | ISetAvatarAction
  | ISetDNIAction
  | ISetCensoAction
  | ISetIdentityCardAction

interface IProfileAssistantContext extends IProfileState {
  setName: ({ firstname, lastname }: { firstname: string; lastname: string }) => void
  setPhone: ({ phone }: { phone: string }) => void
  setAvatar: ({ facephoto }: { facephoto: File }) => void
  setProfession: ({ profession }: { profession: string }) => void
  setDNI: ({ dni }: { dni: string }) => void
  setIdentityCard: ({ identityCard }: { identityCard: { front: File; back: File } }) => void
  setCenso: ({ censo }: { censo: File }) => void
}

export const ProfileAssistantContext = React.createContext<IProfileAssistantContext | null>(null)

const { Provider } = ProfileAssistantContext

const reducer = (prevState: IProfileState, { type, payload }: IAction) => {
  switch (type) {
    case SET_NAME:
      return {
        ...prevState,
        firstname: payload.firstname,
        lastname: payload.lastname,
      }

    case SET_PHONE_NUMBER:
      return {
        ...prevState,
        phone: payload.phone,
      }

    case SET_PROFESSION:
      return {
        ...prevState,
        profession: payload.profession,
      }

    case SET_AVATAR:
      return {
        ...prevState,
        facephoto: payload.facephoto,
      }

    case SET_DNI:
      return {
        ...prevState,
        dni: payload.dni,
      }

    case SET_CENSO:
      return {
        ...prevState,
        censo: payload.censo,
      }

    case SET_IDENTITY_CARD:
      return {
        ...prevState,
        identityCard: payload.identityCard,
      }
    default:
      return prevState
  }
}

interface IProps {
  children: React.ReactNode
}

export const ProfileAssistantProvider = ({ children }: IProps) => {
  const [{ firstname, lastname, phone, facephoto, profession, identityCard, dni, censo }, dispatch] = useReducer<
    Reducer<IProfileState, IAction>
  >(reducer, {
    firstname: '',
    lastname: '',
    phone: '',
    facephoto: null,
    profession: '',
    identityCard: { front: null, back: null },
    dni: '',
    censo: null,
  })

  const setName = useCallback(({ firstname, lastname }: { firstname: string; lastname: string }) => {
    dispatch({ type: SET_NAME, payload: { firstname, lastname } })
  }, [])

  const setPhone = useCallback(({ phone }: { phone: string }) => {
    dispatch({ type: SET_PHONE_NUMBER, payload: { phone } })
  }, [])

  const setAvatar = useCallback(({ facephoto }: { facephoto: File }) => {
    dispatch({ type: SET_AVATAR, payload: { facephoto } })
  }, [])

  const setProfession = useCallback(({ profession }: { profession: string }) => {
    dispatch({ type: SET_PROFESSION, payload: { profession } })
  }, [])

  const setDNI = useCallback(({ dni }: { dni: string }) => {
    dispatch({ type: SET_DNI, payload: { dni } })
  }, [])

  const setIdentityCard = useCallback(({ identityCard }: { identityCard: { front: File; back: File } }) => {
    dispatch({ type: SET_IDENTITY_CARD, payload: { identityCard } })
  }, [])

  const setCenso = useCallback(({ censo }: { censo: File }) => {
    dispatch({ type: SET_CENSO, payload: { censo } })
  }, [])

  return (
    <Provider
      value={{
        firstname,
        lastname,
        phone,
        facephoto,
        profession,
        dni,
        identityCard,
        censo,
        setName,
        setPhone,
        setAvatar,
        setProfession,
        setDNI,
        setIdentityCard,
        setCenso,
      }}
    >
      {children}
    </Provider>
  )
}

export const useProfileAssistant = () => {
  const ctx = useContext(ProfileAssistantContext)
  if (!ctx) {
    throw Error('The `useProfileAssistant` hook must be called from a descendent of the `ProfileAssistantProvider`.')
  }

  return ctx
}
