import React, { useCallback, useState } from 'react'
import styles from './CancelGUCSLScreen.module.css'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Illustration } from './assets/warning_illustration.svg'
import { toast } from 'react-toastify'
import Screen from '../../../../../components/molecules/screen/Screen'
import Text from '../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../types/Components.types'
import Button from '../../../../../components/atoms/button/Button'
import { useProfile, useUpdateGUCSLMutation } from '../../../../../modules/auth/hooks'
import routes from '../../../../../config/routes'
import LoadingData from '../../../../../components/molecules/loadingData/LoadingData'

const CancelGUCSLScreen = ({ ...rest }) => {
  const history = useHistory()
  const [isWorking, setIsWorking] = useState(false)
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const updateProfile = useUpdateGUCSLMutation()

  const handleConfirmClick = useCallback(() => {
    setIsWorking(true)
    updateProfile.mutate(
      { userId: profile!.id, isGUCSL: false },
      {
        onSuccess: () => {
          setIsWorking(false)
          toast.success('Tu baja del grupo ha sido tramitada')
          history.replace(routes.app)
        },

        onError: () => {
          setIsWorking(false)
          toast.error('Oops! No hemos podido darte de baja del grupo en este momento')
        },
      },
    )
  }, [history, profile, updateProfile])

  const handleCancelClick = useCallback(() => {
    history.goBack()
  }, [history])

  if (isLoadingProfile) return <LoadingData />

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <Illustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
            Un momento
          </Text>
          <Text align="center">
            Si te das de baja del GUCSL dejarás de tener las ventajas asociadas al grupo. ¿Estás seguro de querer
            abandonarlo?
          </Text>
        </div>

        <div className={styles.actions}>
          <Button
            inverted
            variant={VARIANTS.max}
            className={styles.action}
            onClick={handleCancelClick}
            disabled={isWorking}
          >
            ¡No!
          </Button>

          <Button
            variant={VARIANTS.negative}
            className={styles.action}
            onClick={handleConfirmClick}
            disabled={isWorking}
            isLoading={isWorking}
          >
            Sí
          </Button>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(CancelGUCSLScreen)
