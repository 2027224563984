import { useFormik } from 'formik'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import Input from '../../../../../../components/atoms/input/Input'
import Text from '../../../../../../components/atoms/text/Text'
import FormField from '../../../../../../components/molecules/formField/FormField'
import { useProfileAssistant } from '../../../../../../providers/profileAssistant'
import { VARIANTS } from '../../../../../../types/Components.types'
import { IStepProps, IStepRef } from '../types'
import styles from './NameStep.module.css'
import nameFormSchema from './schema/name'

const NameStep = forwardRef<IStepRef, IStepProps>(({ onSubmit, onIsSubmittingChange }, ref) => {
  const { firstname, lastname, setName } = useProfileAssistant()

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting } = useFormik({
    initialValues: {
      firstname,
      lastname,
    },
    validationSchema: nameFormSchema,
    onSubmit: (values) => {
      return new Promise<void>((resolve) => {
        setName(values)
        resolve()
        onSubmit?.()
      })
    },
  })

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }))

  useEffect(() => {
    onIsSubmittingChange?.(isSubmitting)
  }, [onIsSubmittingChange, isSubmitting])

  return (
    <div className={styles.content}>
      <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
        ¿Nos dices tu nombre y apellidos?
      </Text>

      <form onSubmit={handleSubmit}>
        <FormField
          field={
            <Input
              name="firstname"
              placeholder="Nombre"
              value={values.firstname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          error={touched.firstname ? errors.firstname : null}
        />

        <FormField
          field={
            <Input
              name="lastname"
              placeholder="Apellidos"
              value={values.lastname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          error={touched.lastname ? errors.lastname : null}
        />
      </form>
    </div>
  )
})

export default NameStep
