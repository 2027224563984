import React from 'react'
import { useProfile } from '../../../modules/auth/hooks'
import { usePurchasedVoucherWithPaidData } from '../../../modules/vouchers/hooks'
import { VARIANTS } from '../../../types/Components.types'
import Button from '../../atoms/button/Button'

interface IProps {
  voucherId: string
  className?: string
  innerClassName?: string
}

const RedsysButton = ({ voucherId, className, innerClassName }: IProps) => {
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: voucher, isLoading: isLoadingPaidData } = usePurchasedVoucherWithPaidData({
    userId: profile?.id,
    voucherId,
  })
  const isLoading = isLoadingProfile || isLoadingPaidData

  if (isLoading || !voucher) {
    return null
  }

  const paidData = voucher?.paidData?.[0]
  return (
    <form className={className} action={paidData?.paymentUri} method="POST">
      <input hidden type="text" name="Ds_SignatureVersion" defaultValue="HMAC_SHA256_V1" />
      <input
        hidden
        type="text"
        id="Ds_MerchantParameters"
        name="Ds_MerchantParameters"
        defaultValue={paidData?.merchantParameters}
      />
      <input hidden type="text" id="Ds_Signature" name="Ds_Signature" defaultValue={paidData?.signature} />

      <Button variant={VARIANTS.primary} className={innerClassName} type="submit">
        Pagar ahora
      </Button>
    </form>
  )
}

export default React.memo(RedsysButton)
