import React, { useCallback } from 'react'
import styles from './BookSeatSuccessScreen.module.css'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Illustration } from './assets/success_illustration.svg'
import { useBookAssistant } from '../../../../../providers/bookAssistant'
import routes from '../../../../../config/routes'
import Screen from '../../../../../components/molecules/screen/Screen'
import Text from '../../../../../components/atoms/text/Text'
import Button from '../../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../../types/Components.types'

const BookSeatSuccessScreen = () => {
  const history = useHistory()
  const { isEligibleForGUCSL, clear } = useBookAssistant()

  const handleContinueClick = useCallback(() => {
    if (isEligibleForGUCSL) {
      clear?.()
      return history.replace(routes.gucsl, { backDisabled: true })
    }
    clear?.()
    history.replace(routes.app)
  }, [isEligibleForGUCSL, history, clear])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <Illustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
            ¡Listo!
          </Text>
          <Text align="center">Tu sitio se ha reservado correctamente. Ya puedes disfrutar del coworking</Text>
        </div>

        <Button variant={VARIANTS.primary} className={styles.action} onClick={handleContinueClick}>
          Continuar
        </Button>
      </div>
    </Screen>
  )
}

export default React.memo(BookSeatSuccessScreen)
