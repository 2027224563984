import FetchHelper from '../FetchHelper'

class VouchersAPI {
  static getVouchers({ queryParams }: { queryParams: URLSearchParams }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`vouchers?${queryParams.toString()}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getPurchasedVouchers({ userId }: { userId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/vouchers`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getPurchasedVoucher({ userId, voucherId }: { userId: string; voucherId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/vouchers/${voucherId}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static purchaseVoucher({
    userId,
    voucherId,
    seatType,
    months,
    userSeatId,
    callback_ok,
    callback_ko,
    reservationData,
  }: {
    userId: string
    voucherId: string
    seatType: string
    months: number
    userSeatId: string
    callback_ok: string
    callback_ko: string
    reservationData: {
      type: string
      schedule: {
        from: number
        to: number
      }
      seatId: string
      seatType: string
      months: number
      userId: string
      date: string
    }
  }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/vouchers`, {
      method: 'POST',
      headers: generateHeaders({ headers: {} }),
      body: {
        voucherId,
        seatType,
        months,
        userSeatId,
        callback_ok,
        callback_ko,
        reservationData,
      },
    })
  }

  static payVoucher({
    userId,
    voucherId,
    callback_ok,
    callback_ko,
  }: {
    userId: string
    voucherId: string
    callback_ok: string
    callback_ko: string
  }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/vouchers/${voucherId}/pay`, {
      method: 'POST',
      headers: generateHeaders({ headers: {} }),
      body: {
        callback_ok,
        callback_ko,
      },
    })
  }

  static renewVoucher({
    userId,
    voucherId,
    callback_ok,
    callback_ko,
  }: {
    userId: string
    voucherId: string
    callback_ok: string
    callback_ko: string
  }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/vouchers/${voucherId}/renew`, {
      method: 'POST',
      headers: generateHeaders({ headers: {} }),
      body: {
        callback_ok,
        callback_ko,
      },
    })
  }

  static redsys({ signature, merchantParameters }: { signature: string; merchantParameters: string }) {
    const { fetch, generateHeaders } = FetchHelper
    const formData = new FormData()
    formData.append('Ds_Signature', signature)
    formData.append('Ds_MerchantParameters', merchantParameters)
    formData.append('Ds_SignatureVersion', 'HMAC_SHA256_V1')
    return fetch(`https://sis-t.redsys.es:25443/sis/realizarPago`, {
      method: 'POST',
      mode: 'no-cors',
      headers: generateHeaders({ headers: {}, useDefaultHeaders: false, isAuthenticated: false }),
      body: formData,
      externalUrl: true,
    })
  }

  static confirmPayment({
    bank,
    paymentId,
    queryParams,
  }: {
    bank: string
    paymentId: string
    queryParams: URLSearchParams
  }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`payments/${bank}/ok/${paymentId}?${queryParams.toString()}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static downloadLastInvoice({ userId, voucherId }: { userId: string; voucherId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/vouchers/${voucherId}/invoice`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static downloadInvoice({ userId, voucherId, paymentId }: { userId: string; voucherId: string; paymentId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/vouchers/${voucherId}/invoice/${paymentId}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static cancelAutoRenew({ userId, voucherId }: { userId: string; voucherId: string }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`users/${userId}/vouchers/${voucherId}`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: {
        autoRenew: false,
      },
    })
  }
}

export default VouchersAPI
