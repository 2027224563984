import React, { useCallback } from 'react'
import styles from './OnBoardingCompleteScreen.module.css'
import { useHistory } from 'react-router-dom'
import { ReactComponent as CompleteIllustration } from './assets/complete_illustration.svg'
import Screen from '../../../../components/molecules/screen/Screen'
import routes from '../../../../config/routes'
import Text from '../../../../components/atoms/text/Text'
import Button from '../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../types/Components.types'

interface IProps {
  onSubmit: () => void
  onIsSubmittingChange: (isSubmitting: boolean) => void
}

const OnBoardingCompleteScreen = ({ onSubmit, onIsSubmittingChange }: IProps) => {
  const history = useHistory()

  const goToHome = useCallback(() => {
    history.replace(routes.app)
  }, [history])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <CompleteIllustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
            Ya estás listo
          </Text>
          <Text className={styles.description} align="center">
            ¿A que esperas para reservar tu espacio de trabajo en el coworking?
          </Text>
        </div>

        <Button variant={VARIANTS.primary} className={styles.action} onClick={goToHome}>
          ¡Vamos!
        </Button>
      </div>
    </Screen>
  )
}

export default React.memo(OnBoardingCompleteScreen)
