import React, { Fragment } from 'react'
import Radio from '../../atoms/radio/Radio'
import FormField from '../formField/FormField'

interface IProps {
  name: string
  value: string
  options: {
    value: string
    label: string
  }[]
  touched?: boolean
  error?: string
  className?: string
  size?: 'medium' | 'large' | 'small' | 'xsmall'
  onChange?: (name: string, value: React.InputHTMLAttributes<HTMLInputElement>['value']) => void
  onBlur: (e: React.FocusEvent<any, Element>) => void
  renderItem?: (props: {
    name: string
    option: {
      value: string
      label: string
    }
  }) => React.ReactNode
}

const RadioGroup = ({ name, value, options = [], touched, error, className, size, renderItem, ...rest }: IProps) => {
  return (
    <Fragment>
      {options.map((option) => {
        return (
          <FormField
            key={`radio-${option.value}`}
            size={size}
            className={className}
            field={
              renderItem ? (
                renderItem({ name, option, ...rest })
              ) : (
                <Radio
                  name={name}
                  isChecked={option.value === value}
                  label={option.label}
                  value={option.value}
                  {...rest}
                />
              )
            }
            error={touched && error ? error : null}
          />
        )
      })}
    </Fragment>
  )
}

export default React.memo(RadioGroup)
