import React from 'react'
import styles from './TouchableOpacity.module.css'
import c from 'classnames'

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  children: React.ReactNode
}

const TouchableOpacity = ({ className, children, ...rest }: IProps) => {
  return (
    <button className={c(styles.container, className)} {...rest}>
      {children}
    </button>
  )
}

export default React.memo(TouchableOpacity)
