import { useEffect, useRef } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ErrorBoundary from '../../../components/molecules/errorBoundary/ErrorBoundary'
import PrivateRoute from '../../../components/molecules/privateRoute/PrivateRoute'
import routes from '../../../config/routes'
import { useIsLogged } from '../../../modules/auth/hooks'
import { Notifications as NotificationsService } from '../../../modules/notifications/service'
import LoginScreen from '../auth/login/LoginScreen'
import RecoverPasswordCompleteScreen from '../auth/password/complete/RecoverPasswordCompleteScreen'
import ForgotPasswordEmailSentScreen from '../auth/password/emailSent/ForgotPasswordEmailSentScreen'
import ForgotPasswordScreen from '../auth/password/forgot/ForgotPasswordScreen'
import RecoverPasswordScreen from '../auth/password/recover/RecoverPasswordScreen'
import RegisterScreen from '../auth/register/RegisterScreen'
import CoworkingValidationPendingScreen from '../auth/validation/coworkingValidationPending/CoworkingValidationPendingScreen'
import EmailValidationPendingScreen from '../auth/validation/emailValidationPending/EmailValidationPendingScreen'
import ValidateScreen from '../auth/validation/validate/ValidateScreen'
import WelcomeScreen from '../auth/welcome/WelcomeScreen'
import WelcomeRegisterScreen from '../auth/welcomeRegister/WelcomeRegisterScreen'
import CookiesScreen from '../cookies/CookiesScreen'
import GDPRScreen from '../gdpr/GDPRScreen'
import GUCSLScreen from '../gucsl/GUCSLScreen'
import HomeScreen from '../home/HomeScreen'
import OnBoardingCompleteScreen from '../onboarding/complete/OnBoardingCompleteScreen'
import OnBoardingCreationScreen from '../onboarding/creation/OnBoardingCreationScreen'
import OnBoardingHomeScreen from '../onboarding/home/OnBoardingHomeScreen'
import OnBoardingProfileScreen from '../onboarding/profile/OnBoardingProfileScreen'
import PaymentKOScreen from '../payments/ko/KO'
import PaymentOkScreen from '../payments/ok/Ok'
import PaymentRenewKOScreen from '../payments/renew/ko/KO'
import PaymentRenewOkScreen from '../payments/renew/ok/Ok'
import GeolocationScreen from '../permissions/geolocation/GeolocationScreen'
import NotificationsScreen from '../permissions/notifications/NotificationsScreen'
import EditProfileScreen from '../profile/edit/EditProfileScreen'
import ProfileScreen from '../profile/home/ProfileScreen'
import ReservationCancelScreen from '../reservations/cancel/ReservationCancelScreen'
import ReservationDetailsScreen from '../reservations/details/ReservationDetailsScreen'
import ReservationsListScreen from '../reservations/list/ReservationsListScreen'
import MeetingRoomChangeSlotScreen from '../reservations/meetingRoom/change/MeetingRoomChangeSlotScreen'
import BookMeetingRoomScreen from '../reservations/meetingRoom/details/BookMeetingRoomScreen'
import BookMeetingRoomSuccessScreen from '../reservations/meetingRoom/success/BookMeetingRoomSuccessScreen'
import MeetingRoomDetailsScreen from '../reservations/meetingRoomDetails/MeetingRoomDetailsScreen'
import ReservationPaymentScreen from '../reservations/payment/ReservationPaymentScreen'
import BookPlaceScreen from '../reservations/place/details/BookPlaceScreen'
import BookChangeSeatScreen from '../reservations/seat/change/BookChangeSeatScreen'
import BookSeatConfirmationScreen from '../reservations/seat/confirmation/BookSeatConfirmationScreen'
import BookSeatScreen from '../reservations/seat/details/BookSeatScreen'
import BookSeatPlaceScreen from '../reservations/seat/place/BookSeatPlaceScreen'
import BookSeatSuccessScreen from '../reservations/seat/success/BookSeatSuccessScreen'
import ReservationVouchersScreen from '../reservations/vouchers/ReservationVouchersScreen'
import WorkmateProfileScreen from '../reservations/workmateProfile/WorkmateProfileScreen'
import ReservationWorkmatesScreen from '../reservations/workmates/ReservationWorkmatesScreen'
import BillingAddressScreen from '../settings/account/billingAddress/BillingAddressScreen'
import CancelGUCSLScreen from '../settings/account/cancelGUCSL/CancelGUCSLScreen'
import GUCSLBenefitsScreen from '../settings/account/gucsl/GUCSLBenefitsScreen'
import InvoicesByYearScreen from '../settings/account/invoices/byYear/InvoicesByYearScreen'
import InvoicesHomeScreen from '../settings/account/invoices/home/InvoicesHomeScreen'
import PrivacyScreen from '../settings/account/privacy/PrivacyScreen'
import SavedCardsScreen from '../settings/account/savedCards/SavedCardsScreen'
import VouchersScreen from '../settings/account/vouchers/home/VouchersScreen'
import VoucherInvoicesScreen from '../settings/account/vouchers/invoices/VoucherInvoicesScreen'
import ReneweVoucherScreen from '../settings/account/vouchers/renew/RenewVoucherScreen'
import VoucherScreen from '../settings/account/vouchers/voucher/VoucherScreen'
import UpdateAttachmentsScreen from '../settings/attachments/update/UpdateAttachmentsScreen'
import UpdateDNIScreen from '../settings/attachments/updateDNI/UpdateDNIScreen'
import SettingsScreen from '../settings/home/SettingsScreen'
import SplashScreen from '../splash/SplashScreen'
import styles from './AppRootScreen.module.css'

export const AppRootScreen = () => {
  const history = useHistory()
  const { isLogged } = useIsLogged()
  const pushRegisterd = useRef(false)

  useEffect(() => {
    if (pushRegisterd.current || !isLogged) {
      return
    }

    pushRegisterd.current = true
    NotificationsService.registerPush()
  }, [isLogged])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div id="app-wrapper" className={styles.appWrapper}>
          <ErrorBoundary history={history}>
            <Route exact path={routes.gdpr} component={GDPRScreen} />
            <Route exact path={routes.cookies} component={CookiesScreen} />

            <Route exact path={routes.app} component={SplashScreen} />
            <Route exact path={routes.welcome} component={WelcomeScreen} />
            <Route exact path={routes.registerWelcome} component={WelcomeRegisterScreen} />
            <Route exact path={routes.register} component={RegisterScreen} />
            <Route exact path={routes.login} component={LoginScreen} />

            <Route exact path={routes.password.forgot} component={ForgotPasswordScreen} />
            <Route exact path={routes.password.emailSent} component={ForgotPasswordEmailSentScreen} />
            <Route exact path={routes.password.recover} component={RecoverPasswordScreen} />
            <Route exact path={routes.password.complete} component={RecoverPasswordCompleteScreen} />

            <Route exact path={routes.validation.validate} component={ValidateScreen} />
            <PrivateRoute exact path={routes.validation.emailPending} component={EmailValidationPendingScreen} />
            <PrivateRoute
              exact
              path={routes.validation.coworkingPending}
              component={CoworkingValidationPendingScreen}
            />

            <Route exact path={routes.geolocation} component={GeolocationScreen} />
            <Route exact path={routes.notifications} component={NotificationsScreen} />

            <PrivateRoute exact path={routes.home} component={HomeScreen} />

            <PrivateRoute exact path={routes.gucsl} component={GUCSLScreen} />

            <PrivateRoute exact path={routes.payments.ok} component={PaymentOkScreen} />
            <PrivateRoute exact path={routes.payments.ko} component={PaymentKOScreen} />

            <PrivateRoute exact path={routes.payments.renew.ok} component={PaymentRenewOkScreen} />
            <PrivateRoute exact path={routes.payments.renew.ko} component={PaymentRenewKOScreen} />

            <PrivateRoute exact path={routes.settings.home} component={SettingsScreen} />
            <PrivateRoute exact path={routes.settings.account.privacy} component={PrivacyScreen} />
            <PrivateRoute exact path={routes.settings.account.billingAddress} component={BillingAddressScreen} />
            <PrivateRoute exact path={routes.settings.account.savedCards} component={SavedCardsScreen} />
            <PrivateRoute exact path={routes.settings.account.vouchers.home} component={VouchersScreen} />
            <PrivateRoute exact path={routes.settings.account.invoices.home} component={InvoicesHomeScreen} />
            <PrivateRoute exact path={routes.settings.account.invoices.byYear} component={InvoicesByYearScreen} />
            <PrivateRoute exact path={routes.settings.account.vouchers.byId} component={VoucherScreen} />
            <PrivateRoute exact path={routes.settings.account.vouchers.invoices} component={VoucherInvoicesScreen} />
            <PrivateRoute exact path={routes.settings.account.gucsl.member} component={GUCSLBenefitsScreen} />
            <PrivateRoute exact path={routes.settings.account.gucsl.cancel} component={CancelGUCSLScreen} />
            <PrivateRoute exact path={routes.settings.account.attachments.update} component={UpdateAttachmentsScreen} />
            <PrivateRoute exact path={routes.settings.account.attachments.dni} component={UpdateDNIScreen} />

            <PrivateRoute exact path={routes.book.new.seat.renewWarning} component={ReneweVoucherScreen} />
            <PrivateRoute exact path={routes.book.new.seat.details} component={BookSeatScreen} />
            <PrivateRoute exact path={routes.book.new.seat.place} component={BookSeatPlaceScreen} />
            <PrivateRoute exact path={routes.book.new.seat.confirmation} component={BookSeatConfirmationScreen} />
            <PrivateRoute exact path={routes.book.new.seat.success} component={BookSeatSuccessScreen} />

            <PrivateRoute exact path={routes.book.new.place.details} component={BookPlaceScreen} />

            <PrivateRoute exact path={routes.book.new.meetingsRoom.details} component={BookMeetingRoomScreen} />
            <PrivateRoute exact path={routes.book.new.meetingsRoom.success} component={BookMeetingRoomSuccessScreen} />

            <PrivateRoute exact path={routes.book.list} component={ReservationsListScreen} />
            <PrivateRoute exact path={routes.book.details} component={ReservationDetailsScreen} />
            <PrivateRoute exact path={routes.book.meetingRoomDetails} component={MeetingRoomDetailsScreen} />
            <PrivateRoute exact path={routes.book.changeMeetingRoom} component={MeetingRoomChangeSlotScreen} />
            <PrivateRoute exact path={routes.book.changeSeat} component={BookChangeSeatScreen} />
            <PrivateRoute exact path={routes.book.workmates} component={ReservationWorkmatesScreen} />
            <PrivateRoute exact path={routes.book.workmate} component={WorkmateProfileScreen} />
            <PrivateRoute exact path={routes.book.cancel} component={ReservationCancelScreen} />

            <PrivateRoute exact path={routes.book.vouchers} component={ReservationVouchersScreen} />
            <PrivateRoute exact path={routes.book.payment} component={ReservationPaymentScreen} />

            <PrivateRoute exact path={routes.profile.details} component={ProfileScreen} />
            <PrivateRoute exact path={routes.profile.edit} component={EditProfileScreen} />

            <PrivateRoute exact path={routes.onboarding.home} component={OnBoardingHomeScreen} />
            <PrivateRoute exact path={routes.onboarding.profile} component={OnBoardingProfileScreen} />
            <PrivateRoute exact path={routes.onboarding.creation} component={OnBoardingCreationScreen} />
            <PrivateRoute exact path={routes.onboarding.complete} component={OnBoardingCompleteScreen} />

            <ToastContainer
              className={styles.toasts}
              position="top-center"
              newestOnTop
              draggable
              limit={1}
              transition={Slide}
            />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}
