import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconDownload = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M7.25 29.247h15.402c1.431 0 2.507-.358 3.228-1.074.72-.716 1.081-1.777 1.081-3.182V11.714c0-1.407-.36-2.468-1.08-3.184-.722-.716-1.798-1.074-3.229-1.074h-3.671V9.86h3.576c.637 0 1.129.169 1.477.507.348.337.522.843.522 1.518v12.931c0 .666-.174 1.168-.522 1.506-.348.338-.84.507-1.477.507H7.346c-.637 0-1.127-.169-1.47-.507-.345-.338-.517-.84-.517-1.506v-12.93c0-.675.172-1.18.516-1.518.344-.338.834-.507 1.47-.507h3.602V7.456H7.25c-1.422 0-2.496.356-3.221 1.068-.725.711-1.088 1.775-1.088 3.19V24.99c0 1.405.363 2.466 1.088 3.182.725.716 1.799 1.074 3.221 1.074zm7.708-8.742c.146 0 .288-.03.427-.087.138-.058.28-.16.426-.305l4.517-4.357c.22-.221.331-.473.331-.755 0-.294-.1-.535-.3-.723a1.056 1.056 0 00-.749-.28.992.992 0 00-.777.349l-1.946 2.069-.883.967.11-1.991V1.376c0-.3-.115-.563-.344-.79a1.12 1.12 0 00-.812-.339c-.32 0-.596.113-.825.34-.23.226-.344.49-.344.789v14.016l.11 2.003-.883-.98-1.946-2.068a1.007 1.007 0 00-.792-.35 1.03 1.03 0 00-.74.281.954.954 0 00-.294.723c0 .282.11.534.33.755l4.518 4.357c.146.146.288.247.426.305.138.058.285.087.44.087z" />
      </svg>
    </i>
  )
}

export default IconDownload
