import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconHome = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M23.352 28.25c.918 0 1.633-.25 2.144-.752.511-.5.767-1.196.767-2.085V13.704l1.587 1.33c.148.122.301.224.459.307.157.082.332.124.524.124.323 0 .599-.096.826-.288.227-.191.341-.453.341-.784 0-.384-.144-.697-.433-.941l-3.304-2.766V5.49c0-.278-.083-.499-.249-.66-.166-.161-.385-.242-.656-.242h-1.534a.928.928 0 00-.662.242c-.17.161-.256.382-.256.66v2.386l-6.254-5.235C16.154 2.214 15.61 2 15.02 2c-.59 0-1.139.214-1.646.64L.46 13.453c-.306.252-.459.557-.459.915 0 .287.107.542.321.765.214.222.505.333.872.333.184 0 .356-.042.518-.124.162-.083.308-.185.44-.308l1.52-1.27v11.65c0 .89.254 1.584.76 2.085.508.501 1.22.752 2.138.752h16.783zm-.55-2.248h-4.223v-8.184c0-.288-.085-.519-.255-.693-.17-.174-.4-.261-.689-.261h-5.231c-.289 0-.522.087-.702.261-.179.174-.268.405-.268.693v8.184H7.12c-.385 0-.682-.105-.892-.314-.21-.21-.315-.514-.315-.915V11.89l8.615-7.197a.751.751 0 01.485-.203.715.715 0 01.498.203l8.496 7.12v12.96c0 .4-.102.706-.307.915-.206.209-.505.314-.898.314z" />
      </svg>
    </i>
  )
}

export default IconHome
