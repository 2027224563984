import React from 'react'
import Button from '../../../../components/atoms/button/Button'
import Text from '../../../../components/atoms/text/Text'
import styles from './Cookies.module.css'
import { useHistory } from 'react-router-dom'
import routes from '../../../../config/routes'
import { VARIANTS } from '../../../../types/Components.types'

const Cookies = () => {
  const history = useHistory()

  const goToCookies = React.useCallback(() => {
    history.push(routes.cookies)
  }, [history])

  return (
    <section className={styles.container}>
      <Text weight="500" size="xl" className={styles.title} align="center">
        ¿Cookies? Sí, pero sólo las necesarias
      </Text>

      <Text size="ml" align="center">
        Seguimos una política de uso mínimo de cookies, nada de fines comerciales, analítica o permisos innecesarios.
        Únicamente te pediremos aquello que sea imprescindible para usar la aplicación.
      </Text>

      <Button variant={VARIANTS.max} inverted onClick={goToCookies} className={styles.cta}>
        Enséñamelo
      </Button>
    </section>
  )
}

export default React.memo(Cookies)
