import React, { useCallback } from 'react'
import styles from './DatePicker.module.css'
import { DateTime } from 'luxon'
import { useBookAssistant } from '../../../../../../../providers/bookAssistant'
import { useCardTransition } from '../../../../../../../providers/cardTransition/cardTransition'
import Text from '../../../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../../../types/Components.types'
import Calendar from '../../../../../../../components/atoms/calendar/Calendar'
import Button from '../../../../../../../components/atoms/button/Button'

const DatePicker = ({ ...rest }) => {
  const { day, setDay } = useBookAssistant()
  const { closeCard } = useCardTransition()

  const handleContinueClick = useCallback(() => {
    closeCard?.()
  }, [closeCard])

  const handleDaySelected = useCallback(
    (value: Date) => {
      setDay({ day: value })
    },
    [setDay],
  )

  let today = DateTime.local().startOf('day').plus({ days: 1 })
  return (
    <div className={styles.container}>
      <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
        Elige el día
      </Text>

      <div className={styles.content}>
        <Calendar
          className={styles.calendar}
          selected={day}
          onSelect={handleDaySelected}
          minDate={today.toJSDate()}
          {...rest}
        />
      </div>

      <div className={styles.actions}>
        <Button variant={VARIANTS.primary} className={styles.action} onClick={handleContinueClick} disabled={!day}>
          Confirmar
        </Button>
      </div>
    </div>
  )
}

export default React.memo(DatePicker)
