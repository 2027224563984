import c from 'classnames'
import React, { Reducer, useCallback, useEffect, useReducer, useRef } from 'react'
import { Geolocation } from '../../../modules/geolocation/service'
import Card from '../../atoms/card/Card'
import Loader from '../../atoms/loader/Loader'
import Text from '../../atoms/text/Text'
import { CLEAR_ERROR, SET_POSITION, SET_SUCCESS, SET_WORKING } from './actions'
import styles from './UnlockSlider.module.css'
import { toast } from 'react-toastify'
import { useOpenCoworkingMutation } from '../../../modules/seats/hooks'
import { useProfile } from '../../../modules/auth/hooks'
import { TYPES } from '../../../providers/bookAssistant/types'
import { useOpenCoworkingMeetingRoomMutation } from '../../../modules/meetingRoom/hooks'

interface IState {
  position: number
  isWorking: boolean
  success: boolean
  showError: boolean
}

interface ISetPositionAction {
  type: 'SET_POSITION'
  payload: { position: number }
}

interface ISetWorkingAction {
  type: 'SET_WORKING'
  payload: { isWorking: boolean; success?: boolean; showError?: boolean }
}

interface ISetSuccessAction {
  type: 'SET_SUCCESS'
  payload: { success: boolean }
}

interface IClearErrorAction {
  type: 'CLEAR_ERROR'
  payload: undefined | null
}

type IAction = ISetPositionAction | ISetWorkingAction | ISetSuccessAction | IClearErrorAction

const reducer = (prevState: IState, { type, payload }: IAction) => {
  switch (type) {
    case SET_POSITION:
      return {
        ...prevState,
        position: payload.position,
      }

    case SET_WORKING:
      return {
        ...prevState,
        isWorking: payload.isWorking,
        success: payload.success ? payload.success : prevState.success,
        position: 0,
        showError: payload.showError ?? prevState.showError,
      }

    case SET_SUCCESS:
      return {
        ...prevState,
        success: payload.success,
      }

    case CLEAR_ERROR:
      return {
        ...prevState,
        showError: false,
      }

    default:
      return prevState
  }
}

interface IProps {
  className?: string
  book: any
}

interface IError {
  reason?: string
}

const UnlockSlider = ({ className, book }: IProps) => {
  const { data: profile } = useProfile()
  const [{ position, isWorking, success, showError }, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, {
    position: 0,
    isWorking: false,
    success: false,
    showError: false,
  })

  const interval = useRef<NodeJS.Timer | null>(null)
  const { mutateAsync: openCoworkingReservation } = useOpenCoworkingMutation()
  const { mutateAsync: openCoworkingMeetingRoom } = useOpenCoworkingMeetingRoomMutation()

  const handleRangeChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: SET_POSITION,
      payload: {
        position: parseInt(evt.target.value),
      },
    })
  }, [])

  const handleRangeChangeEnd = useCallback(
    async (evt: React.MouseEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement>) => {
      const target: HTMLInputElement = evt.target as HTMLInputElement
      const sanitizedValue = parseInt(target.value)
      if (sanitizedValue !== 100) {
        interval.current = setInterval(() => {
          if (sanitizedValue !== 0) {
            dispatch({
              type: SET_POSITION,
              payload: {
                position: sanitizedValue - 1,
              },
            })
          } else {
            clearInterval(interval.current as NodeJS.Timer)
          }
        }, 1)
        return
      }

      dispatch({
        type: SET_WORKING,
        payload: {
          isWorking: true,
        },
      })
      let position
      let dummyCoordinates = false
      try {
        position = await Geolocation.getCurrentPosition()
        console.info(position)
      } catch (error) {
        console.info(error)
        dummyCoordinates = true
        position = {
          coords: {
            latitude: 40.592192,
            longitude: -4.1429322,
            accuracy: 0,
            altitude: 0,
            altitudeAccuracy: 0,
            heading: 0,
            speed: 0,
          },
          timestamp: Date.now(),
        }
      }

      try {
        const { type } = book
        if (type === TYPES.MEETING_ROOM) {
          const meetingRoomId = book.id
          await openCoworkingMeetingRoom({
            userId: profile!.id,
            meetingRoomId,
            coordinates: position,
            dummyCoordinates,
          })
        } else {
          const seatId = book.seat.id
          await openCoworkingReservation({ userId: profile!.id, seatId, coordinates: position, dummyCoordinates })
        }

        dispatch({
          type: SET_WORKING,
          payload: {
            isWorking: false,
            success: true,
          },
        })

        setTimeout(() => {
          dispatch({
            type: SET_SUCCESS,
            payload: {
              success: false,
            },
          })
        }, 4000)
      } catch (error) {
        console.info(error)
        dispatch({
          type: SET_WORKING,
          payload: {
            isWorking: false,
            success: false,
            showError: true,
          },
        })

        let message = 'Recuerda que debes activar la ubicación y estar cerca del coworking para poder acceder.'
        if ((error as IError)?.reason === 'TOO_FAR') {
          message = 'Parece que hay problemas al acceder a tu ubicación. Prueba a conectarte a la WIFI del coworking'
        }
        toast.error(message)
      }
    },
    [book, openCoworkingMeetingRoom, openCoworkingReservation, profile],
  )

  useEffect(() => {
    if (!showError) {
      return
    }

    const timeoutId = setTimeout(() => {
      dispatch({
        type: CLEAR_ERROR,
        payload: null,
      })
    }, 5000)

    return () => clearTimeout(timeoutId)
  }, [showError])

  useEffect(() => {
    return () => clearInterval(interval.current as NodeJS.Timer)
  }, [])

  return (
    <Card className={c(styles.container, (isWorking || success) && styles.working, className)}>
      <div className={styles.content}>
        {!isWorking && !success && (
          <Text className={styles.unlockText} size="s" weight="600">
            Desliza para <br />
            desbloquear la puerta
          </Text>
        )}

        {isWorking && <Loader variant="primary" />}

        {!isWorking && success && (
          <Text className={styles.successText} size="s" weight="600">
            ¡La puerta se ha abierto!
          </Text>
        )}
      </div>
      <input
        className={styles.latch}
        type="range"
        onChange={handleRangeChange}
        onMouseUp={handleRangeChangeEnd}
        onTouchEnd={handleRangeChangeEnd}
        value={position}
        max={100}
      />

      <div className={c(styles.errorWrapper, showError && styles.shown)}>
        <Text size="sm" align="center" weight="600">
          ¡Conéctate a la WIFI del Coworking para abrir la puerta!
        </Text>
      </div>
    </Card>
  )
}

export default React.memo(UnlockSlider)
