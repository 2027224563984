import React, { useState, useEffect, useRef } from 'react'
import { debounce } from '../../../../../utils'
import styles from './Decorator.module.css'

const Decorator = () => {
  const [dimensions, setDimensions] = useState(2 * window.innerWidth)
  const onResize = useRef(
    debounce(() => {
      setDimensions(2 * window.innerWidth)
    }, 300),
  )

  useEffect(() => {
    const onResizeFn = onResize.current
    window.addEventListener('resize', onResizeFn)
    return () => {
      window.removeEventListener('resize', onResizeFn)
    }
  }, [])

  return (
    <div className={styles.container}>
      <span className={styles.decorator} style={{ width: `${dimensions}px`, height: `${dimensions}px` }}></span>
    </div>
  )
}

export default React.memo(Decorator)
