import c from 'classnames'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BackButton from '../../../../../../components/atoms/backButton/BackButton'
import Button from '../../../../../../components/atoms/button/Button'
import Tag from '../../../../../../components/atoms/tag/Tag'
import Text from '../../../../../../components/atoms/text/Text'
import LinkRow from '../../../../../../components/molecules/linkRow/LinkRow'
import LoadingData from '../../../../../../components/molecules/loadingData/LoadingData'
import RedsysButton from '../../../../../../components/molecules/redsysButton/RedsysButton'
import Screen from '../../../../../../components/molecules/screen/Screen'
import routes, { generateRoute } from '../../../../../../config/routes'
import { useProfile } from '../../../../../../modules/auth/hooks'
import { useReservationByVoucherId } from '../../../../../../modules/seats/hooks'
import {
  useDownloadVoucherInvoiceMutation,
  usePurchasedVoucher,
  useRenewVoucherMutation,
} from '../../../../../../modules/vouchers/hooks'
import {
  getBookAutoRenew,
  getBookMonths,
  getBookRenewedMonths,
  getHumanizedBookMonths,
  getVoucherPrice,
  isRenewable,
} from '../../../../../../modules/vouchers/utils'
import { VARIANTS } from '../../../../../../types/Components.types'
import styles from './VoucherScreen.module.css'

interface IParams {
  voucherId: string
}

const VoucherScreen = () => {
  const history = useHistory()
  const { voucherId } = useParams<IParams>()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: voucher, isLoading: isLoadingVoucher } = usePurchasedVoucher({ userId: profile?.id, voucherId })
  const { data: reservation, isLoading: isLoadingReservation } = useReservationByVoucherId({
    userId: profile?.id,
    voucherId,
  })
  const isLoading = isLoadingProfile || isLoadingVoucher || isLoadingReservation
  const isRenewAvailable = isRenewable({ voucher })
  const isMeetingRoomVoucher = !voucher?.spec?.seat
  const [isWorking, setIsWorking] = useState(false)

  const downloadInvoice = useDownloadVoucherInvoiceMutation()
  const renewVoucher = useRenewVoucherMutation()

  const handleDownloadInvoice = useCallback(() => {
    downloadInvoice.mutate({ userId: profile!.id, voucherId })
  }, [downloadInvoice, profile, voucherId])

  const handleRenewClick = useCallback(() => {
    setIsWorking(true)
    renewVoucher.mutate(
      { userId: profile!.id, voucherId },
      {
        onSuccess: (status) => {
          setIsWorking(false)
          if (status !== 202) {
            history.push(generateRoute(routes.book.payment, [{ name: 'voucherId', value: voucherId }]))
          }
        },
        onError: (error) => {
          console.info(error)
          setIsWorking(false)
        },
      },
    )
  }, [renewVoucher, profile, voucherId, history])

  const getVoucherDescription = useCallback(() => {
    if (isMeetingRoomVoucher) {
      const { spec } = voucher!
      const { meetingroom } = spec
      return `Podrás reservar la sala de reuniones durante ${meetingroom.hours} ${
        meetingroom.hours > 1 ? 'horas' : 'hora'
      }`
    }

    const { spec } = voucher
    const { type, days, period, schedule } = spec.seat

    switch (type) {
      case 'period':
        return `Podrás acceder al coworking durante ${period.months} ${
          period.months > 1 ? 'meses' : 'mes'
        }, en horario de ${schedule.from}h a ${schedule.to}h`

      case 'days':
        return `Podrás acceder al coworking durante ${days} ${days > 1 ? 'días' : 'día'}, en horario de ${
          schedule.from
        }h a ${schedule.to}h`

      default:
        return ''
    }
  }, [voucher, isMeetingRoomVoucher])

  const getExtraInfo = useCallback(() => {
    const { spec } = voucher!
    const { meetingroom } = spec
    if (!meetingroom || meetingroom.hours === 0) {
      return null
    }
    return `Incluye ${meetingroom.hours}h para la sala de reuniones`
  }, [voucher])

  const isPeriodVoucher = !isMeetingRoomVoucher ? voucher?.spec?.seat?.type === 'period' : false
  const autoRenew = getBookAutoRenew({ voucher })
  const monthsToRenew = getBookMonths({ voucher })
  const monthsRenewed = getBookRenewedMonths({ voucher })
  const humanizedMonthsToRenew = getHumanizedBookMonths({ voucher })

  const isConsumed = useMemo(() => {
    if (!isPeriodVoucher) {
      return voucher?.isConsumed
    }

    return monthsToRenew > 0 && monthsRenewed >= monthsToRenew
  }, [voucher, isPeriodVoucher, monthsRenewed, monthsToRenew])

  const lastDay = useMemo(() => {
    if (!isPeriodVoucher) {
      return null
    }

    return voucher?.lastSeat?.reserved_from ?? reservation?.seat?.reserved_from
  }, [isPeriodVoucher, voucher, reservation])

  const hasInvoices = (voucher?.Payments?.length ?? 0) > 0

  const price = useMemo(() => {
    if (!voucher) {
      return 0
    }
    if (voucher?.invoiceData?.total) {
      return voucher?.invoiceData?.total
    }
    const vPrice = getVoucherPrice({
      voucher: voucher?.voucher,
      seatType: voucher?.spec?.seatType,
      isEmpresa: profile?.dtoEmpresa ?? false,
      isFreeUse: profile?.dtoFreeUse ?? false,
      isPadron: profile?.padron ?? false,
    })

    return vPrice.withDiscountApplied
  }, [profile?.dtoEmpresa, profile?.dtoFreeUse, profile?.padron, voucher])

  if (!voucher || isLoading) {
    return <LoadingData />
  }

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Mi bono
          </Text>
        </div>

        <div className={styles.content}>
          <Text size="ml" weight="500">
            {voucher?.invoiceData?.subject ?? voucher?.voucher?.name}
          </Text>

          <Text className={styles.description}>{getVoucherDescription()}</Text>

          {!isMeetingRoomVoucher && <Text className={styles.extraInfo}>{getExtraInfo()}</Text>}

          <div className={styles.tags}>
            {isPeriodVoucher && (
              <Tag className={styles.tag} variant="secondary" textVariant={VARIANTS.white}>
                {humanizedMonthsToRenew > 0
                  ? `${humanizedMonthsToRenew} mes${humanizedMonthsToRenew === 1 ? '' : 'es'}`
                  : 'Indefinido'}
              </Tag>
            )}

            {!voucher.isPaid && (
              <Tag className={styles.tag} variant="negative" textVariant={VARIANTS.white}>
                Pago pendiente
              </Tag>
            )}

            {isConsumed && <Tag className={styles.tag}>Consumido</Tag>}
          </div>

          <div className={styles.options}>
            <div className={c(styles.row, styles.price)}>
              <Text>Precio del bono</Text>

              <Text weight="500">{price}€</Text>
            </div>

            {!isPeriodVoucher && (
              <div className={c(styles.row, styles.price)}>
                <Text>Días consumidos</Text>

                <Text weight="500">{voucher?.consumed?.seat?.days ?? 0}</Text>
              </div>
            )}

            {reservation && autoRenew && isPeriodVoucher && (
              <div className={c(styles.row, styles.autoRenew)}>
                <LinkRow path={generateRoute(routes.book.cancel, [{ name: 'bookId', value: reservation.seat.id }])}>
                  Cancelar renovación
                </LinkRow>
              </div>
            )}

            {reservation && autoRenew && isPeriodVoucher && lastDay && (
              <Text className={styles.autoRenewHelpText} size="s">
                Se cancelará automáticamente el{' '}
                <Text element="span" weight="600" size="s">
                  {DateTime.fromISO(lastDay).toLocaleString(DateTime.DATE_SHORT)}
                </Text>
              </Text>
            )}

            {hasInvoices && (
              <div className={c(styles.row)}>
                <LinkRow
                  className={styles.invoicesLink}
                  path={generateRoute(routes.settings.account.vouchers.invoices, [
                    { name: 'voucherId', value: voucherId },
                  ])}
                >
                  Ver facturas
                </LinkRow>
              </div>
            )}
          </div>

          <div className={styles.actions}>
            {voucher.isPaid && isRenewAvailable && (
              <Button
                variant={VARIANTS.primary}
                className={styles.action}
                onClick={handleRenewClick}
                isLoading={isWorking}
              >
                Renovar siguiente mes
              </Button>
            )}

            {voucher.isPaid && (
              <Button inverted variant={VARIANTS.max} className={styles.action} onClick={handleDownloadInvoice}>
                Descargar última factura
              </Button>
            )}

            {!voucher.isPaid && <RedsysButton className={styles.action} voucherId={voucherId} />}
          </div>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(VoucherScreen)
