import React from 'react'
import Screen from '../../../../components/molecules/screen/Screen'
import Button from '../../../../components/atoms/button/Button'
import styles from './RegisterScreen.module.css'
import { useHistory } from 'react-router-dom'
import Text from '../../../../components/atoms/text/Text'
import { useFormik } from 'formik'
import Input from '../../../../components/atoms/input/Input'
import registerFormSchema from './schemas/register'
import FormField from '../../../../components/molecules/formField/FormField'
import Radio from '../../../../components/atoms/radio/Radio'
import routes from '../../../../config/routes'
import Link from '../../../../components/atoms/link/Link'
import ScreenTitle from '../../../../components/molecules/screenTitle/ScreenTitle'
import { prices, terms } from '../../../../config'
import { VARIANTS } from '../../../../types/Components.types'
import { useRegisterMutation } from '../../../../modules/auth/hooks'

const RegisterScreen = () => {
  const history = useHistory()
  const register = useRegisterMutation()

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, setFieldValue, isValid, isSubmitting } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
        passwordConfirmation: '',
        gdpr: false,
      },
      validateOnMount: true,
      validationSchema: registerFormSchema,
      onSubmit: async (values) => {
        register.mutate(
          { email: values.email, password: values.password },
          { onSuccess: () => history.replace(routes.onboarding.home) },
        )
      },
    })

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <ScreenTitle title="Regístrate" />

        <Text>El registro es obligatorio para poder disfrutar del coworking</Text>

        <div className={styles.content}>
          <form onSubmit={handleSubmit}>
            <FormField
              field={
                <Input
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputMode="email"
                />
              }
              error={touched.email && errors.email ? errors.email : null}
            />

            <FormField
              field={
                <Input
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              error={touched.password && errors.password ? errors.password : null}
            />

            <FormField
              field={
                <Input
                  name="passwordConfirmation"
                  type="password"
                  placeholder="Repetir contraseña"
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              error={touched.passwordConfirmation && errors.passwordConfirmation ? errors.passwordConfirmation : null}
            />

            <FormField
              field={
                <Radio
                  name="gdpr"
                  isChecked={!!values.gdpr}
                  label={
                    <Text size="sm">
                      Acepto los{' '}
                      <a className={styles.inlineLink} href={terms} rel="noreferrer" target="_blank">
                        términos de uso
                      </a>
                      ,
                      <a className={styles.inlineLink} href={prices} rel="noreferrer" target="_blank">
                        precios del servicio
                      </a>{' '}
                      y la{' '}
                      <Link className={styles.inlineLink} path={routes.gdpr}>
                        política de privacidad
                      </Link>{' '}
                      del coworking
                    </Text>
                  }
                  value="true"
                  onChange={(name, value) => {
                    setFieldValue(name, value)
                  }}
                  onBlur={handleBlur}
                />
              }
            />

            <Button
              variant={VARIANTS.primary}
              className={styles.action}
              type="submit"
              disabled={!isValid}
              isLoading={isSubmitting}
            >
              Crear cuenta
            </Button>
          </form>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(RegisterScreen)
