import React from 'react'
import styles from './CoworkingValidationPendingScreen.module.css'
import { ReactComponent as CompleteIllustration } from './assets/illustration.svg'
import Screen from '../../../../../components/molecules/screen/Screen'
import Text from '../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../types/Components.types'

const CoworkingValidationPendingScreen = () => {
  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <div className={styles.content}>
          <CompleteIllustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title} align="center">
            Estamos comprobando tu cuenta
          </Text>
          <Text className={styles.description} align="center">
            Nuestro personal esta verificando tu información y te responderán en los próximos días. No olvides verificar
            tu carpeta de SPAM para comprobar si recibiste el mail de validación de tu cuenta
          </Text>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(CoworkingValidationPendingScreen)
