class GeolocationService {
  state = 'pending'

  getState() {
    return this.state
  }

  getGeolocationState() {
    if (!navigator.permissions || typeof navigator.permissions?.query !== 'function') {
      this.state = localStorage.getItem('@coworking/geolocation') || 'prompt'
      return Promise.resolve(this.state)
    }

    // Hack firefox
    return navigator.permissions.query({ name: 'geolocation' }).then(({ state }) => {
      if (this.state === 'granted') {
        return this.state
      }

      this.state = state
      return state
    })
  }

  askForPermission() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.info(position)
          localStorage.setItem('@coworking/geolocation', 'granted')
          this.state = 'granted'
          resolve('granted')
        },
        (error) => {
          console.info(error)
          // alert('errror' + JSON.stringify(error))
          if (error && error.code === 1) {
            this.state = 'denied'
            localStorage.setItem('@coworking/geolocation', 'denied')
          } else {
            this.state = 'granted'
            localStorage.setItem('@coworking/geolocation', 'granted')
          }

          reject(this.state)
        },
        {
          timeout: 20000,
        },
      )
    })
  }

  grantPermission() {
    this.state = 'granted'
    localStorage.setItem('@coworking/geolocation', 'granted')
  }

  getCurrentPosition() {
    return new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          console.info(position)
          localStorage.setItem('@coworking/geolocation', 'granted')
          this.state = 'granted'
          resolve(position)
        },
        (error) => {
          console.info(error)
          // alert('errror' + JSON.stringify(error))
          if (error && error.code === 1) {
            this.state = 'denied'
            localStorage.setItem('@coworking/geolocation', 'denied')
          } else {
            this.state = 'granted'
            localStorage.setItem('@coworking/geolocation', 'granted')
          }

          reject()
        },
        {
          timeout: 20000,
        },
      )
    })
  }
}

const Geolocation = new GeolocationService()
export { Geolocation }
