/* eslint-disable react-hooks/exhaustive-deps */
import c from 'classnames'
import React, {
  forwardRef,
  Fragment,
  Reducer,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useSwipeable } from 'react-swipeable'
import { SET_WIDTH_ITEMS_ARRAY } from './actions'
import styles from './Carousel.module.css'
import CarouselIndicators from './CarouselIndicators'
import useCarousel, { prepareSlidesCarouselData } from './useCarousel'

export interface ISlide {
  id: string
  Component: React.ReactNode | React.ForwardRefExoticComponent<any>
}

interface IProps {
  className?: string
  indicatorsClassName?: string
  slides: ISlide[]
  active?: number
  displayPosition?: number
  fixActiveFocus?: boolean
  widthItem?: number
  heightItem?: number
  widthWindow: number
  render?: (data: any) => React.ReactNode
  onChange?: (data: { activeItem: any; activeIndex: number; displayPosition: number }) => void
  onTransitionEnd?: (data: { activeItem: any; activeIndex: number; displayPosition: number }) => void
  onClick?: (data: { index: number; active: boolean }) => void
  indicators?: boolean
  disableNavigation?: boolean
  transitionCSS?: string
  auto?: boolean
  timer?: number
  isFocused?: boolean
  onFocus?: () => void
  onBlur?: () => void
  swipeable?: boolean
  onSwiped?: (eventData: any) => void
  onSwipedLeft?: (eventData: any) => void
  onSwipedRight?: (eventData: any) => void
  onSwipedUp?: (eventData: any) => void
  onSwipedDown?: (eventData: any) => void
  onSwiping?: (eventData: any) => void
  onSwipingLeft?: (eventData: any) => void
  onSwipingRight?: (eventData: any) => void
  onSwipingUp?: (eventData: any) => void
  onSwipingDown?: (eventData: any) => void
  onTap?: (eventData: any) => void
}

interface IState {
  widthItemsArray: number[]
}

interface IAction {
  type: 'SET_WIDTH_ITEMS_ARRAY'
  payload: {
    index: number
    width: number
  }
}

export interface ICarouselRef {
  next: () => void
  back: () => void
}

const getMaxVisibleIndex = (array: any[], availableWidth: number) => {
  let acumulatorWidth = 0
  const maxIndex = array.findIndex((elem) => {
    acumulatorWidth += elem
    if (acumulatorWidth > availableWidth) {
      return true
    }
    return false
  })
  // If maxIndex not found, all the elements fit on the availableWidth
  return maxIndex !== -1 ? maxIndex - 1 : array.length - 1
}

const Carousel = forwardRef<ICarouselRef, IProps>(
  (
    {
      className,
      indicatorsClassName,
      slides,
      active,
      displayPosition = 0,
      fixActiveFocus = false,
      widthItem,
      heightItem,
      widthWindow,
      render,
      onChange,
      onTransitionEnd,
      onClick,
      indicators = false,
      disableNavigation = false,
      auto = false,
      timer = 15000,
      isFocused,
      onFocus,
      onBlur,
      swipeable = false,
      onSwiped,
      onSwipedLeft,
      onSwipedRight,
      onSwipedUp,
      onSwipedDown,
      onSwiping,
      onTap,
    },
    ref,
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [transitioning, setTransitioninig] = useState(false)
    const [focused, setFocused] = useState(false)
    const [{ widthItemsArray }, dispatch] = useReducer<Reducer<IState, IAction>>(
      (state: IState, action: IAction) => {
        switch (action.type) {
          case SET_WIDTH_ITEMS_ARRAY:
            const { index, width } = action.payload
            if (!state.widthItemsArray[index]) {
              const newState = [...state.widthItemsArray]
              newState[index] = width
              return {
                ...state,
                widthItemsArray: newState,
              }
            }
            return state
          default:
            return state
        }
      },
      {
        widthItemsArray: [],
      },
    )
    const autoTimeourId = useRef<NodeJS.Timeout | null>(null)

    const activesLength = widthItem ? Math.floor(widthWindow / widthItem) : slides.length

    const {
      activeIndex,
      firstActiveIndex,
      lastActiveIndex,
      activeItem,
      items,
      next,
      back,
      isFirst,
      isLast,
      direction,
      generateActiveItems,
      setActiveIndex,
    } = useCarousel({
      active,
      activesLength,
      slides: prepareSlidesCarouselData(slides),
      loop: false,
    })

    const handlers = useSwipeable({
      onSwiped,
      onSwipedLeft,
      onSwipedRight,
      onSwipedUp,
      onSwipedDown,
      onSwiping,
      onTap,
    })

    const handleItemsRendered = useCallback(({ carouselIndex, width }: { carouselIndex: number; width: number }) => {
      dispatch({
        type: SET_WIDTH_ITEMS_ARRAY,
        payload: { index: carouselIndex, width },
      })
    }, [])

    const handleLeftPosition = useCallback(
      (initPosition: number, carouselIndex: number) =>
        widthItemsArray.slice(initPosition, carouselIndex).reduce((acumulator, widthItem) => acumulator + widthItem, 0),
      [widthItemsArray],
    )

    const calcDisplayPosition = useCallback(() => {
      if (fixActiveFocus) {
        return 0
      }
      return activeIndex - firstActiveIndex
    }, [activeIndex, firstActiveIndex])

    useEffect(() => {
      if (focused || fixActiveFocus) {
        return
      }

      let tempDisplace = displayPosition
      if (displayPosition >= activesLength) {
        tempDisplace = activesLength - 1
      }

      let newIndex = tempDisplace + firstActiveIndex
      if (slides.length <= newIndex) {
        newIndex = slides.length - 1
      }

      setActiveIndex(newIndex)
    }, [displayPosition, activesLength])

    useEffect(() => {
      setFocused(!!isFocused)
    }, [isFocused])

    const setTransitioningIfNeeded = useCallback(
      (direction: 'next' | 'back') => {
        if (
          (direction === 'next' && activeIndex === lastActiveIndex) ||
          (direction === 'back' && activeIndex === firstActiveIndex)
        ) {
          setTransitioninig(true)
        }
      },
      [activeIndex, firstActiveIndex, lastActiveIndex, activesLength],
    )

    const handleNext = useCallback(() => {
      if (isLast || transitioning || disableNavigation) {
        return
      }

      next()
      setTransitioningIfNeeded('next')
    }, [disableNavigation, isLast, activeIndex, next, transitioning, setTransitioningIfNeeded])

    const handleBack = useCallback(() => {
      if (isFirst || transitioning || disableNavigation) {
        return
      }

      back()
      setTransitioningIfNeeded('back')
    }, [disableNavigation, isFirst, activeIndex, back, transitioning, setTransitioningIfNeeded])

    const autoChange = useCallback(() => {
      console.log(
        `[Carousel] transitioning change auto. Direction: ${direction}, isLast: ${isLast}, isFirst: ${isFirst}`,
      )
      if ((direction === 'next' && !isLast) || (direction === 'back' && isFirst)) {
        handleNext()
      } else if ((direction === 'next' && isLast) || (direction === 'back' && !isFirst)) {
        handleBack()
      }
    }, [isFirst, isLast, direction, handleNext, handleNext, setTransitioningIfNeeded])

    const initAutoChange = useCallback(() => {
      if (!auto) {
        return
      }
      autoTimeourId.current && clearTimeout(autoTimeourId.current)
      autoTimeourId.current = setTimeout(autoChange, timer)
    }, [autoChange, auto, timer])

    useEffect(() => {
      if (auto) {
        initAutoChange()
      }

      return () => {
        autoTimeourId.current && clearTimeout(autoTimeourId.current)
      }
    }, [auto, activeIndex])

    useEffect(() => {
      const endTransition = () => {
        generateActiveItems()
        setTransitioninig(false)

        onTransitionEnd &&
          onTransitionEnd({
            activeItem,
            activeIndex,
            displayPosition: calcDisplayPosition(),
          })
      }
      wrapperRef.current?.addEventListener('webkitTransitionEnd', endTransition)

      return () => {
        wrapperRef.current?.removeEventListener('webkitTransitionEnd', endTransition)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateActiveItems, activeItem])

    useEffect(() => {
      onChange?.({
        activeItem,
        activeIndex,
        displayPosition: calcDisplayPosition(),
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex])

    useImperativeHandle(ref, () => ({
      next: handleNext,
      back: handleBack,
    }))

    const movement = useMemo(() => {
      if (fixActiveFocus) {
        return widthItem ? activeIndex * -widthItem : -handleLeftPosition(0, activeIndex)
      }
      const maxIdx = getMaxVisibleIndex(widthItemsArray, widthWindow) + 1

      /**translate not depend on lastActiveIndex or firstActiveIntex because when there isn't,
    widthItem, these values never change. We could integrate this operation
    into generateActiveItems of useCarousel**/
      const translate = activeIndex < maxIdx ? 0 : -handleLeftPosition(maxIdx, activeIndex + 1)

      return widthItem ? firstActiveIndex * -widthItem : translate
    }, [fixActiveFocus, widthItem, activeIndex, widthItemsArray, widthWindow, handleLeftPosition, firstActiveIndex])

    const transitionableInlineStyles = {
      width: widthItem ? `${slides.length * widthItem}px` : 'auto',
      height: `${heightItem}px`,
      WebkitTransform: `translate3d(${movement}px, 0, 0)`,
      transform: `translate3d(${movement}px, 0, 0)`,
    }

    let containerProps = {}
    if (swipeable) {
      containerProps = handlers
    }

    return (
      <div className={c(className, 'carousel')} {...containerProps}>
        <div
          style={{
            width: `${widthWindow}px`,
            height: `${heightItem}px`,
          }}
          className={styles.wrapper}
        >
          <div ref={wrapperRef} style={transitionableInlineStyles} className={styles.transitionable}>
            {Object.values(items as { id: string; [key: string]: any }[]).map(({ id, ...rest }) => (
              <Fragment key={id}>
                {render?.({
                  id: id,
                  widthItem: widthItem,
                  heightItem: heightItem,
                  onRendered: handleItemsRendered,
                  leftPosition: widthItem ? widthItem * rest.carouselIndex : handleLeftPosition(0, rest.carouselIndex),
                  activeIndex,
                  displayed: rest.carouselIndex >= firstActiveIndex && rest.carouselIndex <= lastActiveIndex,
                  carouselFocused: focused,
                  ...rest,
                })}
              </Fragment>
            ))}
          </div>
        </div>

        {indicators && (
          <CarouselIndicators
            className={indicatorsClassName}
            numberOfSlides={slides.length}
            currentSlide={activeIndex}
          />
        )}
      </div>
    )
  },
)

export default Carousel
