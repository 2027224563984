import c from 'classnames'
import styles from './Icon.module.css'

interface IProps {
  fill?: string
  className?: string
  variant?:
    | 'white'
    | 'black'
    | 'max'
    | 'dark'
    | 'light'
    | 'lighter'
    | 'min'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'panda'
    | 'pandaDark'
    | 'flamingo'
    | 'cocodrile'
    | 'pig'
    | 'koala'
    | 'parrot'
    | 'parrotLight'
    | 'octupus'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  display?: 'inline-block' | 'inline'
}

const IconCalendarAdd = ({ fill, className, variant, size = 's', display, ...rest }: IProps) => {
  return (
    <i
      className={c(
        styles.icon,
        variant && styles[variant],
        size && styles[size],
        display && styles[display],
        className,
      )}
      style={{ fill }}
      {...rest}
    >
      <svg viewBox="0 0 30 30">
        <path d="M26.06 24.84c2.623 0 3.94-1.318 3.94-3.9V5.886C30 3.317 28.683 2 26.06 2H9.17C6.56 2 5.23 3.304 5.23 5.887v7.004c.353-.039.705-.078 1.07-.078.352 0 .717.04 1.07.078v-3.43c0-1.122.6-1.696 1.67-1.696h17.138c1.07 0 1.683.574 1.683 1.696v11.53c0 1.135-.613 1.696-1.683 1.696h-12.13a7.098 7.098 0 01-.835 2.152h12.848zm-8.908-12.692c.444 0 .587-.13.587-.574v-.717c0-.43-.143-.574-.587-.574h-.717c-.444 0-.587.143-.587.574v.717c0 .443.143.574.587.574h.717zm3.47 0c.443 0 .587-.13.587-.574v-.717c0-.43-.144-.574-.587-.574h-.73c-.431 0-.575.143-.575.574v.717c0 .443.144.574.574.574h.73zm3.47 0c.443 0 .586-.13.586-.574v-.717c0-.43-.143-.574-.587-.574h-.73c-.43 0-.574.143-.574.574v.717c0 .443.143.574.574.574h.73zm-10.41 4.004c.444 0 .588-.13.588-.56v-.731c0-.43-.144-.561-.587-.561h-.718c-.443 0-.587.13-.587.56v.731c0 .43.144.561.587.561h.718zm3.47 0c.444 0 .587-.13.587-.56v-.731c0-.43-.143-.561-.587-.561h-.717c-.444 0-.587.13-.587.56v.731c0 .43.143.561.587.561h.717zm3.47 0c.443 0 .587-.13.587-.56v-.731c0-.43-.144-.561-.587-.561h-.73c-.431 0-.575.13-.575.56v.731c0 .43.144.561.574.561h.73zm3.47 0c.443 0 .586-.13.586-.56v-.731c0-.43-.143-.561-.587-.561h-.73c-.43 0-.574.13-.574.56v.731c0 .43.143.561.574.561h.73zM6.3 27.135c3.417 0 6.3-2.857 6.3-6.3 0-3.457-2.857-6.3-6.3-6.3-3.457 0-6.3 2.843-6.3 6.3 0 3.456 2.843 6.3 6.3 6.3zm0-2.205c-.443 0-.835-.313-.835-.795v-2.518H3.13a.805.805 0 01-.795-.782c0-.43.365-.796.795-.796h2.335v-2.517c0-.483.392-.783.835-.783.443 0 .835.3.835.783v2.517H9.47c.43 0 .782.365.782.796a.793.793 0 01-.782.782H7.135v2.518c0 .482-.392.795-.835.795zm10.852-4.76c.444 0 .587-.13.587-.561v-.73c0-.431-.143-.562-.587-.562h-.717c-.444 0-.587.13-.587.561v.73c0 .431.143.562.587.562h.717zm3.47 0c.443 0 .587-.13.587-.561v-.73c0-.431-.144-.562-.587-.562h-.73c-.431 0-.575.13-.575.561v.73c0 .431.144.562.574.562h.73z" />
      </svg>
    </i>
  )
}

export default IconCalendarAdd
