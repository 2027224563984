import { DateTime } from 'luxon'
import { IMeetingRoomReservation } from '../../types/app.types'

export const isReservedForToday = ({ reservation }: { reservation?: IMeetingRoomReservation }) => {
  if (!reservation) {
    return false
  }
  return DateTime.fromISO(reservation.reserved_from).startOf('day').equals(DateTime.local().startOf('day'))
}

export const getReservationDay = ({ reservation }: { reservation?: IMeetingRoomReservation }) => {
  if (!reservation) {
    return DateTime.local()
  }
  return DateTime.fromISO(reservation.reserved_from)
}

export const getReservationFrom = ({ reservation }: { reservation?: IMeetingRoomReservation }) => {
  if (!reservation) {
    return DateTime.local()
  }
  return DateTime.fromISO(reservation.reserved_from)
}

export const getReservationTo = ({ reservation }: { reservation?: IMeetingRoomReservation }) => {
  if (!reservation) {
    return DateTime.local()
  }
  return DateTime.fromISO(reservation.reserved_to)
}

export const getReservationDaysLeft = ({ reservation }: { reservation?: IMeetingRoomReservation }) => {
  if (!reservation) {
    return { months: 0, days: 0, hours: 0 }
  }
  const reserved = DateTime.fromISO(reservation.reserved_from)
  return reserved.diffNow(['months', 'days', 'hours']).toObject()
}

export const getHumanizedReservationDaysLeft = ({ reservation }: { reservation?: IMeetingRoomReservation }) => {
  const { months = 0, days = 0, hours = 0 } = getReservationDaysLeft({ reservation })

  if (months > 0) {
    return `En ${Math.floor(months)} mes${Math.floor(months) !== 1 ? 'es' : ''}`
  }

  if (days > 0) {
    return `En ${Math.floor(days)} día${Math.floor(days) !== 1 ? 's' : ''}`
  }

  if (hours > 0) {
    return `En ${Math.floor(hours)} hora${Math.floor(hours) !== 1 ? 's' : ''}`
  }

  return ''
}

export const getHumanizedDuration = ({ reservation }: { reservation?: IMeetingRoomReservation }) => {
  if (!reservation) {
    return ''
  }
  return `De ${DateTime.fromISO(reservation.reserved_from).hour}h a ${DateTime.fromISO(reservation.reserved_to).hour}h`
}
