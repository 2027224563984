import React from 'react'
import Text from '../../atoms/text/Text'
import styles from './GDPRText.module.css'

const GDPRText = () => {
  return (
    <>
      <Text weight="600" className={styles.header}>
        ¿Qué hacemos con tus datos?
      </Text>

      <Text className={styles.block}>
        Los datos facilitados mediante el formulario de alta, por teléfono o correo electrónico, se incorporan al
        tratamiento “Usuarios del coworking, cuyo{' '}
        <Text weight="500" element="span">
          Responsable
        </Text>{' '}
        es el Ayuntamiento de San Lorenzo de El Escorial (NIF: P2813100A) y el órgano de gestión es el Centro de
        Difusión de la Innovación. Tiene como única{' '}
        <Text weight="500" element="span">
          finalidad
        </Text>{' '}
        tramitar su solicitud de información o prestarte el servicio solicitado. Los datos aportados y los informes
        generados son confidenciales. La{' '}
        <Text weight="500" element="span">
          legitimación
        </Text>{' '}
        del tratamiento es poder prestarte el servicio solicitado.
      </Text>

      <Text className={styles.block}>
        Tus datos{' '}
        <Text weight="500" element="span">
          no se cederán
        </Text>{' '}
        a ninguna empresa u organismo. Los datos se conservan de forma indefinida mientras tengas interés en los
        servicios del centro. Para que dejemos de tenerlos nos lo debes comunicar. No recogemos nunca datos sensibles.
      </Text>

      <Text className={styles.block}>
        Puedes{' '}
        <Text weight="500" element="span">
          ejercer los derechos
        </Text>{' '}
        de acceso, rectificación, oposición y cancelación, dirigiendo un escrito a Centro de Difusión de la Innovación,
        calle Calvario 38, 28200 San Lorenzo de El Escorial, Madrid, personándote en el horario de atención o por correo
        electrónico: cdi@aytosanlorenzo.es.
      </Text>

      <Text weight="600" className={styles.header}>
        Otros datos facilitados
      </Text>

      <Text className={styles.block}>
        Los datos aportados por{' '}
        <Text weight="500" element="span">
          proveedores
        </Text>{' '}
        se incorporan al tratamiento «Proveedores». No recogemos datos de candidat@s, que son derivados a Recursos
        Humanos del Ayuntamiento.
      </Text>
    </>
  )
}

export default React.memo(GDPRText)
