import React from 'react'
import styles from './Card.module.css'
import c from 'classnames'

interface IProps {
  className?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  children: React.ReactNode
}

const Card = ({ className, children, type = 'button', onClick, ...rest }: IProps) => {
  return React.createElement(
    onClick ? 'button' : 'div',
    {
      className: c(styles.container, className),
      onClick: onClick,
      type: onClick ? type : null,
      ...rest,
    },
    children,
  )
}

export default React.memo(Card)
