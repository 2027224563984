import React from 'react'
import styles from './Calendar.module.css'
import InfiniteCalendar, {
  Calendar as RICalendar,
  DateType,
  defaultMultipleDateInterpolation,
  withMultipleDates,
} from 'react-infinite-calendar'
import 'react-infinite-calendar/styles.css'
import calendarTheme from './config/calendar.theme'
import c from 'classnames'

interface IProps {
  className?: string
  multipleDateSelection?: boolean
  theme?: any
  selected?: Date | Date[] | null
  onSelect?: (value: Date) => void
  minDate?: Date
  maxDate?: Date
  disabledDays?: (0 | 1 | 2 | 3 | 4 | 5 | 6)[]
  onScroll?: () => void
}

interface IExtraProps {
  Component?: any
  interpolateSelection?: any
}

const locale = {
  blank: 'Selecciona un día',
  headerFormat: 'dddd, D MMM',
  locale: require('date-fns/locale/es'), // You need to pass in the date-fns locale for the language you want (unless it's EN)
  todayLabel: {
    long: 'Hoy',
    short: 'Hoy',
  },
  weekdays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vi', 'Sa'],
  weekStartsOn: 1, // Start the week on Monday
}

const MultipleDatesCalendar = withMultipleDates(RICalendar)

const Calendar = ({ className, multipleDateSelection = false, theme = {}, selected, ...rest }: IProps) => {
  const extraProps = {} as IExtraProps

  if (multipleDateSelection) {
    extraProps.Component = MultipleDatesCalendar
    extraProps.interpolateSelection = defaultMultipleDateInterpolation
  }
  return (
    <InfiniteCalendar
      className={c(styles.calendar, className)}
      width="100%"
      theme={{
        ...calendarTheme,
        ...theme,
      }}
      locale={locale as any}
      displayOptions={{
        showHeader: false,
      }}
      selected={selected as DateType}
      {...extraProps}
      {...rest}
    />
  )
}

export default React.memo(Calendar)
