import * as Yup from 'yup'

const loginFormSchema = Yup.object({
  email: Yup.string()
    .email('Debes introducir un correo electrónico')
    .required('Debes introducir un correo electrónico'),
  password: Yup.string().required('Debes introducir la contraseña'),
})

export default loginFormSchema
