import CookieService from '../cookies/service'
import { CoworkingQueryClient } from '../CoworkingQueryClient'
import FetchHelper from '../FetchHelper'
import AuthAPI from './api'
import { IUser } from './types'

interface ILogin {
  email: string
  password: string
}

interface IForgotPassword {
  email: string
}

interface ISecureOP {
  userId: string
  nonce: string
  token: string
}

interface IRecoverPassword extends ISecureOP {
  password: string
}

interface IValidateAccount extends ISecureOP {
  mode: string
}

interface IResendValidationEmail {
  userId: string
}

interface IUpdateProfile extends Partial<Omit<IUser, 'censo' | 'facephoto' | 'dniphoto_front' | 'dniphoto_back'>> {
  userId: string
  censo?: File
  facephoto?: File
  identityCard?: {
    front?: File
    back?: File
  }
  dniphoto_front?: File
  dniphoto_back?: File
}

export const me = async (): Promise<IUser> => {
  const token = CookieService.readCookie()
  if (!token) {
    throw new Error('No token')
  }

  const { setAuthToken } = FetchHelper
  setAuthToken({ authToken: token })

  const response = await AuthAPI.refreshToken()
  if (response.status !== 200) {
    CookieService.deleteCookie()
    throw new Error(response.status.toString())
  }

  const { token: refreshToken, ...user } = await response.json()
  setAuthToken({ authToken: refreshToken })
  CookieService.setCookie(refreshToken)

  return user
}

export const login = async (data: ILogin) => {
  const response = await AuthAPI.login(data)
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  const { setAuthToken } = FetchHelper
  const { token } = await response.json()
  setAuthToken(token)
  CookieService.setCookie(token)

  await CoworkingQueryClient.prefetchQuery({ queryKey: ['me'], queryFn: me })
}

export const logout = async () => {
  const { setAuthToken } = FetchHelper
  setAuthToken({ authToken: null })
  CookieService.deleteCookie()
}

export const register = async (data: ILogin) => {
  const response = await AuthAPI.singup(data)
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  const { setAuthToken } = FetchHelper
  const { token } = await response.json()
  setAuthToken(token)
  CookieService.setCookie(token)

  await CoworkingQueryClient.prefetchQuery({ queryKey: ['me'], queryFn: me })
}

export const forgotPassword = async ({ email }: IForgotPassword) => {
  const response = await AuthAPI.forgotPassword({ email })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }
}

export const recoverPassword = async (data: IRecoverPassword) => {
  const response = await AuthAPI.recoverPassword(data)
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }
}

export const validateAccount = async ({ nonce, token, mode, userId }: IValidateAccount) => {
  const queryParams = new URLSearchParams({
    nonce,
    token,
    mode,
  })
  const response = await AuthAPI.validateAccount({ userId, queryParams })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }
}

export const resendValidationEmail = async ({ userId }: IResendValidationEmail) => {
  const response = await AuthAPI.resendValidationEmail({ userId })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }
}

export const updateProfile = async ({ userId, ...rest }: IUpdateProfile) => {
  const response = await AuthAPI.updateProfile({ userId, profile: rest })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  const user = await response.json()
  CoworkingQueryClient.setQueryData(['me'], user)
}

export const isBillingAddressComplete = ({ user }: { user?: IUser }) => {
  return (
    user &&
    user.empresaDireccion &&
    user.empresaDireccion.street &&
    user.empresaDireccion.postalCode &&
    user.empresaDireccion.state &&
    user.empresaDireccion.locality &&
    user.empresaDireccion.country
  )
}

export const removePaymentCard = async ({ userId, cardId }: { userId: string; cardId: string }) => {
  const response = await AuthAPI.removeCardId({ userId, cardId })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  const user: IUser = CoworkingQueryClient.getQueryData(['me'])!
  const paymentCards = user?.paymentCards || {}
  delete paymentCards[cardId]
  CoworkingQueryClient.setQueryData(['me'], { ...user, paymentCards })
}
