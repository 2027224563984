import React, { useCallback } from 'react'
import styles from './Avatar.module.css'
import c from 'classnames'
import IconCamera from '../../icons/IconCamera'

interface IProps {
  className?: string
  editable?: boolean
  resource?: string
  onChange?: (data: { files: FileList }) => void
}

const Avatar = ({ className, editable = false, resource, onChange }: IProps) => {
  const handleFilesChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = evt.target
      if (!files || !Object.keys(files).length) {
        return
      }

      onChange?.({ files })
    },
    [onChange],
  )

  return (
    <div className={c(styles.container, className)}>
      {resource && <img src={resource} alt="avatar" className={styles.avatar} />}
      {editable && (
        <label className={c(styles.icon, resource && styles.floatIcon)}>
          <input type="file" hidden onChange={handleFilesChange} accept="image/*" disabled={!editable} />
          <IconCamera variant={resource ? 'white' : 'lighter'} />
        </label>
      )}
    </div>
  )
}

export default React.memo(Avatar)
