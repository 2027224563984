import { useFormik } from 'formik'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { FaRegTimesCircle } from 'react-icons/fa'
import { FaRegCircleCheck } from 'react-icons/fa6'
import Text from '../../../../../../components/atoms/text/Text'
import Avatar from '../../../../../../components/molecules/avatar/Avatar'
import { useProfileAssistant } from '../../../../../../providers/profileAssistant'
import { VARIANTS } from '../../../../../../types/Components.types'
import { IStepProps, IStepRef } from '../types'
import styles from './FacePhotoStep.module.css'
import facephotoFormSchema from './schema/facephoto'

const FacePhotoStep = forwardRef<IStepRef, IStepProps>(({ onSubmit, ...rest }, ref) => {
  const { facephoto, setAvatar } = useProfileAssistant()

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    initialValues: {
      facephoto,
    },
    validationSchema: facephotoFormSchema,
    onSubmit: async (values) => {
      return new Promise<void>((resolve) => {
        setAvatar({ facephoto: values.facephoto! })
        resolve()
        onSubmit?.()
      })
    },
  })

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }))
  const [localAvatar, setLocalAvatar] = useState('')

  const onAvatarChange = useCallback(
    ({ files }: { files: FileList }) => {
      setFieldValue('facephoto', files[0])
    },
    [setFieldValue],
  )

  useEffect(() => {
    if (values.facephoto) {
      const _URL = window.URL || window.webkitURL
      const blob = _URL.createObjectURL(values.facephoto)

      const img = new Image()
      img.src = blob

      img.onload = function () {
        setLocalAvatar(blob)
      }
    }
  }, [values])

  return (
    <div className={styles.container}>
      <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
        ¡Sonríe!
      </Text>

      <Text className={styles.description}>
        Hazte o sube una foto de ti para que otros miembros del coworking te conozcan.{' '}
        <Text element="span" weight={600}>
          Te dejamos algunos tips:
        </Text>
      </Text>

      <ul className={styles.tips}>
        <li>
          <FaRegCircleCheck color={`rgba(var(--clrPositive))`} />
          La foto debe ser de tu rostro
        </li>
        <li>
          <FaRegCircleCheck color={`rgba(var(--clrPositive))`} />
          Fotografía actual
        </li>
        <li>
          <FaRegTimesCircle color={`rgba(var(--clrNegative))`} />
          Cubrirse la cara
        </li>
        <li>
          <FaRegTimesCircle color={`rgba(var(--clrNegative))`} />
          Aparecer con más gente
        </li>
      </ul>

      <form className={styles.content} onSubmit={handleSubmit}>
        <Avatar onChange={onAvatarChange} resource={localAvatar} editable />

        {localAvatar && (
          <Text className={styles.cheers} align="center">
            ¡Sales genial!
          </Text>
        )}

        {touched.facephoto && errors.facephoto && (
          <Text variant={VARIANTS.negative} align="center" className={styles.error}>
            {errors.facephoto}
          </Text>
        )}
      </form>
    </div>
  )
})

export default FacePhotoStep
