import React from 'react'
import styles from './UpdateAttachmentsScreen.module.css'
import { ReactComponent as Illustration } from './assets/illustration.svg'
import Screen from '../../../../../components/molecules/screen/Screen'
import BackButton from '../../../../../components/atoms/backButton/BackButton'
import Text from '../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../types/Components.types'

const UpdateAttachmentsScreen = () => {
  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Domicilio fiscal
          </Text>
        </div>

        <div className={styles.content}>
          <Illustration />
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title} align="center">
            ¿Necesitas modificar la documentación?
          </Text>
          <Text className={styles.description} align="center">
            Envíanos la documentación a{' '}
            <a className={styles.link} href="mailto:coworking@aytosanlorenzo.es">
              coworking@aytosanlorenzo.es
            </a>{' '}
            indicando tu nombre, apellidos y DNI, junto a la documentación, para que la revisemos y te demos de alta en
            los sistemas para que puedas beneficiarte de los descuentos asociados.
          </Text>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(UpdateAttachmentsScreen)
