import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'
import { toast } from 'react-toastify'
import {
  cancelAutoRenew,
  confirmPayment,
  downloadInvoice,
  getPurchasedVoucher,
  getPurchasedVouchers,
  getVouchers,
  payVoucher,
  purchaseVoucher,
  renewVoucher,
} from '.'
import { IUserPurchaseVoucher } from '../../types/app.types'
import { CoworkingQueryClient } from '../CoworkingQueryClient'
import { isRenewable } from './utils'

export const useVouchers = ({ queryParams }: { queryParams: URLSearchParams }, options = {}) => {
  return useQuery({
    queryKey: ['vouchers', queryParams.toString()],
    queryFn: () => getVouchers({ queryParams }),
    ...options,
  })
}

export const useVoucherById = ({ queryParams, voucherId }: { queryParams: URLSearchParams; voucherId?: string }) => {
  const { data: vouchers, ...rest } = useVouchers(
    { queryParams },
    {
      enabled: !!voucherId,
    },
  )
  const voucher = React.useMemo(() => {
    if (!vouchers) {
      return undefined
    }
    return vouchers.find((voucher) => voucher.id === voucherId)
  }, [vouchers, voucherId])

  return { data: voucher, ...rest }
}

export const usePurchasedVouchers = ({ userId }: { userId?: string }) => {
  return useQuery({
    queryKey: ['purchased-vouchers', userId],
    queryFn: () => getPurchasedVouchers({ userId: userId! }),
    enabled: !!userId,
  })
}

export const usePurchasedVoucher = ({ userId, voucherId }: { userId?: string; voucherId?: string }) => {
  const { data: vouchers, ...rest } = usePurchasedVouchers({ userId })
  const voucher = React.useMemo(() => {
    if (!vouchers) {
      return undefined
    }
    return vouchers.find((voucher) => voucher.id === voucherId)
  }, [voucherId, vouchers])

  return { data: voucher as IUserPurchaseVoucher | undefined, ...rest }
}

export const usePurchasedVoucherById = ({ userId, voucherId }: { userId?: string; voucherId?: string }) => {
  return useQuery({
    queryKey: ['purchased-vouchers', userId],
    queryFn: () => getPurchasedVoucher({ userId: userId!, voucherId: voucherId!! }),
    enabled: !!userId && !!voucherId,
  })
}

export const usePurchasedVoucherWithPaidData = ({ userId, voucherId }: { userId?: string; voucherId?: string }) => {
  return useQuery({
    queryKey: ['voucher', userId, voucherId, 'paid-data'],
    queryFn: () => payVoucher({ userId: userId!, voucherId: voucherId!! }),
    enabled: !!userId && !!voucherId,
  })
}

export const useRenewableVouchers = ({ userId }: { userId?: string }) => {
  const { data: vouchers, ...rest } = usePurchasedVouchers({ userId })
  const renewableVouchers = React.useMemo(() => {
    if (!vouchers) {
      return undefined
    }
    return vouchers.filter((voucher) => isRenewable({ voucher }))
  }, [vouchers])

  return { data: renewableVouchers as IUserPurchaseVoucher[] | undefined, ...rest }
}

export const useConfirmPaymentMutation = () => {
  return useMutation({
    mutationFn: confirmPayment,
    onSuccess: () => {
      toast.success('Tu pago se ha confirmado con el banco')
    },
    onError: () => {
      console.info('Error on confirm payment')
      // toast.error('Oops! No hemos podido confirmar tu pago. Inténtalo de nuevo más tarde')
    },
  })
}

export const useDownloadVoucherInvoiceMutation = () => {
  return useMutation({
    mutationFn: downloadInvoice,
    onSuccess: () => {},
    onError: () => {
      toast.error('Oops! No hemos podido descargar tu factura. Inténtalo de nuevo más tarde')
    },
  })
}

export const useRenewVoucherMutation = () => {
  return useMutation({
    mutationFn: renewVoucher,
    onSuccess: (status) => {
      if (status === 202) {
        toast.success('¡Genial! Tu bono se ha renovado un mes más')
      }
    },
    onError: () => {
      toast.error('Oops! No podemos contactar con la pasarela de pagos en estos momentos')
    },
  })
}

export const useCancelAutoRenewMutation = () => {
  return useMutation({
    mutationFn: cancelAutoRenew,
    onSuccess: (_, { userId }) => {
      toast.success('Tu reserva se ha cancelado correctamente')
      CoworkingQueryClient.invalidateQueries(['purchased-vouchers', userId])
    },
    onError: () => {
      toast.error('Oops! No hemos podido cancelar tu reserva')
    },
  })
}

export const usePurchaseVoucherMutation = () => {
  return useMutation({
    mutationFn: purchaseVoucher,
    onSuccess: ({ status }, { userId }) => {
      if (status === 202) {
        toast.success('¡Genial! Has comprado tu bono')
        CoworkingQueryClient.invalidateQueries({
          queryKey: ['purchased-vouchers', userId],
          exact: true,
        })
      }
    },
    onError: () => {
      toast.error('Oops! No hemos podido realizar la compra del bono')
    },
  })
}
