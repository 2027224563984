import React, { useCallback } from 'react'
import styles from './BillingAddressScreen.module.css'
import { useFormik } from 'formik'
import billingAddressSchema from './schema'
import { useHistory } from 'react-router-dom'
import { useProfile, useUpdateProfileMutation } from '../../../../../modules/auth/hooks'
import Screen from '../../../../../components/molecules/screen/Screen'
import BackButton from '../../../../../components/atoms/backButton/BackButton'
import Text from '../../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../../types/Components.types'
import FormField from '../../../../../components/molecules/formField/FormField'
import Input from '../../../../../components/atoms/input/Input'
import Button from '../../../../../components/atoms/button/Button'

const BillingAddressScreen = () => {
  const { data: profile } = useProfile()

  const history = useHistory()
  const updateProfileMutation = useUpdateProfileMutation()

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting } = useFormik({
    initialValues: {
      empresa: profile!.empresa || '',
      cif: profile!.cif || '',
      empresaDireccion: {
        street: '',
        postalCode: '',
        state: '',
        locality: '',
        country: '',
        ...(profile!.empresaDireccion || {}),
      },
    },
    validationSchema: billingAddressSchema,
    onSubmit: (values) => {
      return new Promise<void>((resolve, reject) => {
        updateProfileMutation.mutate(
          {
            userId: profile!.id,
            ...values,
          },
          {
            onSuccess: () => {
              resolve()
              goBack()
            },
            onError: () => {
              reject()
            },
          },
        )
      })
    },
  })

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Dirección de facturación
          </Text>
        </div>

        <Text>
          ¿Necesitas la factura emitida a una empresa? Aquí puedes configurar los datos que que utilizaremos para
          generar tu factura.
        </Text>

        <form className={styles.content} onSubmit={handleSubmit}>
          <FormField
            field={
              <Input
                name="empresa"
                placeholder="Empresa"
                value={values.empresa}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={touched?.empresa && errors?.empresa ? errors?.empresa : null}
          />

          <FormField
            field={
              <Input name="cif" placeholder="NIF" value={values.cif} onChange={handleChange} onBlur={handleBlur} />
            }
            error={touched?.cif && errors?.cif ? errors?.cif : null}
          />

          <FormField
            field={
              <Input
                name="empresaDireccion.street"
                placeholder="Dirección"
                value={values.empresaDireccion.street}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={
              touched.empresaDireccion?.street && errors.empresaDireccion?.street
                ? errors.empresaDireccion?.street
                : null
            }
          />

          <FormField
            field={
              <Input
                name="empresaDireccion.postalCode"
                placeholder="Código postal"
                value={values.empresaDireccion.postalCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={
              touched.empresaDireccion?.postalCode && errors.empresaDireccion?.postalCode
                ? errors.empresaDireccion?.postalCode
                : null
            }
          />

          <FormField
            field={
              <Input
                name="empresaDireccion.state"
                placeholder="Provincia"
                value={values.empresaDireccion.state}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={
              touched.empresaDireccion?.state && errors.empresaDireccion?.state ? errors.empresaDireccion?.state : null
            }
          />

          <FormField
            field={
              <Input
                name="empresaDireccion.locality"
                placeholder="Localidad"
                value={values.empresaDireccion.locality}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={
              touched.empresaDireccion?.locality && errors.empresaDireccion?.locality
                ? errors.empresaDireccion?.locality
                : null
            }
          />

          <FormField
            field={
              <Input
                name="empresaDireccion.country"
                placeholder="País"
                value={values.empresaDireccion.country}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            error={
              touched.empresaDireccion?.country && errors.empresaDireccion?.country
                ? errors.empresaDireccion?.country
                : null
            }
          />

          <Button variant={VARIANTS.primary} className={styles.action} type="submit" isLoading={isSubmitting}>
            Guardar
          </Button>
        </form>
      </div>
    </Screen>
  )
}

export default React.memo(BillingAddressScreen)
