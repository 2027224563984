import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/atoms/button/Button'
import Text from '../../../../components/atoms/text/Text'
import Avatar from '../../../../components/molecules/avatar/Avatar'
import LoadingData from '../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../components/molecules/screen/Screen'
import routes from '../../../../config/routes'
import { useProfile, useUpdateProfileMutation } from '../../../../modules/auth/hooks'
import { VARIANTS } from '../../../../types/Components.types'
import Row from '../components/row/Row'
import styles from './ProfileScreen.module.css'

const ProfileScreen = () => {
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const updateProfile = useUpdateProfileMutation()

  const history = useHistory()

  const goToEditProfile = useCallback(() => {
    history.push(routes.profile.edit)
  }, [history])

  const handleAvatarChange = useCallback(
    ({ files }: { files: FileList }) => {
      updateProfile.mutate({ userId: profile!.id, facephoto: files[0] })
    },
    [profile, updateProfile],
  )

  if (isLoadingProfile) {
    return <LoadingData />
  }

  return (
    <Screen
      headerProps={{
        variant: VARIANTS.parrot,
        title: 'Mi perfil',
        backEnabled: true,
      }}
    >
      <div className={styles.container}>
        <div className={styles.mainInfo}>
          <Avatar editable resource={profile?.facephoto} onChange={handleAvatarChange} />
          <Text
            weight="700"
            align="center"
            size="l"
            className={styles.name}
          >{`${profile?.firstname} ${profile?.lastname}`}</Text>
        </div>
        <div className={styles.infoWrapper}>
          <Row label="DNI" info={profile?.dni} />

          <Row label="Teléfono de contacto" info={profile?.phone} />

          <Row label="Dirección" info={profile?.address?.street} />

          <Row label="Código Postal" info={profile?.address?.postalCode} />

          <Row label="Provincia" info={profile?.address?.state} />

          <Row label="Localidad" info={profile?.address?.locality} />

          <Row label="País" info={profile?.address?.country} />

          <Row label="Profesión" info={profile?.profession} />

          <Row label="Linked-in" info={profile?.linkedIn} />

          <Row label="Facebook" info={profile?.facebook} />

          <Row label="Twitter" info={profile?.twitter} />
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant={VARIANTS.primary} className={styles.action} type="button" onClick={goToEditProfile}>
          Modificar perfil
        </Button>
      </div>
    </Screen>
  )
}

export default React.memo(ProfileScreen)
