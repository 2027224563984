import React from 'react'
import Screen from '../../../../../components/molecules/screen/Screen'
import Button from '../../../../../components/atoms/button/Button'
import styles from './RecoverPasswordScreen.module.css'
import { useHistory } from 'react-router-dom'
import Text from '../../../../../components/atoms/text/Text'
import { useFormik } from 'formik'
import Input from '../../../../../components/atoms/input/Input'
import recoverPasswordFormSchema from './schemas/recoverPassword'
import FormField from '../../../../../components/molecules/formField/FormField'
import routes from '../../../../../config/routes'
import { useQuery } from '../../../../../utils/hooks/useQuery'
import { VARIANTS } from '../../../../../types/Components.types'
import { useRecoverPasswordMutation } from '../../../../../modules/auth/hooks'

const RecoverPasswordScreen = () => {
  const history = useHistory()
  const query = useQuery()
  const recoverPassword = useRecoverPasswordMutation()

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, isValid, isSubmitting } = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validateOnMount: true,
    validationSchema: recoverPasswordFormSchema,
    onSubmit: async ({ password }) => {
      recoverPassword.mutate(
        {
          password,
          userId: query.get('userId') as string,
          nonce: query.get('nonce') as string,
          token: query.get('token') as string,
        },
        {
          onSuccess: () => {
            history.replace(routes.app)
          },
        },
      )
    },
  })

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Nueva contraseña
          </Text>
        </div>
        <Text>Introduce tu nueva contraseña para acceder a tu cuenta del coworking</Text>

        <form className={styles.content} onSubmit={handleSubmit}>
          <div>
            <FormField
              field={
                <Input
                  name="password"
                  type="password"
                  placeholder="Nueva contraseña"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              error={touched.password && errors.password ? errors.password : null}
            />

            <FormField
              field={
                <Input
                  name="passwordConfirmation"
                  type="password"
                  placeholder="Repetir contraseña"
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              error={touched.passwordConfirmation && errors.passwordConfirmation ? errors.passwordConfirmation : null}
            />
          </div>

          <Button
            variant={VARIANTS.primary}
            className={styles.action}
            type="submit"
            disabled={!isValid}
            isLoading={isSubmitting}
          >
            Modificar contraseña
          </Button>
        </form>
      </div>
    </Screen>
  )
}

export default React.memo(RecoverPasswordScreen)
