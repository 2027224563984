import React from 'react'
import styles from './SeatsHelp.module.css'
import { ReactComponent as IconSeat } from './assets/iconSeat.svg'
import c from 'classnames'
import Text from '../../atoms/text/Text'
import { VARIANTS } from '../../../types/Components.types'

const SeatsHelp = () => {
  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <div className={styles.rowHeader}>
          <IconSeat className={c(styles.icon, styles.red)} />

          <Text size="ml" variant={VARIANTS.primary} weight="600">
            Sitio rojo
          </Text>
        </div>

        <ul className={styles.list}>
          <li>
            <Text>- Silla ergonómica con ruedas</Text>
          </li>

          <li>
            <Text>- Mesa de trabajo con espacio de 120cm x 75cm</Text>
          </li>

          <li>
            <Text>- Enchufe cercano en la propia mesa</Text>
          </li>

          <li>
            <Text>- Luz natural y eléctrica con iluminación mínima de 500lx</Text>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <div className={styles.rowHeader}>
          <IconSeat className={c(styles.icon, styles.yellow)} />

          <Text size="ml" variant={VARIANTS.primary} weight="600">
            Sitio amarillo
          </Text>
        </div>

        <ul className={styles.list}>
          <li>
            <Text>- Silla ergonómica con ruedas</Text>
          </li>

          <li>
            <Text>- Mesa de trabajo con espacio de 120cm x 75cm</Text>
          </li>

          <li>
            <Text>- Enchufe cercano en la propia mesa</Text>
          </li>

          <li>
            <Text>- Luz natural y eléctrica con iluminación mínima de 500lx</Text>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <div className={styles.rowHeader}>
          <IconSeat className={c(styles.icon, styles.blue)} />

          <Text size="ml" variant={VARIANTS.primary} weight="600">
            Sitio azul
          </Text>
        </div>

        <ul className={styles.list}>
          <li>
            <Text>- Silla ergonómica con ruedas</Text>
          </li>

          <li>
            <Text>- Mesa de trabajo con espacio de 100cm x 75cm</Text>
          </li>

          <li>
            <Text>- Enchufe cercano en la propia mesa</Text>
          </li>

          <li>
            <Text>- Luz natural y eléctrica con iluminación mínima de 500lx</Text>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <div className={styles.rowHeader}>
          <IconSeat className={c(styles.icon, styles.primary)} />

          <Text size="ml" variant={VARIANTS.primary} weight="600">
            Sitio seleccionado
          </Text>
        </div>

        <Text className={styles.list}>Indica el sitio que has elegido</Text>
      </div>

      <div className={styles.block}>
        <div className={styles.rowHeader}>
          <IconSeat className={c(styles.icon, styles.disabled)} />

          <Text size="ml" variant={VARIANTS.primary} weight="600">
            Sitio no disponible
          </Text>
        </div>

        <Text className={styles.list}>Estos sitios no se pueden seleccionar</Text>
      </div>
    </div>
  )
}

export default React.memo(SeatsHelp)
