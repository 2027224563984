import React from 'react'
import styles from './Loader.module.css'
import c from 'classnames'

interface IProps {
  className?: string
  variant?: 'white' | 'primary' | 'secondary' | 'max'
}

const Loader = ({ className, variant = 'white' }: IProps) => {
  return (
    <div className={c(styles.container, styles[variant], className)}>
      <span />
      <span />
      <span />
      <span />
    </div>
  )
}

export default React.memo(Loader)
