import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Loader from '../../../../components/atoms/loader/Loader'
import Text from '../../../../components/atoms/text/Text'
import Screen from '../../../../components/molecules/screen/Screen'
import routes from '../../../../config/routes'
import { useProfile, useUpdateProfileMutation } from '../../../../modules/auth/hooks'
import { useProfileAssistant } from '../../../../providers/profileAssistant'
import { ReactComponent as WorkingIllustration } from './assets/working_illustration.svg'
import styles from './OnBoardingCreationScreen.module.css'

const OnBoardingCreationScreen = () => {
  const { firstname, lastname, phone, profession, facephoto, identityCard, dni, censo } = useProfileAssistant()
  const history = useHistory()
  const { data: profile } = useProfile()
  const { mutate: updateProfileMutation } = useUpdateProfileMutation()
  const isWorking = useRef(false)

  useEffect(() => {
    if (isWorking.current) {
      return
    }
    isWorking.current = true
    updateProfileMutation(
      {
        userId: profile!.id,
        firstname,
        lastname,
        phone,
        profession,
        facephoto: facephoto!,
        identityCard: {
          front: identityCard!.front!,
          back: identityCard!.back!,
        },
        dni,
        censo: censo!,
      },
      {
        onSuccess: () => {
          history.replace(routes.onboarding.complete)
        },
      },
    )
  }, [
    history,
    firstname,
    lastname,
    phone,
    profession,
    facephoto,
    identityCard,
    dni,
    censo,
    updateProfileMutation,
    profile,
  ])

  return (
    <Screen hasDecorator decoratorSize="large">
      <div className={styles.container}>
        <WorkingIllustration />
        <Text className={styles.description}>¡Estamos preparando tu cuenta!</Text>
        <Loader variant="primary" />
      </div>
    </Screen>
  )
}

export default React.memo(OnBoardingCreationScreen)
