import React, { useCallback } from 'react'
import styles from './WelcomeScreen.module.css'
import { useHistory } from 'react-router-dom'
import Screen from '../../../../components/molecules/screen/Screen'
import Logo from '../../../../components/atoms/logo/Logo'
import Button from '../../../../components/atoms/button/Button'
import { VARIANTS } from '../../../../types/Components.types'
import routes from '../../../../config/routes'

const WelcomeScreen = () => {
  const history = useHistory()

  const goToRegisterScreen = useCallback(() => {
    history.push(routes.registerWelcome)
  }, [history])

  const goToLoginScreen = useCallback(() => {
    history.push(routes.login)
  }, [history])

  return (
    <Screen hasDecorator decoratorSize="large">
      <Logo className={styles.logoWrapper} />

      <div className={styles.container}>
        <Button variant={VARIANTS.primary} className={styles.action} onClick={goToLoginScreen}>
          Acceder
        </Button>

        <Button variant={VARIANTS.max} inverted className={styles.action} onClick={goToRegisterScreen}>
          Nueva cuenta
        </Button>
      </div>
    </Screen>
  )
}

export default React.memo(WelcomeScreen)
